import { useAuth } from "../components/context/AuthContext";
import { PUBLIC_USER } from "../constants/constants";

export const usePermissions = () => {
  const { profile } = useAuth();

  function getPermissions(keys = []) {
    let visible = false;

    if (keys?.length > 0) {
      keys?.some((key) => (visible = profile?.permissions?.includes(key)));
    }

    return visible;
  }
  return { getPermissions };
};

export const IsPublicUser = () => {
  const { profile } = useAuth();
  const isPublicUser = profile?.role_name?.toLowerCase() === PUBLIC_USER;
  return isPublicUser;
};
