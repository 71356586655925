import styled from "styled-components";
import { stringify } from "../../../utils/helperUtils";
import { EmptyField, EmptyView, SectionTitle } from "../../elements/Elements";
import { Loader } from "../Loader";
import { TablePagination } from "./TablePagination";
import { Add } from "@raresail/react-ionicons";
import { Button } from "../GenericButton";
import { FilterBar, TableFilter } from "./TableFilter";
import { primaryColor } from "../../../constants/theme";
import { ModalForm } from "../../forms/ModalForm";

export const TableView = ({
  columns = [],
  rows = [],
  totalRow,
  loading,
  title,
  description,
  actions,
  count,
  showPagination = true,
  setPage,
  current,
  setFilter,
  filters,
  tableButton,
  tableOptions,
}) => {
  const { filterBar } = tableOptions || {};
  const colSpan = columns?.length + 1;

  function getCell({ row, id }) {
    const cellData = row?.[id];
    // handle objects that are not components
    if (typeof cellData === "object" && !cellData?.["$$typeof"]) return;
    return cellData;
  }

  const startValue = ((filters?.page || 1) - 1) * 10 + 1;
  const endValue = ((filters?.page || 1) - 1) * 10 + rows?.length;

  return (
    <div className="h100">
      <Wrapper className="rounded h100">
        <div className="whiteBg">
          <SectionTitle
            actions={actions}
            title={title}
            description={description}
            leftSide={tableButton && leftButton(tableButton)}
          >
            {count > 0 && (
              <div className="font11">
                {startValue} - {endValue} of {count} items
              </div>
            )}
          </SectionTitle>
          <FilterBar
            filters={filters}
            setFilter={setFilter}
            columns={columns}
            filterBarOptions={filterBar}
          />
        </div>

        <div className="flex">
          <TableWrapper className="animate flexGrow">
            <table className="font12 animate x100">
              {/* HEADER */}
              <TableHeader className="borderBottom">
                <tr>
                  <th>#</th>
                  {columns?.map(({ id, title, filter }, i) => (
                    <th key={i} className="capitalize">
                      <div className="flex gap10">
                        <div
                          className="flexGrow"
                          style={{ color: filters?.[id] && primaryColor }}
                        >
                          {title || stringify(id)}
                        </div>
                        {/* FILTER */}
                        {filter && (
                          <div>
                            <TableFilter
                              field={filter?.field || id}
                              setFilter={setFilter}
                              filter={filter}
                              filters={filters}
                              active={filters?.[id]}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </TableHeader>
              {/* ROWS */}
              {rows?.length > 0 ? (
                <tbody>
                  {rows?.map((row, i) => (
                    // ROW
                    <tr
                      key={i}
                      onClick={row?.onClick}
                      className={row?.onClick && "pointer"}
                    >
                      {/* FIELDS */}
                      <td>{startValue + i}</td>
                      {columns?.map(({ id }, i) => (
                        <td key={i}>
                          {getCell({ row, id }) || <EmptyField />}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {/* TOTAL ROW */}
                  {totalRow && (
                    <tr>
                      <th>#</th>
                      {columns?.map(({ id }, i) => (
                        <th key={i}>
                          {getCell({ row: totalRow, id }) || <EmptyField />}
                        </th>
                      ))}
                    </tr>
                  )}
                </tbody>
              ) : (
                <td colSpan={colSpan} height={100}>
                  {loading ? <Loader /> : <EmptyView />}
                </td>
              )}
              {/* FOOTER */}
              <TableFooter className="whiteBg">
                {/* PAGINATION */}
                <td colSpan={colSpan}>
                  {showPagination && (
                    <TablePagination
                      count={count}
                      setPage={setPage}
                      current={current}
                    />
                  )}
                </td>
              </TableFooter>
            </table>
          </TableWrapper>
        </div>
      </Wrapper>
    </div>
  );
};

export const leftButton = (tableButton) => {
  const { modal, source, id, state, visible = true } = tableButton || {};
  if (!visible) return;
  const button = (
    <Button
      className="font12"
      type="primary"
      size="small"
      icon={<Add fontSize={13} />}
      onClick={tableButton?.onClick}
      to={tableButton?.to}
    >
      New
    </Button>
  );

  if (modal)
    return <ModalForm source={source} button={button} id={id} state={state} />;
  return button;
};

const Wrapper = styled.div`
  border: 1px solid #eee;
  overflow: scroll;
`;

const TableWrapper = styled.div`
  width: 0;
  table {
    border-spacing: 0;
    background-color: white;
  }

  td,
  th {
    padding: 10px;
    text-align: left;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
  }

  tr {
    transition: 0.3s ease;
  }

  th {
    background-color: #eee;
    line-height: normal;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;

  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
`;
const TableFooter = styled.tfoot`
  position: sticky;
  bottom: 0;
  z-index: 10;

  td {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
`;
