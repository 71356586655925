import { API_CHARGES } from "../../constants/apis";
import { SELECT, TEXT, UGX } from "../../constants/constants";
import { charges_types } from "../../constants/options";
import { FieldProps } from "../../utils/formUtils";

export const useChargesForm = () => {
  const fields = [
    {
      field: "type",
      type: SELECT,
      required: true,
      options: charges_types,
    },
    {
      field: "name",
      type: TEXT,
      required: true,
    },
    {
      field: "amount",
      type: TEXT,
      required: true,
      props: { prefix: UGX, ...FieldProps.number },
    },
    {
      field: "description",
      type: TEXT,
      props: { rows: 1 },
    },
  ];

  const sections = [
    {
      title: "Charge Details",
      fields,
    },
  ];

  return { sections, onSubmit };
};

function onSubmit({ payload, id }) {
  return {
    id,
    api: API_CHARGES,
    params: payload,
  };
}
