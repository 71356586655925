import { Fab } from "@mui/material";
import {
  ACCEPTED_DATA_IMPORT_FILES,
  COLUMN,
  COMPONENT,
  FILE,
  SELECT,
} from "../../constants/constants";
import { DownloadOutline } from "@raresail/react-ionicons";
import { addSelectOption } from "../../utils/helperUtils";

export const useImportForm = ({ form }) => {
  const fields = [
    {
      field: "file",
      label: "Excel file",
      type: FILE,
      required: true,
      props: {
        maxFileSize: 1,
        acceptedFiles: ACCEPTED_DATA_IMPORT_FILES,
        helperText:
          "Ensure that the Excel data matches the format specified in the template file.",
      },
    },
  ];

  console.log(form?.module);

  const sections = [
    {
      title: "Import Details",
      layout: COLUMN,
      fields: [
        {
          field: "module",
          type: SELECT,
          required: true,
          options: [
            addSelectOption("loans"),
            addSelectOption("charges"),
            addSelectOption("installments"),
            addSelectOption("transactions"),
          ],
        },
        {
          type: COMPONENT,
          component: <SampleView module={form?.module} />,
          visible: form?.module != null,
        },
      ],
    },
    {
      fields,
    },
  ];

  function onSubmit({ payload }) {}

  return { sections, submitText: "Import File", onSubmit };
};

const SampleView = ({ module }) => (
  <div
    className="rounded p15 lightBg flexSpaceCenter gap10"
    style={{ border: "1px dashed #d9d9d9" }}
  >
    <div className="flexColumn gap10">
      <h4 className="capitalize">{module} Sample Format</h4>
      <p className="font12 greyColor" style={{ maxWidth: 400 }}>
        You can download the attached <b>Excel</b> sample template and use this
        to create your own file.
      </p>
    </div>
    <div>
      <Fab color="secondary" size="medium" sx={{ color: "white" }}>
        <DownloadOutline fontSize={23} />
      </Fab>
    </div>
  </div>
);
