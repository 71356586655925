import React, { useEffect } from "react";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";
import {
  ChevronDown,
  DocumentTextOutline,
  FingerPrintOutline,
  GitBranch,
} from "@raresail/react-ionicons";
import { primaryColor } from "../../../constants/theme";
import {
  ACCOUNT,
  ACTIVE,
  ALL,
  APPLICATIONS,
  LOANS,
  OVERVIEW,
  PENDING,
} from "../../../constants/constants";
import { EmptyView, StatusTag } from "../../elements/Elements";
import { Button, Progress } from "antd";
import {
  currencyFormat,
  formatDate,
  getForm,
  getWorkArea,
  greeting,
} from "../../../utils/helperUtils";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import useGet from "../../../hooks/common/useGet";
import { API_APPLICATIONS, API_LOANS } from "../../../constants/apis";
import { Loader } from "../../common/Loader";

export const ClientDashboard = () => {
  const { profile } = useAuth();
  const { first_name } = profile || {};

  const { loadData: getLoans, data: loans, loading: loadingLoans } = useGet();
  const {
    loadData: getApplications,
    data: applications,
    loading: loadingApplications,
  } = useGet();

  useEffect(() => {
    getLoans({ api: API_LOANS, params: { status: ACTIVE } });
    getApplications({ api: API_APPLICATIONS, params: { status: PENDING } });
  }, []);

  return (
    <div className="gap15 flexColumn h100 scroll">
      <h3 className="rounded whiteBg p15">
        {greeting()}, <span className="mainColor">{first_name} 👋</span>
      </h3>
      <div className="gap15 flexColumn rounded whiteBg p20">
        {/* BRANCH DETAILS */}
        <BranchCard profile={profile} />

        {/* QUICK ACTIONS */}
        <h3>Quick Actions</h3>
        <div className="flex gap15">
          {quickActions.map((action, i) => (
            <ActionItem key={i} data={action} />
          ))}
        </div>

        {/* ACTIVE LOANS */}
        <Divider />
        <h3 className="capitalize">Active {LOANS}</h3>
        {loadingLoans ? (
          <Loader />
        ) : (
          <>
            {loans?.results?.length > 0 ? (
              <GridWrapper>
                {loans?.results?.map((val, i) => (
                  <LoanItem data={val} key={i} />
                ))}
              </GridWrapper>
            ) : (
              <EmptyView />
            )}
          </>
        )}

        {/* APPLICATIONS */}
        <Divider />
        <h3 className="capitalize">{APPLICATIONS}</h3>
        <div className="flexColumn gap15">
          {loadingApplications ? (
            <Loader />
          ) : applications?.results?.length > 0 ? (
            applications?.results?.map((val) => <ApplicationItem data={val} />)
          ) : (
            <EmptyView>No action required</EmptyView>
          )}
        </div>
      </div>
    </div>
  );
};

const ActionItem = ({ data: { icon: Icon, label, to } }) => {
  return (
    <Link to={to}>
      <TileWrapper className="radius15 flexColumn gap20 whiteColor pointer animate p20">
        <Icon fontSize={40} />
        <div>{label}</div>
      </TileWrapper>
    </Link>
  );
};

const ApplicationItem = ({ data }) => {
  const { id, ref_number, category, status, amount } = data || {};
  return (
    <div className="rounded lightBg p20 flexSpaceCenter">
      <div>
        <p className="mb10 font15 bold">{category}</p>
        <div className="flexNullCenter gap10">
          <p className="font12 greyColor">{ref_number}</p>
        </div>
      </div>
      <div className="flexNullCenter gap20">
        <div>
          <div className="font15 bold mb10">{currencyFormat(amount)}</div>
          <StatusTag status={status} />
        </div>
        <Link to={`${getWorkArea(APPLICATIONS, ALL)}/${id}/${OVERVIEW}?list`}>
          <Button
            type="primary"
            icon={<ChevronDown />}
            iconPosition="end"
            className="font12"
            style={{ boxShadow: "none" }}
          >
            View
          </Button>
        </Link>
      </div>
    </div>
  );
};
const LoanItem = ({ data }) => {
  const {
    id,
    ref_number,
    category_name,
    status,
    amount,
    next_payment_date,
    progress,
  } = data || {};
  return (
    <LoanWrapper className="rounded flexSpaceCenter gap20 whiteColor radius10 animate p20">
      {/* MAIN */}
      <div className="flexSpaceCenter gap20 x100" id="part1">
        {/* PART 1 */}
        <div className="flexNullCenter gap20">
          {/* PROGRESS */}
          <div>
            <Progress
              type="circle"
              size="small"
              status="active"
              percent={progress}
              strokeColor="#009fff"
              trailColor="#ffffff40"
            />
          </div>
          {/* INFO */}
          <div className="">
            <p className="bold font18 mb10">{category_name}</p>
            <div className="flexNullCenter gap15">
              <p className="font12">{ref_number}</p>
              <StatusTag status={status} />
            </div>
          </div>
        </div>
        {/* PART 2 */}
        <div className="flexNullCenter gap20">
          <p className="bold font18">{currencyFormat(amount)}</p>
          <h1>-</h1>
          {/* NEXT */}
          <div>
            <p className="font12 mb5 white70">Next Payment</p>
            <p className="font15">{formatDate(next_payment_date)}</p>
          </div>
        </div>
      </div>

      {/* END */}
      <Link to={`${getWorkArea(LOANS, ALL)}/${id}/${OVERVIEW}?list`}>
        <Button
          icon={<ChevronDown />}
          iconPosition="end"
          className="font12"
          style={{
            background: 0,
            border: "1px solid",
            color: "white",
          }}
        >
          View
        </Button>
      </Link>
    </LoanWrapper>
  );
};

const BranchCard = ({ profile }) => {
  const branch = profile?.profile?.branch_name;

  if (!branch) return;

  return (
    <>
      <h3>Branch Details</h3>
      <div className="lightBg p20 radius10 flexNullCenter gap15">
        <div className="whiteBg p10 radius10 greyColor">
          <GitBranch fontSize={30} />
        </div>
        <div>
          <div className="mb5 greyColor font12">Your currently under</div>
          <div className="semiBold font15">{branch}</div>
        </div>
      </div>
      <Divider />
    </>
  );
};

const quickActions = [
  {
    label: "Apply for a new loan",
    icon: DocumentTextOutline,
    to: getForm(APPLICATIONS),
  },
  {
    label: "Update your information",
    icon: FingerPrintOutline,
    to: ACCOUNT,
  },
];

const TileWrapper = styled.div`
  background-color: ${primaryColor};
  width: 150px;
  height: 160px;
  justify-content: flex-end;

  &:hover {
    filter: brightness(180%);
  }
`;
const LoanWrapper = styled.div`
  background-color: #081b28;
  &:hover {
    filter: brightness(180%);
  }

  @media (max-width: 960px) {
    flex-direction: column;
    #part1 {
      flex-direction: column;
    }
  }
`;
const GridWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
