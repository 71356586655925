import React, { cloneElement } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@raresail/react-ionicons";

export const GenericModal = ({ show, dismiss, title, body }) => {
  const handleClose = () => {
    if (dismiss) return dismiss();
  };

  const renderBody = () => cloneElement(body, { dismiss });

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        maxWidth="md"
        sx={{ zIndex: 1000 }}
      >
        {/* CHILDREN */}
        <div className="flexSpaceCenter">
          <DialogTitle>
            <p className="capitalize font14 bold mainColor">{title}</p>
          </DialogTitle>
          <IconButton sx={{ mr: 1 }} onClick={handleClose}>
            <Close fontSize={20} />
          </IconButton>
        </div>
        <DialogContent dividers={true} sx={{ minHeight: 200, p: 1 }}>
          {renderBody()}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
