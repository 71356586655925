import React from "react";

import { Select } from "antd";
import { FieldWrapper } from "../elements/FieldWrapper";

export const GenericSelect = ({
  placeholder,
  label,
  options,
  helperText,
  mode,
  value,
  required = false,
  onChange,
  onSelect,
  onClear,
  info,
  disabled,
  field,
  error,
  ...props
}) => (
  <FieldWrapper
    {...{ label, helperText, error, field, required, info }}
    {...props}
  >
    <Select
      mode={mode}
      allowClear
      style={{
        width: "100%",
      }}
      placeholder={placeholder || `Select ${label}`}
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      onClear={onClear}
      options={options}
      showSearch
      disabled={disabled}
      filterOption={(input, option) => {
        return (option?.searchLabel || option?.label)
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
      {...props}
    />
  </FieldWrapper>
);
