import { DateInput } from "../components/common/inputs/DateInput";
import { FileUpload } from "../components/common/FileUpload";
import { GenericCheckBox } from "../components/common/GenericCheckBox";
import { GenericInput } from "../components/common/inputs/GenericInput";
import { GenericSelect } from "../components/common/GenericSelect";
import { GenericStepper } from "../components/common/GenericStepper";
import { GenericSwitch } from "../components/common/GenericSwitch";
import { FormWrapper } from "../components/elements/Elements";
import { FormSection } from "../components/forms/FormSection";
import {
  CHECK_BOX,
  COMPONENT,
  DATE,
  FILE,
  MULTIPLE,
  SELECT,
  SWITCH,
  TEXT,
} from "../constants/constants";
import { returnObject, stringify } from "./helperUtils";

export function RenderForm({
  form,
  fields,
  onChange,
  updateFormValues,
  updateChanges, // track changes to the form
  error,
}) {
  return (
    <>
      {fields?.map((formField, i) => {
        const {
          label,
          type,
          value,
          required,
          field,
          options = [],
          component,
          visible = true,
          option_keys = [],
          form_keys = [],
          props,
        } = formField || {};

        if (!visible) return;

        function getLabel() {
          return label || stringify(field);
        }

        function getFieldName() {
          return field || label?.toLowerCase();
        }

        function getValue() {
          return form?.[getFieldName()] || value;
        }

        const fieldProps = {
          label: getLabel(),
          field: getFieldName(),
          error,
          value: getValue(),
          required,
          props,
        };

        switch (type) {
          case TEXT:
            return (
              <GenericInput
                key={i}
                label={getLabel()}
                name={getFieldName()}
                numberValue={form?.[getFieldName()]}
                value={getValue()}
                onChange={onChange}
                updateChanges={updateChanges}
                required={required}
                error={error}
                {...props}
              />
            );
          case SELECT:
            return (
              <GenericSelect
                key={i}
                label={getLabel()}
                field={getFieldName()}
                value={getValue()}
                onClear={() => updateFormValues({ [field]: null })}
                options={
                  options?.length > 0 &&
                  options?.map((val) => getOptions(val, option_keys))
                }
                onChange={(val, obj) => {
                  // mutiple options
                  if (props?.mode === MULTIPLE)
                    return updateFormValues({ [field]: val });

                  const values = getFormValues(field, obj, form_keys);

                  if (updateChanges) updateChanges(values);

                  // set selected value to form
                  return updateFormValues(values);
                }}
                required={required}
                error={error}
                {...props}
              />
            );
          case CHECK_BOX:
            return (
              <GenericCheckBox
                key={i}
                label={getLabel()}
                field={getFieldName()}
                updateFormValues={updateFormValues}
                value={getValue()}
                {...props}
              />
            );
          case SWITCH:
            return (
              <GenericSwitch
                key={i}
                label={getLabel()}
                field={getFieldName()}
                updateFormValues={updateFormValues}
                value={getValue()}
                {...props}
              />
            );
          case FILE:
            return (
              <FileUpload
                key={i}
                label={getLabel()}
                field={getFieldName()}
                updateFormValues={updateFormValues}
                error={error}
                value={getValue()}
                required={required}
                {...props}
              />
            );

          case DATE:
            return (
              <DateInput
                key={i}
                updateFormValues={updateFormValues}
                updateChanges={updateChanges}
                {...fieldProps}
                {...props}
              />
            );

          case COMPONENT:
            return <div key={i}>{component}</div>;

          default:
            return null;
        }
      })}
    </>
  );
}

export function renderStepper({
  form,
  onChange,
  updateFormValues,
  error,
  steps,
  current,
  next,
  prev,
  enableSubmit,
  submitting,
}) {
  return (
    <GenericStepper
      enableSubmit={enableSubmit}
      next={next}
      prev={prev}
      current={current}
      submitting={submitting}
      steps={[
        ...steps?.map((step, i) => {
          const { title, fields, layout, ...props } = step || {};
          return {
            title,
            content: (
              <FormWrapper key={i} layout={layout} {...props}>
                {RenderForm({
                  fields,
                  form,
                  updateFormValues,
                  onChange,
                  error,
                })}
              </FormWrapper>
            ),
          };
        }),
      ]}
    />
  );
}

export function createForm({
  sections,
  form,
  updateFormValues,
  onChange,
  error,
}) {
  return (
    <>
      {sections?.map((section, i) => {
        const { title, fields, component, visible = true, ...rest } = section;

        if (!visible || visible == "false") return;

        return (
          <>
            <FormSection key={i} title={title} {...rest}>
              {RenderForm({
                fields,
                form,
                updateFormValues,
                onChange,
                error,
              })}
              {component}
            </FormSection>
          </>
        );
      })}
    </>
  );
}

export function getValidators(sections) {
  let validators = [];
  sections?.forEach((item) => {
    if (item.visible === false) return;
    if (item.fields) validators = [...validators, ...item.fields];
  });
  return validators;
}

export const FieldProps = {
  number: { type: "number" },
  disabled: { disabled: true },
};

function getOptions(data, option_keys) {
  // default for picker
  let defaultObject = {
    label: data?.name,
    value: data?.id,
    ...returnObject(data?.searchLabel, { searchLabel: data?.searchLabel }),
  };

  // override default for picker
  option_keys.forEach((key) => {
    if (key instanceof Object) {
      defaultObject = {
        ...defaultObject,
        [key.field_key]: data[key.value_key],
      };
    } else if (typeof key === "string") {
      option_keys.forEach((key) => {
        defaultObject = { ...defaultObject, [key]: data?.[key] };
      });
    }
  });

  return defaultObject;
}

function getFormValues(field, data) {
  delete data?.searchLabel;
  let defaultObject = {
    [field]: data?.value,
  };

  Object.keys(data || {}).forEach((key) => {
    if (!["label", "value"].includes(key)) {
      defaultObject = { ...defaultObject, [key]: data[key] };
    }
  });

  // override default
  /*   if (form_keys) {
    form_keys.map((value) => {
      if (value instanceof Object) {
        defaultObject = Object.assign(defaultObject, {
          // custom key/value pair
          [value.field_key]: data[value.value_key],
        });
      } else if (typeof value === "string") {
        defaultObject = Object.assign(defaultObject, {
          // same custom key/value pair
          [value]: data[value],
        });
      }
    });
  } */
  return defaultObject;
}
