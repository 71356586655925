import { useApplicationsForm } from "../applications/useApplicationsForm";
import { useBranchesForm } from "../branches/useBranchesForm";
import { useCategoriesForm } from "../categories/useCategoriesForm";
import { useChargePenaltiesForm } from "../charges/useChargePenaltiesForm";
import { useChargesForm } from "../charges/useChargesForm";
import { useCommentsForm } from "../comments/useCommentsForm";
import { useActionForm } from "../common/useActionForm";
import { useDocumentsForm } from "../documents/useDocumentsForm";
import { useImportForm } from "../import/useImportForm";
import { useRepaymentForm } from "../installments/useRepaymentForm";
import { useLoansForm } from "../loans/useLoansForm";
import { useLoginForm } from "../login/useLoginForm";
import { useForgotPasswordForm } from "../password-reset/useForgotPasswordForm";
import { useResetPasswordForm } from "../password-reset/useResetPasswordForm";
import { useTransactionsForm } from "../payments/useTransactionsForm";
import { useRegisterForm } from "../register/useRegisterForm";
import { useStaffFilters } from "../reports/useStaffReport";
import { useRolesForm } from "../roles/useRolesForm";
import { useTasksForm } from "../tasks/useTasksForm";
import { useUsersForm } from "../users/useUsersForm";

// FORMS
export const formHooks = {
  loans: useLoansForm,
  applications: useApplicationsForm,
  transactions: useTransactionsForm,
  categories: useCategoriesForm,
  users: useUsersForm,
  roles: useRolesForm,
  comments: useCommentsForm,
  charges: useChargesForm,
  charge_penalties: useChargePenaltiesForm,
  // repayment
  repayments: useRepaymentForm,
  // docs
  documents: useDocumentsForm,
  // data-control
  import: useImportForm,
  // Branches
  branches: useBranchesForm,
};

// AUTH FORMS
export const authFormHooks = {
  login: useLoginForm,
  register: useRegisterForm,
  "forgot-password": useForgotPasswordForm,
  "reset-password": useResetPasswordForm,
};

// MODALS
export const modalFormHooks = {
  ...formHooks,
  loans: useActionForm,
  applications: useActionForm,
  tasks: useTasksForm,
};

// FILTERS
export const filterFormHooks = {
  staff: useStaffFilters,
};
