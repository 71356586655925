import { Grid, Receipt } from "@raresail/react-ionicons";
import {
  ACCEPT,
  ACCEPTED,
  ALL,
  APPLICATION,
  APPLICATIONS,
  CAN_ADMIN,
  CAN_CHANGE_APPLICATION_STATUS,
  CANCEL,
  CANCELLED,
  DOCUMENTS,
  MONTHLY,
  OVERVIEW,
  PENDING,
  REJECT,
  REJECTED,
  TASKS,
} from "../../constants/constants";
import {
  createColumn,
  currencyFormat,
  formatDate,
  formatDateTime,
  getForm,
  getUser,
  getWorkArea,
  interestFormat,
  returnObject,
} from "../../utils/helperUtils";
import { DetailsView } from "../../components/common/views/DetailsView";
import {
  EditButton,
  LinkItem,
  RefLink,
  StatusTag,
} from "../../components/elements/Elements";
import { DocumentsList } from "../../components/documents/DocumentsList";
import { RegisterHandler } from "../../components/handlers/RegisterHandler";
import { NoteFilledSVG } from "../../assets/svg/Svgs";
import { IsPublicUser, usePermissions } from "../../utils/permissionUtils";

export const useApplications = ({ data, reload: reloadData }) => {
  const { getPermissions } = usePermissions();

  const applicationId = data?.id;

  const tableColumns = () => [
    ...["ref_number", "amount", "category", "status", "created_at"].map(
      (column) => createColumn(column)
    ),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const { id, ref_number, created_at, status, amount } = item || {};

      const row = {
        ...item,
        ref_number: (
          <LinkItem
            to={`${getWorkArea(APPLICATIONS, ALL)}/${id}/${OVERVIEW}?list`}
          >
            {ref_number}
          </LinkItem>
        ),
        status: <StatusTag status={status} />,
        amount: currencyFormat(amount),
        created_at: formatDate(created_at),
      };
      return row;
    }),
  ];

  const listData = (data) => [
    ...data.map((item) => {
      const { ref_number, created_at, amount, status } = item || {};

      const row = {
        ...item,
        title: ref_number,
        amount: currencyFormat(amount),
        created_at: formatDate(created_at),
        tags: [
          {
            status,
          },
        ],
      };
      return row;
    }),
  ];

  const listFields = () => [
    ...["amount", "category", "client", "created_at"].map((id) => {
      return { id };
    }),
  ];

  // const { submitting, submitStatus } = useSubmitStatus({
  //   id: data?.id,
  //   reload,
  // });

  // TITLE ACTIONS
  const titleActions = [
    // {
    //   label: ACCEPT,
    //   onClick: () => submitStatus({ api: API_APPLICATIONS, status: ACCEPTED }),
    //   loading: submitting,
    //   icon: CheckmarkCircle,
    // },
    // {
    //   label: "Reject",
    //   onClick: () => submitStatus({ api: API_APPLICATIONS, status: REJECTED }),
    //   loading: submitting,
    //   icon: CloseCircleOutline,
    // },
  ];

  // TITLE BUTTONS
  const titleButtons = () => {
    const status = data?.status;

    function getButtons() {
      if (!getPermissions([CAN_CHANGE_APPLICATION_STATUS, CAN_ADMIN])) return;
      let buttons;
      const rejectBtn = { label: REJECT, value: REJECTED };
      const cancelBtn = { label: CANCEL, value: CANCELLED };
      const acceptBtn = { label: ACCEPT, value: ACCEPTED };

      switch (status) {
        case PENDING:
          buttons = [rejectBtn, acceptBtn];
          break;
        case ACCEPTED:
          buttons = [cancelBtn];
          break;
        case REJECTED:
        case CANCELLED:
          buttons = [acceptBtn];
          break;

        default:
          break;
      }
      return buttons;
    }

    return getButtons();
  };

  // VIEW TITLE FIELDS
  const titleFields = {
    created: formatDateTime(data?.created_at),
    updated: formatDateTime(data?.updated_at),
    category: data?.category_details?.name,
  };

  // VIEW DATA
  function viewData() {
    const {
      amount,
      interest_rate,
      category_details,
      loan_term,
      payment_frequency,
      client_details,
      ref_number,
      created_by,
      loan_ref_number,
      date_accepted,
      created_at,
    } = data || {};

    const { email, display_name, phone_number, profile } = client_details || {};

    return {
      // APPLICATION
      application_details: {
        ref_number,
        date_applied: formatDateTime(created_at),
        date_approved: formatDateTime(date_accepted),
        ...getUser(created_by),
      },
      // LOAN REQUEST
      request_details: {
        category: category_details?.name,
        principal: currencyFormat(amount),
        interest_rate: interestFormat(interest_rate),
        ...returnObject(payment_frequency === MONTHLY, {
          loan_term: `${loan_term} Months`,
        }),
        payment_frequency,
      },
      // CLIENT
      client_details: {
        email: <LinkItem>{email}</LinkItem>,
        client_name: display_name,
        phone_number,
        ...profile,
      },
      // LOAN
      loan_details: {
        loan_ref_number: <RefLink>{loan_ref_number}</RefLink>,
      },
    };
  }

  // ACTIONS
  // const applicationActions = [editAction(APPLICATIONS, data?.id)];

  // TABS FOR VIEW
  const tabs = () => [
    {
      icon: <Grid />,
      label: OVERVIEW,
      children: (
        <>
          <div className="flexColumn gap15">
            <DetailsView
              data={viewData({ data })?.application_details}
              // actions={applicationActions}
              rightSide={
                data?.status !== ACCEPTED && (
                  <EditButton to={getForm(APPLICATIONS, applicationId)} />
                )
              }
              title="application_details"
            />
            <DetailsView
              data={viewData({ data })?.client_details}
              title="client_details"
            />
          </div>
          <div className="flexColumn gap15">
            <DetailsView
              data={viewData({ data })?.request_details}
              title="request_details"
            />
            {data?.loan && (
              <DetailsView
                data={viewData({ data })?.loan_details}
                title="loan_details"
              />
            )}
          </div>
        </>
      ),
    },
    // DOCUMENTS
    {
      icon: <Receipt />,
      label: DOCUMENTS,
      children: (
        <DocumentsList
          attachments={data?.attachments}
          id={applicationId}
          reload={reloadData}
        />
      ),
      props: { fullWidth: true },
    },
    // TASKS
    {
      icon: <NoteFilledSVG />,
      label: TASKS,
      children: (
        <RegisterHandler
          source={TASKS}
          params={{ object_id: applicationId, type: APPLICATION }}
        />
      ),
      props: { fullWidth: true },
      visible: !IsPublicUser(),
    },
    // COMMENTS
    // {
    //   icon: <ChatboxEllipses />,
    //   label: COMMENTS,
    //   children: (
    //     <RegisterHandler
    //       source={COMMENTS}
    //       id={applicationId}
    //       state={{ type: APPLICATION }}
    //     />
    //   ),
    //   props: { fullWidth: true },
    // },
  ];

  return {
    tableData,
    tableColumns,
    listData,
    listFields,
    titleFields,
    titleActions,
    titleButtons,
    tabs,
  };
};
