import { API_BRANCHES } from "../../constants/apis";
import { TEXT } from "../../constants/constants";

export const useBranchesForm = () => {
  const fields = [
    {
      field: "name",
      type: TEXT,
      required: true,
      props: {
        helperText:
          "Unique branch code will be automatically generated for this branch.",
      },
    },
  ];

  function onSubmit({ payload, id }) {
    return {
      id,
      api: API_BRANCHES,
      params: { ...payload },
    };
  }
  return { fields, onSubmit };
};
