import { InputNumber } from "antd";
import React from "react";

export const NumberInput = ({ onChange, name, value, ...props }) => {
  return (
    <InputNumber
      className="x100"
      value={value}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
      onChange={(value) => {
        const event = {
          target: { name, value },
        };
        onChange(event);
      }}
      {...props}
    />
  );
};
