import {
  ChevronDown,
  GitBranch,
  LogOutOutline,
  PersonOutline,
} from "@raresail/react-ionicons";
import React from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import { primaryColor } from "../../constants/theme";
import { ActionsPopup, VerticalDivider } from "../elements/Elements";
import { LogoSVG } from "../../assets/svg/Svgs";
import { MobileMenu } from "./MobileMenu";
import { ACCOUNT } from "../../constants/constants";
import { Tag } from "antd";

export const AppBar = () => {
  const { logout, profile } = useAuth();
  const displayName = profile?.display_name?.trim() || profile?.email;
  const branch = profile?.profile?.branch_name;

  const items = [
    {
      label: ACCOUNT,
      icon: PersonOutline,
      to: ACCOUNT,
    },
    {
      label: "Logout",
      icon: LogOutOutline,
      onClick: logout,
    },
  ];

  return (
    <div className="p15">
      <AppBarWrapper className="whiteBg rounded flexNullCenter gap10">
        {/* TITLE */}
        <div className="flexNullCenter gap10">
          <MobileMenu />
          <img src={LogoSVG} height={35} />
        </div>

        {/* BRANCH */}
        {branch && (
          <>
            <VerticalDivider id="web" />
            <div id="web">
              {branch && (
                <TagWrapper className="lightBg rounded flexNullCenter gap5">
                  <GitBranch className="greyColor" />
                  <div className="font11">{branch}</div>
                </TagWrapper>
              )}
            </div>
          </>
        )}

        <div className="flexGrow" />

        {/* END */}
        <ActionsPopup actions={items} placement="bottomRight" trigger="hover">
          <div className="flexNullCenter gap10 pointer">
            {/* AVATAR */}
            <AvatarWrapper className="animate flexCenter">
              <div className="extraBold capitalize">
                {displayName?.substring(0, 1)}
              </div>
            </AvatarWrapper>
            <VerticalDivider />
            {/* TITLES */}
            <div id="web">
              <p className="font12 semiBold mainColor">{displayName}</p>
              <p className="font10 greyColor">{profile?.email}</p>
            </div>
            <ChevronDown className="greyColor" />
          </div>
        </ActionsPopup>
      </AppBarWrapper>
    </div>
  );
};

const AppBarWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;

  #mobile {
    display: none;
  }

  @media (max-width: 960px) {
    #mobile {
      display: inherit;
    }
    #web {
      display: none;
    }
  }
`;

const AvatarWrapper = styled.div`
  background-color: ${primaryColor}1f;
  font-size: 17px;
  border-radius: 50%;
  color: ${primaryColor};
  height: 30px;
  width: 30px;
  border: 1px solid ${primaryColor}2f;
  &:hover {
    scale: 1.1;
  }
`;

const TagWrapper = styled.div`
  padding: 0 10px;
  height: 25px;
  border: 0.5px solid #ddd;
`;
