import React from "react";
import { EmptyView, SectionTitle } from "../elements/Elements";
import styled from "styled-components";
import {
  Add,
  DocumentTextOutline,
  ImageOutline,
  OpenOutline,
} from "@raresail/react-ionicons";
import { getFileFormat, stringify } from "../../utils/helperUtils";
import { Button, Tag } from "antd";
import { BASE_MEDIA_URL } from "../../constants/config";
import { leftButton } from "../common/tables/TableView";
import { DOCUMENTS } from "../../constants/constants";
import { useParams } from "react-router-dom";

function getIcon(format) {
  switch (format) {
    case "png":
    case "jpg":
    case "jpeg":
      return <ImageOutline />;
    default:
      return <DocumentTextOutline />;
  }
}

export const DocumentsList = ({ attachments = [], title, reload, id }) => {
  const { source } = useParams();
  const tableButton = {
    modal: true,
    source: DOCUMENTS,
    id,
    state: {
      reloadData: reload,
      source,
    },
  };
  return (
    <div className="rounded whiteBg">
      <SectionTitle
        title={title || "Attachments"}
        actions={[{ label: "Add document", icon: Add }]}
        leftSide={tableButton && leftButton(tableButton)}
      />
      {attachments?.length > 0 ? (
        attachments?.map(({ file, caption }, i) => {
          const fileFormat = getFileFormat(file);
          return (
            <ItemWrapper key={i} className="flexSpaceCenter gap15">
              {/* LABEL */}
              <div className="flexNullCenter gap15">
                <div className="greyColor flex" style={{ fontSize: 17 }}>
                  {getIcon(fileFormat)}
                </div>

                <p className="font13 capitalize">
                  {`${i + 1}. ${stringify(caption)}`}
                </p>
                {fileFormat && <Tag className="font11">{fileFormat}</Tag>}
              </div>
              {/* BUTTON */}
              <Button
                size="small"
                type="primary"
                href={`${BASE_MEDIA_URL}${file}`}
                target="_blank"
                className="font12 radius5"
                icon={<OpenOutline fontSize={15} />}
              >
                View
              </Button>
            </ItemWrapper>
          );
        })
      ) : (
        <EmptyView />
      )}
    </div>
  );
};

const ItemWrapper = styled.div`
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
`;
