import { LockClosedOutline, LockOpenOutline } from "@raresail/react-ionicons";
import React from "react";
import { COMPONENT, TEXT } from "../../constants/constants";
import { Heading } from "../../components/pages/home/AuthPage";
import { URL_LOGIN } from "../../constants/urls";
import { useParams } from "react-router-dom";
import { API_RESET_PASSWORD } from "../../constants/apis";

export const useResetPasswordForm = ({ form }) => {
  const { uid, token } = useParams();

  const enableSubmit = form?.password === form?.new_password;

  const fields = [
    {
      type: COMPONENT,
      component: <Heading>Reset your Password</Heading>,
    },
    {
      type: COMPONENT,
      component: (
        <p className="font13" style={{ lineHeight: 1.5 }}>
          To change your password, please fill in the fields below. <br />
          Your password must contain atleast 8 characters, one uppercase
          character and one special character.
        </p>
      ),
    },
    {
      label: "New Password",
      field: "password",
      type: TEXT,
      required: true,
      props: {
        info: "Please enter your password",
        prefix: <LockOpenOutline />,
        type: "password",
      },
    },
    {
      label: "Confirm Password",
      field: "new_password",
      type: TEXT,
      required: true,
      props: {
        info: "Please enter your password",
        prefix: <LockClosedOutline />,
        type: "password",
      },
    },
  ];

  function onSubmit({ payload }) {
    return {
      api: API_RESET_PASSWORD,
      params: { new_password: payload?.new_password, uid, token },
    };
  }
  return {
    fields,
    submitText: "Reset Password",
    onSubmit,
    redirect: URL_LOGIN,
    enableSubmit,
    successMsg: "Password has been reset successfully.",
  };
};
