import React from "react";
import { SectionTitle } from "../../elements/Elements";
import { AUTOMATIONS } from "../../../constants/constants";
import {
  API_CHECK_INSTALLMENTS,
  API_SEND_REMINDERS,
} from "../../../constants/apis";
import styled from "styled-components";
import { Button } from "../../common/GenericButton";
import useGet from "../../../hooks/common/useGet";
import { SECRET_TOKEN } from "../../../constants/config";

export const AutomationsView = () => {
  const { loading, loadData } = useGet();

  const runJob = (api) => {
    loadData({ api, token: SECRET_TOKEN, successText: "Job run successfully" });
  };

  return (
    <div className="rounded whiteBg">
      <SectionTitle
        title={AUTOMATIONS}
        description="Run automation jobs on your data manually."
      />
      <div className="flexColumn gap10">
        {automations.map(({ label, frequency, desc, api }, i) => (
          <ItemWrapper key={i} className="flexSpaceCenter gap10 p15">
            <div className="flexColumn gap5">
              <p className="semiBold capitalize">{label}</p>
              <p className="greyColor font12">{desc}</p>
              <p className="mainColor font12">{frequency}</p>
            </div>
            <Button loading={loading} onClick={() => runJob(api)}>
              Run Job
            </Button>
          </ItemWrapper>
        ))}
      </div>
    </div>
  );
};

const ItemWrapper = styled.div`
  border-bottom: 1px solid #eee;
`;

const automations = [
  {
    label: "Check installments",
    desc: "Check installment dates and update status accordingly.",
    frequency: "Everyday at 12:00 AM",
    api: API_CHECK_INSTALLMENTS,
  },
  {
    label: "Send reminders",
    desc: "Send email reminders to clients for payments.",
    frequency: "Everyday at 9:00 AM",
    api: API_SEND_REMINDERS,
  },
];
