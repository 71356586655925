import React from "react";
import {
  createColumn,
  editModalAction,
  formatDate,
  formatDateTime,
  getModuleRef,
  getUser,
} from "../../utils/helperUtils";
import {
  ActionsPopup,
  RefLink,
  StatusTag,
} from "../../components/elements/Elements";
import { Avatar, Tooltip } from "antd";
import { CLOSED, COMPLETED, PENDING, TASKS } from "../../constants/constants";
import { Button } from "../../components/common/GenericButton";
import { openModalDetails, openModalForm } from "../../utils/modalUtils";
import useSet from "../common/useSet";
import { API_TASKS } from "../../constants/apis";
import { Checkmark, CreateOutline } from "@raresail/react-ionicons";

export const useTasks = ({ refreshData, params }) => {
  const tableColumns = () => [
    ...[
      "title",
      "description",
      "ref_number",
      "assignees",
      "status",
      "due_date",
      "created_at",
      "actions",
    ].map((column) => createColumn(column)),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const {
        created_at,
        content_type,
        object_id,
        status,
        due_date,
        assignees,
      } = item || {};

      return {
        ...item,
        status: <StatusTag status={status} />,
        ref_number: <RefLink>{getModuleRef(object_id, content_type)}</RefLink>,
        created_at: formatDateTime(created_at),
        due_date: formatDate(due_date),
        assignees: <UserGroup assignees={assignees} />,
        onClick: () => {
          openModalDetails({
            source: TASKS,
            id: item?.id,
            state: {
              refreshData,
            },
          });
        },
        actions: (
          <ActionsPopup
            actions={[
              editModalAction({
                item: {
                  ...item,
                  assignees_ids: [...assignees?.map((val) => val?.id)], // get all assignees
                },
                source: TASKS,
                refreshData,
              }),
            ]}
          />
        ),
      };
    }),
  ];

  const tableButton = {
    modal: true,
    source: TASKS,
    state: {
      defaultValues: {
        object_id: params?.object_id,
        content_type: params?.type,
      },
      // reloadData,
      refreshData,
    },
  };

  const detailsData = ({ data, state }) => {
    const {
      title,
      description,
      object_id,
      content_type,
      created_at,
      updated_at,
      due_date,
      assignees,
      created_by,
      updated_by,
      status,
    } = data || {};
    return {
      title,
      description,
      ref_number: <RefLink>{getModuleRef(object_id, content_type)}</RefLink>,
      type: <StatusTag status={content_type} />,
      assignees: <UserGroup assignees={assignees} />,
      due_date: formatDate(due_date),
      status: <StatusTag status={status} />,
      ...getUser(created_by, "created_by"),
      ...getUser(updated_by, "updated_by"),
      created_at: formatDateTime(created_at),
      updated_at: formatDateTime(updated_at),
      actions: <TaskActions data={data} state={state} />,
    };
  };

  return { tableColumns, tableData, tableButton, detailsData };
};

const TaskActions = ({ data, state }) => {
  const { uploadData, submitting } = useSet();
  const { handleClose, refreshData } = state || {};
  const status = data?.status;

  const handleSubmit = (status) => {
    uploadData({
      api: API_TASKS,
      id: data?.id,
      params: {
        status,
      },
      onSuccess: () => {
        refreshData();
        handleClose();
      },
    });
  };

  const buttonProps = { className: "font12 capitalize" };
  return (
    <div className="flexNullCenter gap10">
      {[COMPLETED, CLOSED].includes(status) ? (
        <Button
          {...buttonProps}
          onClick={() => handleSubmit(PENDING)}
          loading={submitting}
        >
          Reopen Task
        </Button>
      ) : (
        <>
          <Button
            {...buttonProps}
            onClick={() => handleSubmit(COMPLETED)}
            loading={submitting}
            icon={<Checkmark />}
          >
            {COMPLETED}
          </Button>
          <Button
            {...buttonProps}
            onClick={() => handleSubmit(CLOSED)}
            loading={submitting}
            type="default"
          >
            Close
          </Button>
        </>
      )}
    </div>
  );
};

function UserGroup({ assignees }) {
  return (
    <Avatar.Group>
      {assignees?.length > 0 &&
        assignees?.map((assignee) => {
          const username = assignee?.display_name;
          return (
            <Tooltip
              title={username}
              placement="top"
              overlayStyle={{ fontSize: 12 }}
            >
              <Avatar
                className="semiBold"
                size={30}
                style={{
                  backgroundColor: "orange",
                  fontSize: 12,
                }}
              >
                {username?.charAt(0)}
              </Avatar>
            </Tooltip>
          );
        })}
    </Avatar.Group>
  );
}
