import { useState } from "react";
import useAxios from "./useAxios";
import { message } from "antd";

const useGet = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState();
  const { get } = useAxios();

  const loadData = ({
    api,
    isExternal,
    params,
    method,
    id,
    replace = true,
    token,
    successText,
    onSuccess,
  }) => {
    if (replace) setData(null);
    setSuccess(false);
    setLoading(true);
    // If id is passed get single instance, other fetch list
    get({
      api: id ? `${api}${id}/` : api,
      params,
      isExternal,
      loader: setLoading,
      errorHandler: setError,
      successHandler: (data) => {
        setData(data);
        setSuccess(true);
        if (successText) {
          message.success(successText);
        }
        onSuccess && onSuccess(data);
      },
      methodName: method,
      token,
    });
  };

  return { loading, error, data, success, loadData };
};

export default useGet;
