import React, { useEffect, useState } from "react";
import { ModalDialog } from "../common/modals/ModalDialog";
import { RenderForm } from "../../utils/formUtils";
import useSet from "../../hooks/common/useSet";
import { modalFormHooks } from "../../hooks/handlers/useFormHandler";
import { makeSingular } from "../../utils/helperUtils";
import useForm from "../../hooks/common/useForm";
import { message } from "antd";
import { ERROR_MESSAGE } from "../../constants/constants";

export const ModalHandler = ({
  source,
  button,
  title,
  id,
  reload,
  defaultValues,
  state,
}) => {
  const { form, updateFormValues, onChange, validateForm } =
    useForm(defaultValues);
  const { uploadData, error, setError, submitting, success } = useSet();
  const [open, setOpen] = useState(false);

  const formHook = modalFormHooks?.[source];
  const {
    title: modalTitle,
    fields,
    onSubmit: handleSubmit,
  } = formHook({ form, updateFormValues, source, state });

  useEffect(() => {
    if (success) {
      message.success("Status updated successfully");
      reload();
    } else if (error) {
      const errorMessage = error?.detail || ERROR_MESSAGE;
      message.error(errorMessage);
    } else if (submitting) {
      message.loading({
        content: "Please wait...",
      });
    }
  }, [success, error, submitting]);

  function onSubmit() {
    if (validateForm(fields, setError)) {
      if (handleSubmit) {
        let upload_data = handleSubmit({ payload: form, id });

        // handle submission
        if (upload_data) {
          uploadData(upload_data);
          setOpen(false);
        }
      }
    }
  }

  return (
    <ModalDialog
      button={button}
      loading={submitting}
      title={`${title} ${makeSingular(source)}` || modalTitle}
      onSubmit={onSubmit}
      setOpen={setOpen}
      open={open}
    >
      <div className="flexColumn gap10">
        <RenderForm
          {...{
            fields,
            form,
            updateFormValues,
            onChange,
            error,
          }}
        />
      </div>
    </ModalDialog>
  );
};
