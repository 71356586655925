import React from "react";
import { useReports } from "../../../hooks/reports/useReports";
import {
  LoanReportView,
  ReportsHeader,
  TransactionChartView,
} from "../reports/ReportsView";
import { OverlayLoader } from "../../common/Loader";

export const StaffDashboard = () => {
  const { reports, setFilter, loading } = useReports();
  return (
    <div className="flexColumn gap15 h100 relative">
      <ReportsHeader setFilter={setFilter} title="Dashboard" />

      {loading && <OverlayLoader />}
      <div className="scroll h100 gap10 flexColumn">
        <LoanReportView loans={reports?.loans} />
        <TransactionChartView transactions={reports?.transactions} />
      </div>
    </div>
  );
};
