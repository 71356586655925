import moment from "moment";
import {
  ACCEPT,
  ACCEPTED,
  ACTIVE,
  ADD,
  ALL,
  APP_COMPANY_CODE,
  APPLICATION,
  APPROVE,
  APPROVED,
  CANCEL,
  CANCELLED,
  COMPLETED,
  CONST_CREDENTIALS_KEY,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DISBURSEMENT,
  DUE_TODAY,
  EDIT,
  INACTIVE,
  INSTALLMENT,
  LOAN,
  MISSED,
  NOT_PAID,
  OVERDUE,
  PAID,
  PARTIALY_PAID,
  PENDING,
  REJECT,
  REJECTED,
  REPAYMENT,
  TRANSACTION,
  WRITTEN_OFF,
} from "../constants/constants";
import { EmptyField } from "../components/elements/Elements";
import { jwtDecode } from "jwt-decode";
import {
  Add,
  Checkmark,
  Close,
  CreateOutline,
  DocumentText,
} from "@raresail/react-ionicons";
import { Button, message, Tag } from "antd";
import { ModalHandler } from "../components/handlers/ModalHandler";
import { API_EXPORT_DATA, getBaseUrl } from "../constants/apis";
import axios from "axios";
import { openModalForm } from "./modalUtils";

export const setCredentials = (value) => {
  try {
    window.localStorage.setItem(CONST_CREDENTIALS_KEY, JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

export const removeCredentials = () => {
  try {
    localStorage.clear();
  } catch (e) {
    // saving error
  }
};

export const decodeUser = (token) => {
  try {
    const decoded = jwtDecode(token);
    const user_id = decoded.user_id;
    return { id: user_id };
    // return decoded?.user;
  } catch (err) {
    // saving error
  }
};

export const getCredentials = () => {
  try {
    const res = window.localStorage.getItem(CONST_CREDENTIALS_KEY);
    return res ? JSON.parse(res) : null;
  } catch (e) {
    // saving error
  }
};

export function stringify(text) {
  return text?.replaceAll?.("_", " ");
}

export function getForm(source, id) {
  if (id) return `/${source}/update/${id}`;
  return `/${source}/create`;
}

export function getRegister(source, status) {
  const suffix = status ? `/${status}` : "";
  return `/register/${source}${suffix}`;
}

export function getWorkArea(source, status) {
  const suffix = status ? `/${status}` : "";
  return `/workarea/${source}${suffix}`;
}

export function getRegisterTitle(source, status) {
  const prefix = status ? `${status} ` : "";
  return `${prefix}${source}`;
}

export function addSelectOption(id, name) {
  return { id, name: capitalizeText(name || stringify(id)) };
}

export function filterOption(value, label) {
  return { value, label: label || capitalizeText(stringify(value)) };
}

export function addTagOption(data, options) {
  const { label, tag, exact, suffix } = options || {};
  const labelValue = data?.name || data?.[label];
  const tagValue = exact ? tag : data?.[tag];

  return {
    ...data,
    id: data?.id,
    name: (
      <div className="flexSpaceCenter">
        {labelValue}
        <Tag>
          {tagValue}
          {suffix}
        </Tag>
      </div>
    ),
    searchLabel: `${labelValue} ${tagValue}`,
  };
}

export const createColumn = (id, title, align) => {
  if (typeof id === "object")
    return {
      ...id,
      title: stringify(id?.title || id?.id),
    };
  return {
    id,
    title: stringify(title || id),
    align,
  };
};

export const addColumns = (columns = []) => {
  return columns?.map((column) => createColumn(column));
};

export const formatDate = (value, format) => {
  if (value) return moment(value)?.format(format || DATE_FORMAT);
  return <EmptyField />;
};

export const formatDateTime = (value, format) => {
  if (value) return moment(value)?.format(format || DATE_TIME_FORMAT);
  return <EmptyField />;
};

export const getStatusColor = (status) => {
  switch (status) {
    // case OVERDUE:
    //   return "red";
    case PENDING:
    case PARTIALY_PAID:
      return "gold-inverse";
    case ACCEPTED:
    case COMPLETED:
    case ACTIVE:
    case PAID:
      return "green-inverse";
    case APPROVED:
    case NOT_PAID:
      return "blue-inverse";
    case REJECTED:
    case MISSED:
    case CANCELLED:
      return "red-inverse";
    case INACTIVE:
    case OVERDUE:
    case WRITTEN_OFF:
      return "#808080";
    case REPAYMENT:
      return "green";
    case DISBURSEMENT:
    case DUE_TODAY:
      return "blue";

    default:
      break;
  }
};
export const getButtonProps = (status) => {
  let icon, color;
  switch (status?.toLowerCase()) {
    case APPROVE:
    case ACCEPT:
      icon = Checkmark;
      color = "green";
      break;
    case CANCEL:
    case REJECT:
      icon = Close;
      color = "#f5222d";
      break;
    default:
      break;
  }
  return { icon, color };
};

export const RenderTitleButtons = ({
  buttons = [],
  source,
  id,
  reload,
  state,
}) => {
  return (
    <>
      {buttons?.map(({ label, icon, onClick, props, color, value: status }) => {
        const Icon = icon || getButtonProps(label)?.icon;

        const button = (
          <Button
            type="primary"
            className="font12 capitalize"
            onClick={onClick}
            icon={Icon && <Icon fontSize={15} />}
            style={{
              backgroundColor: color || getButtonProps(label)?.color,
              boxShadow: "none",
              // borderRadius: 0,
              marginRight: 1,
              padding: 10,
            }}
            {...props}
          >
            {label}
          </Button>
        );
        return (
          <ModalHandler
            reload={reload}
            defaultValues={{ status }}
            state={state}
            source={source}
            button={button}
            title={label}
            id={id}
          />
        );
      })}
    </>
  );
};

export const numberFormat = (value) => Number(value || 0).toLocaleString();

export const currencyFormat = (value) => `${numberFormat(value)}/=`;

export const interestFormat = (value) => `${value}%`;

export function capitalizeText(str) {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");
}

export const returnObject = (show, object) => {
  return show ? object : [];
};

export const createAction = (label, onClick, icon) => {
  return { label, onClick, icon };
};

export const editAction = (source, id) => {
  return { label: EDIT, to: getForm(source, id), icon: CreateOutline };
};

export const editModalAction = ({ source, item: data, id, refreshData }) => {
  return {
    label: EDIT,
    icon: CreateOutline,
    onClick: () => {
      openModalForm({
        source,
        id: id || data?.id,
        state: {
          defaultValues: {
            ...data,
          },
          refreshData,
        },
      });
    },
  };
};

export const addAction = (source, to) => {
  return {
    label: `${ADD} ${makeSingular(source)}`,
    to: to || getForm(source),
    icon: Add,
  };
};

export const cleanPayload = (payload) => {
  delete payload?.id;
  delete payload?.created_at;
  delete payload?.created_by;
  delete payload?.updated_at;
  delete payload?.updated_by;
};

export const getInterestAmount = (interest, principal, loan_term) => {
  const amount = (interest / 100) * principal;
  return amount;
};

export const createAttachments = (attachments = [], caption) => {
  const attachmentsList = attachments?.map((attachment) => {
    return {
      file: attachment?.originFileObj,
      caption,
    };
  });
  return attachmentsList;
};

export const prepareAttachmentsPost = (attachments, field, object, noFile) => {
  const prefix = field || "attachments";
  const extras = {};
  const atts = (attachments || []).filter((item) => noFile || item?.file);
  const inflated_data = [];
  atts.forEach((item) => {
    inflated_data.push(item);
  });

  inflated_data
    .map((item) => ({ ...item, ...object }))
    .forEach((item, idx) => {
      Object.keys(item).forEach((key) => {
        extras[`${prefix}[${idx}]${key}`] = item[key];
      });
    });
  return extras;
};

export const getFileFormat = (url) => {
  const urlParts = url?.split(".");
  return urlParts?.pop();
};

export function makeSingular(value) {
  const str = stringify(value);
  if (str.endsWith("ies")) {
    return `${str.slice(0, -3)}y`;
  } else if (str.endsWith("hes")) {
    return str.slice(0, -2);
  } else if (str.endsWith("s")) {
    return str.slice(0, -1);
  } else {
    return str;
  }
}

export function getUser(user, field_name) {
  const username = user?.display_name || user?.email;
  return { [field_name || "created_by"]: username };
}

export function removeKeys(object, array) {
  const newObject = { ...object };

  array.forEach((value) => {
    delete newObject[value];
  });

  return newObject;
}

export const scaleFormatter = (value) => {
  let label = Intl.NumberFormat("en", { notation: "compact" }).format(value);
  return label;
};

export const createFile = ({ response, fileName }) => {
  const suggestedFilename = response.headers["content-disposition"]
    .split("=")[1]
    .replace(/"/g, "");

  const url = URL.createObjectURL(response.data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName || suggestedFilename || "file");
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const DownloadExcel = ({ module, setLoading }) => {
  setLoading(true);
  const token = getCredentials()?.access;
  axios
    .get(getBaseUrl(API_EXPORT_DATA), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params: { module },
      responseType: "blob",
    })
    .then((res) => {
      createFile({ response: res });
      setLoading(false);
      message.success("Data exported successfully");
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      message.error("Error exporting data");
    });
};

export const DownloadFile = ({ api, params, id, setLoading }) => {
  setLoading(true);
  const token = getCredentials()?.access;
  const url = id ? `${getBaseUrl(api)}${id}/` : getBaseUrl(api);
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      params,
      responseType: "blob",
    })
    .then((res) => {
      createFile({ response: res });
      setLoading(false);
      message.success("File downloaded successfully");
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      message.error("Error downloading file");
    });
};

export function getModuleRef(object_id, model_name) {
  switch (model_name?.toLowerCase()) {
    case LOAN:
    case TRANSACTION:
    case INSTALLMENT:
      return `${APP_COMPANY_CODE}/LOA/${object_id}`;
    case APPLICATION:
      return `${APP_COMPANY_CODE}/APP/${object_id}`;
    default:
      return object_id;
  }
}

export function addForm(source, label) {
  return {
    label: label || `New ${makeSingular(source)}`,
    to: getForm(source),
    icon: <Add />,
  };
}

export function addRegister(source, status, label) {
  if (status === ALL) label = `All ${source}`;
  return {
    label: label || status || "Register",
    to: getRegister(source, status),
    icon: <DocumentText />,
  };
}

export function addWorkArea(source, status, label) {
  if (status === ALL) label = `All ${source}`;
  return {
    label: label || `${status}`,
    to: getWorkArea(source, status),
    icon: <DocumentText />,
  };
}

export const stopPropagation = (e) => e.stopPropagation();

export function greeting() {
  const hour = moment().hour();

  if (hour > 16) {
    return "Good evening";
  }

  if (hour > 11) {
    return "Good afternoon";
  }

  return "Good morning";
}

export const unsetKeys = (object, keys = []) => {
  const temp = { ...object };
  keys?.forEach((item) => {
    delete temp[item];
  });
  return temp;
};
