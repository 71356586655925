import {
  addAction,
  addColumns,
  currencyFormat,
  editAction,
  formatDateTime,
  getForm,
  getUser,
} from "../../utils/helperUtils";
import { CHARGES } from "../../constants/constants";
import { ActionsPopup, StatusTag } from "../../components/elements/Elements";

export const useCharges = () => {
  const tableColumns = () =>
    addColumns([
      "name",
      "amount",
      "type",
      "description",
      "created_by",
      "created_at",
      "actions",
    ]);

  const tableData = (data) => [
    ...data.map((item) => {
      const { created_by, created_at, amount, type } = item || {};
      return {
        ...item,
        ...getUser(created_by),
        amount: currencyFormat(amount),
        type: <StatusTag status={type} />,
        created_at: formatDateTime(created_at),
        actions: <ActionsPopup actions={[editAction(CHARGES, item?.id)]} />,
      };
    }),
  ];

  const tableActions = () => [addAction(CHARGES, `${getForm(CHARGES)}`)];

  const tableButton = {
    to: `${getForm(CHARGES)}`,
  };

  return {
    tableColumns,
    tableData,
    tableActions,
    tableButton,
  };
};
