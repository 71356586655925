import { useEffect } from "react";
import { CLIENT, SELECT, STAFF, SWITCH, TEXT } from "../../constants/constants";
import useGet from "../common/useGet";
import { useRegisterForm } from "../register/useRegisterForm";
import {
  API_BRANCHES,
  API_CREATE_USER,
  API_ROLES,
  API_USERS,
} from "../../constants/apis";
import { addSelectOption } from "../../utils/helperUtils";
import { IsPublicUser } from "../../utils/permissionUtils";

export const useUsersForm = ({ id, form }) => {
  const isPublicUser = IsPublicUser();
  const isUpdate = id;
  const { fields } = useRegisterForm({ id, form });

  const { loadData: getRoles, data: roles, loading: loadingRoles } = useGet();
  const {
    loadData: getBranches,
    data: branches,
    loading: loadingBranches,
  } = useGet();

  useEffect(() => {
    getRoles({ api: API_ROLES });
    getBranches({ api: API_BRANCHES });
  }, []);

  const isStaff = form?.is_staff === true ? true : false;

  const { address, is_employed, profession } = form?.profile || {};

  console.log(form);

  const sections = [
    {
      title: "User Details",
      fields,
    },
    {
      title: "Branch Details",
      fields: [
        {
          field: "branch",
          type: SELECT,
          required: true,
          props: { loading: loadingBranches },
          options: branches?.results,
          value: form?.profile?.branch,
        },
      ],
    },
    {
      title: "Role Details",
      fields: [
        {
          label: "Account Type",
          field: "is_staff",
          value: isUpdate && "false",
          type: SELECT,
          required: true,
          options: [
            addSelectOption("false", CLIENT),
            addSelectOption(true, STAFF),
          ],
        },
        {
          label: "System Role",
          field: "role",
          type: SELECT,
          required: true,
          props: { loading: loadingRoles },
          options: roles?.results,
          visible: form?.is_staff === true,
        },
        {
          field: "is_active",
          type: SWITCH,
        },
      ],
      visible: !isPublicUser,
    },
    {
      title: "Location Details",
      fields: [
        {
          label: "Residential Address",
          field: "address",
          type: TEXT,
          value: address,
          required: true,
          props: {
            placeholder: "Where do you stay?",
          },
        },
      ],
      visible: !isStaff,
    },
    {
      title: "Employment Details",
      fields: [
        {
          field: "is_employed",
          type: SELECT,
          required: true,
          value: is_employed === false ? "false" : true,
          options: [
            addSelectOption(true, "Employed"),
            addSelectOption("false", "Not Employed"),
          ],
        },
        {
          label: "Profession / Nature of work",
          field: "profession",
          type: TEXT,
          required: true,
          value: profession,
          props: {
            placeholder: "Engineering, Law, Construction",
            capitalize: false,
          },
          visible: form?.is_employed !== "false",
        },
      ],
      visible: !isStaff,
    },
    {
      title: "Marital Status",
      fields: [
        {
          field: "is_married",
          type: SELECT,
          required: true,
          // value: is_employed === false ? "false" : true,
          options: [
            addSelectOption(true, "Yes"),
            addSelectOption("false", "No"),
          ],
        },
      ],
      visible: !isStaff,
    },
  ];

  function onSubmit({ payload, id }) {
    let params = {
      email: payload.email,
      first_name: payload.first_name,
      last_name: payload.last_name,
      phone_number: payload.phone_number,
      role: payload.role,
      is_active: payload.is_active,
      is_staff: payload.is_staff,
      profile: {
        is_employed: payload?.is_employed,
        address: payload?.address,
        profession: payload?.profession,
        branch: payload?.branch,
      },
    };

    if (isPublicUser && id) {
      params = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone_number: payload.phone_number,
        profile: {
          is_employed: payload?.is_employed,
          address: payload?.address,
          profession: payload?.profession,
          branch: payload?.branch,
        },
      };
    }

    // return console.log(params);

    // UPDATE
    if (id) {
      return {
        id,
        api: API_USERS,
        params,
      };
    }
    return { api: API_CREATE_USER, params };
  }
  return { sections, onSubmit };
};
