import { IconButton } from "@mui/material";
import { Menu } from "@raresail/react-ionicons";
import { Drawer } from "antd";
import React, { useState } from "react";
import { LeftMenu } from "../menus/LeftMenu";

export const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!open);
  return (
    <div id="mobile">
      <IconButton onClick={toggleDrawer}>
        <Menu />
      </IconButton>
      <Drawer
        onClose={() => setOpen(false)}
        placement="left"
        title={false}
        width={200}
        open={open}
        styles={{ body: { padding: 0 }, header: { display: "none" } }}
      >
        <LeftMenu collapsible={false} />
      </Drawer>
    </div>
  );
};
