import { Checkbox } from "antd";
import React from "react";

export const GenericCheckBox = ({
  field,
  label,
  updateFormValues,
  value,
  ...props
}) => {
  function handleChange(e) {
    const checked = e.target.checked;
    updateFormValues({ [field]: checked });
  }

  return (
    <>
      <Checkbox
        className="flexNullCenter gap10 capitalize"
        checked={value}
        onChange={handleChange}
        {...props}
      >
        <p {...props}>{label}</p>
      </Checkbox>
    </>
  );
};
