import React, { useEffect } from "react";
import useGet from "../../hooks/common/useGet";
import { DetailsView } from "../common/views/DetailsView";
import { Loader } from "../common/Loader";
import { sourceApis } from "../../hooks/handlers/useApiHandler";
import { registerHooks } from "../../hooks/handlers/useRegisterHandler";
import { EmptyView } from "../elements/Elements";

export const ViewHandler = ({ source, id, state }) => {
  const hooks = registerHooks?.[source];

  const { data, loading, loadData } = useGet();

  const { detailsData } = hooks({ data, reload: getData });

  // fetch the instance data
  function getData() {
    const api = sourceApis?.[source];
    if (id && source && api) {
      loadData({ id, api });
    }
  }

  useEffect(() => {
    getData();
  }, [id]);

  if (loading) return <Loader />;

  if (!data) return <EmptyView />;

  return (
    <div>
      <DetailsView data={detailsData({ data, state })} />
    </div>
  );
};
