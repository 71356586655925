import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  decodeUser,
  getCredentials,
  removeCredentials,
  setCredentials,
} from "../../utils/helperUtils";
import { useLocalStorage } from "../../hooks/common/useLocalStorage";
import { PROFILE } from "../../constants/constants";
import { API_USERS } from "../../constants/apis";
import axios from "axios";
import { BASE_API_URL } from "../../constants/config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [authToken, setAuthToken] = useState();
  const { getData, setData } = useLocalStorage();

  function getUserProfile(token) {
    const storedProfile = getData(PROFILE);

    function updateProfile(data) {
      setProfile(data);
      setLoggedIn(true);
    }

    if (storedProfile) updateProfile(storedProfile);
    else {
      setLoggedIn(null); // show loading screen
      const uid = decodeUser(token)?.id;
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: `${BASE_API_URL}${API_USERS}${uid}/`,
      }).then(({ data }) => {
        setData(PROFILE, data);
        updateProfile(data);
      });
    }
  }

  useEffect(() => {
    const fetchCredentials = () => {
      const res = getCredentials();
      if (res) {
        const token = res.access;
        setAuthToken(token);
        setUser(decodeUser(token));
        getUserProfile(token);
      } else {
        setLoggedIn(false);
      }
    };

    fetchCredentials();
  }, []);

  const login = (credentials) => {
    const token = credentials.access;
    setCredentials(credentials);
    setAuthToken(token);
    setUser(decodeUser(token));
    getUserProfile(token);
  };

  const logout = () => {
    removeCredentials();
    setUser(null);
    setLoggedIn(false);
    // message.info("You have been logged out");
  };

  const updateUser = (user) => {
    setCredentials({ access: authToken, user });
    setUser(user);
  };

  const renderBody = (ele) => cloneElement(ele);

  return (
    <AuthContext.Provider
      value={{
        login,
        loggedIn,
        logout,
        user,
        authToken,
        updateUser,
        profile,
      }}
    >
      {renderBody(children)}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

const LogoutRoute = () => useAuth().logout();

export { AuthProvider, useAuth, LogoutRoute };
