import React from "react";

import { usePermissions } from "../../../utils/permissionUtils";
import { CAN_PUBLIC } from "../../../constants/constants";
import { ClientDashboard } from "./ClientDashboard";
import { StaffDashboard } from "./StaffDashboard";

export const Dashboard = () => {
  const { getPermissions } = usePermissions();
  const isPublicUser = getPermissions([CAN_PUBLIC]);

  return <>{isPublicUser ? <ClientDashboard /> : <StaffDashboard />}</>;
};
