import {
  addAction,
  addColumns,
  editAction,
  formatDateTime,
  getForm,
  getUser,
  numberFormat,
} from "../../utils/helperUtils";
import { CHARGE_PENALTIES, PAID } from "../../constants/constants";
import {
  ActionsPopup,
  BalanceField,
  StatusTag,
} from "../../components/elements/Elements";

export const useChargePenalties = ({ params, reloadData }) => {
  const tableColumns = () =>
    addColumns([
      "type",
      "amount",
      { id: "paid_amount", title: "paid" },
      "balance",
      "comment",
      { id: "payment_date", title: "Paid date" },
      "created_by",
      "created_at",
      "status",
      "actions",
    ]);
  const tableData = (data) => [
    ...data.map((item) => {
      const {
        created_by,
        created_at,
        amount,
        type,
        status,
        balance,
        paid_amount,
        payment_date,
      } = item || {};

      const isPaid = status === PAID;

      return {
        ...item,
        ...getUser(created_by),
        paid_amount: numberFormat(paid_amount),
        amount: numberFormat(amount),
        balance: <BalanceField isPaid={isPaid} balance={balance} />,

        type: <StatusTag status={type} />,
        status: <StatusTag status={status} />,
        created_at: formatDateTime(created_at),
        payment_date: formatDateTime(payment_date),
        actions: (
          <ActionsPopup actions={[editAction(CHARGE_PENALTIES, item?.id)]} />
        ),
      };
    }),
  ];

  const tableActions = () => [
    addAction(CHARGE_PENALTIES, `${getForm(CHARGE_PENALTIES)}`),
  ];

  const tableButton = {
    modal: true,
    source: CHARGE_PENALTIES,
    state: {
      defaultValues: params,
      reloadData,
    },
  };

  return {
    tableColumns,
    tableData,
    tableActions,
    tableButton,
  };
};
