import { BASE_API_URL } from "./config";

export const getBaseUrl = (url) => {
  return `${BASE_API_URL}${url}`;
};

// AUTHENTICATION
export const API_TOKEN = "token/";
export const API_CREATE_USER = "create-user/";
export const API_USERS = "users/";
export const API_PASSWORD_RESET = "password-reset/";
export const API_RESET_PASSWORD = "reset-password/";

// LOANS
export const API_LOANS = "loans/";
export const API_LOAN_STATEMENT = "loans/statement/";

// APPLICATIONS
export const API_APPLICATIONS = "applications/";

// PAYMENTS
export const API_TRANSACTIONS_LOAN = "transactions/loan/";
export const API_TRANSACTIONS = "transactions/";

// SCHEDULE
export const API_SCHEDULE = "schedule/";

// INSTALLMENTS
export const API_INSTALLMENTS = "installments/";

// CATEGORIES
export const API_CATEGORIES = "categories/";

// ROLES
export const API_ROLES = "roles/";

// PERMISSIONS
export const API_PERMISSIONS = "permissions/";

// DOCUMENTS
export const API_DOCUMENTS = "documents/";

// COMMENTS
export const API_COMMENTS = "comments/";

// CHARGES
export const API_CHARGES = "charges/";
export const API_CHARGE_PENALTIES = "charge-penalties/";

// AUDIT TRAIL
export const API_AUDIT_TRAIL = "audit-trail/";

// REPORTS
export const API_REPORT_SUMMARY = "reports/summary/";

// EXPORTS
export const API_EXPORT_DATA = "export-data/";

// TASKS
export const API_TASKS = "tasks/";

// AUTOMATIONS
export const API_SEND_REMINDERS = "send-reminders/";
export const API_CHECK_INSTALLMENTS = "check_installments/";

// BRANCHES
export const API_BRANCHES = "branches/";
