import React, { useState } from "react";
import { Button, Steps } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

export const GenericStepper = ({
  steps,
  current,
  prev,
  next,
  enableSubmit,
  submitting,
}) => {
  const items = steps?.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  // PREVIOUS
  const prevButton = () => {
    return (
      <>
        {current > 0 && (
          <Button
            className="font13"
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </>
    );
  };

  // NEXT
  const nextButton = () => {
    return (
      <>
        {current < steps.length - 1 && (
          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            htmlType="submit"
            className="font13"
            // onClick={next}
          >
            Next Step
          </Button>
        )}
      </>
    );
  };

  // DONE
  const submitButton = () => {
    return (
      <>
        {current === steps.length - 1 && (
          <Button
            disabled={!enableSubmit}
            type="primary"
            className="font13"
            htmlType="submit"
            loading={submitting}
          >
            Submit
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      {/* STEPS */}
      <Steps
        className="whiteBg radius10"
        current={current}
        items={items}
        style={{ padding: "15px 20px", fontSize: 10 }}
        size="small"
      />
      {/* CONTENT */}
      <div className="flexColumn gap15 scroll radius10">
        <div>{steps?.[current]?.content}</div>
      </div>
      {/* ACTIONS */}
      <div>
        {nextButton()}
        {submitButton()}
        {prevButton()}
      </div>
    </>
  );
};

export function useStepper() {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  return { next, prev, current };
}
