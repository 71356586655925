import { Tag } from "antd";
import {
  ACTIVE,
  COMMENTS_PLACEHOLDER,
  COMPONENT,
  DISBURSEMENT,
  SWITCH,
  TEXT,
} from "../../constants/constants";
import { submitApis } from "../handlers/useApiHandler";
import { currencyFormat } from "../../utils/helperUtils";

export const useActionForm = ({ source, form, state }) => {
  const isDisbursement = form?.status === ACTIVE;
  const fields = [
    {
      field: "comment",
      props: { rows: 5, placeholder: COMMENTS_PLACEHOLDER },
      type: TEXT,
      required: true,
    },
    {
      type: COMPONENT,
      component: (
        <div className="flexNullCenter gap15 border p10 rounded">
          <div className="flexColumn gap5 flexGrow">
            <p className="font12 greyColor">
              A new transaction will be automatically created
            </p>
            <div className="flexSpaceCenter">
              <p className="font13 semiBold">
                Amount: {currencyFormat(state?.amount)}
              </p>
              <Tag className="capitalize m0" color="blue">
                {DISBURSEMENT}
              </Tag>
            </div>
          </div>
        </div>
      ),
      visible: isDisbursement && form?.transaction_type,
    },
    {
      field: "transaction_type",
      label: "Create new disbursement transaction",
      type: SWITCH,
      props: { defaultValue: true },
      visible: isDisbursement,
    },
  ];

  function onSubmit({ payload, id }) {
    const api = submitApis[source];
    let params = payload;

    if (payload?.status === ACTIVE && payload?.transaction_type !== false) {
      params.transaction_type = DISBURSEMENT;
    }

    if (api) {
      return { id, api, params };
    }
  }

  return { fields, onSubmit };
};
