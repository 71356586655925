import { ExpandableTable } from "../../components/common/tables/ExpandableTable";
import { API_CATEGORIES } from "../../constants/apis";
import { CHECK_BOX, COLUMN, SWITCH, TEXT } from "../../constants/constants";
import { FieldProps } from "../../utils/formUtils";

export const useCategoriesForm = ({ updateFormValues, form }) => {
  const fields = [
    {
      field: "name",
      type: TEXT,
      required: true,
    },
    {
      field: "description",
      type: TEXT,
      props: { rows: 1, optional: true },
    },
    {
      field: "interest_rate",
      props: { ...FieldProps.number, prefix: "%" },
      type: TEXT,
    },
    {
      field: "max_amount",
      props: FieldProps.number,
      type: TEXT,
    },
    {
      field: "is_active",
      type: SWITCH,
    },
  ];

  const sections = [
    {
      title: "Category Details",
      fields,
    },
    {
      title: "document_fields",
      component: (
        <ExpandableTable
          data={form?.document_fields}
          formHook={useDocumentFieldsForm}
          updateFormValues={updateFormValues}
          field="document_fields"
        />
      ),
      layout: COLUMN,
    },
  ];
  return { sections, onSubmit };
};

function onSubmit({ payload, id }) {
  return { id, api: API_CATEGORIES, params: payload };
}

export const useDocumentFieldsForm = () => {
  const fields = [
    {
      field: "name",
      label: "label",
      type: TEXT,
      required: true,
      props: { noLabel: true },
    },
    {
      field: "caption",
      type: TEXT,
      required: true,
      props: { noLabel: true },
    },
    {
      field: "description",
      type: TEXT,
      props: { noLabel: true },
    },
    {
      field: "is_required",
      type: CHECK_BOX,
      required: true,
      props: { style: { textWrap: "nowrap" } },
    },
  ];

  const formatData = (data) => {
    const newData = {
      ...data,
      caption: (data?.caption || data?.name)?.replace(" ", "_").toLowerCase(),
    };
    return newData;
  };

  return { fields, formatData };
};
