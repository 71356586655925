import { Input, Popover, Select, Tag } from "antd";
import React, { useState } from "react";
import { Button } from "../GenericButton";
import { IconButton } from "@mui/material";
import {
  CloseCircleSharp,
  Filter,
  Funnel,
  SearchOutline,
} from "@raresail/react-ionicons";
import { stringify } from "../../../utils/helperUtils";
import { DATE, SEARCH_PLACEHOLDER, SELECT } from "../../../constants/constants";
import { DateRangeInput } from "../inputs/DateRangeInput";

export const TableFilter = ({ setFilter, filter, field, active }) => {
  const { options, type } = filter || {};
  const [value, setValue] = useState();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  function updateFilter() {
    setFilter((prev) => {
      // remove page
      delete prev.page;
      return { ...prev, ...value };
    });
    setOpen(false);
  }

  function updateValue(value) {
    setValue({ [field]: value });
  }

  const style = {
    placeholder: options ? `${SELECT} option` : SEARCH_PLACEHOLDER,
    className: "font13",
    allowClear: true,
  };

  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      overlayStyle={{ minWidth: 200 }}
      content={
        <div className="flexColumn gap10">
          <div className="semiBold font13">Add Filter</div>

          {options ? (
            <Select
              {...style}
              style={{ fontSize: 10 }}
              options={filter?.options}
              rootClassName="smallSelect"
              onChange={(val) => updateValue(val)}
            />
          ) : type === DATE ? (
            <DateRangeInput field={field} setValue={setValue} />
          ) : (
            <Input onChange={(e) => updateValue(e.target.value)} {...style} />
          )}

          <Button onClick={updateFilter}>Apply Filter</Button>
        </div>
      }
      placement="bottomRight"
      arrow={false}
      trigger="click"
    >
      <IconButton
        size="small"
        color={active && "info"}
        style={{ color: !active && "darkgrey" }}
      >
        <Funnel fontSize={13} />
      </IconButton>
    </Popover>
  );
};

export const FilterBar = ({
  filters = {},
  setFilter,
  columns = [],
  filterBarOptions,
}) => {
  const { selectProps, visible, showSearch } = filterBarOptions || {};

  function clearFilter(key) {
    const filterValue = filters[key];

    const newData = { ...filters };
    delete newData[key];

    if (filterValue === DATE) {
      delete newData[`${key}_after`];
      delete newData[`${key}_before`];
    }

    setFilter(newData);
  }

  const filterFields = Object.keys(filters).filter(
    (key) => filters?.[key] && columns.some((column) => column.id === key)
  );

  if (filterFields.length <= 0 && !visible) return;

  return (
    <div className="flexSpaceCenter" style={{ padding: "5px 10px" }}>
      <div className="flexNullCenter gap10">
        {/* SELECT */}
        {selectProps?.options && (
          <Select
            allowClear
            rootClassName="smallSelect"
            style={{ width: 110, height: 28 }}
            onChange={(val) => {
              // console.log(val);
              setFilter((prev) => ({ ...prev, [selectProps?.field]: val }));
            }}
            {...selectProps}
          />
        )}
        {/* FILTERS */}
        <div className="flexWrap gap10">
          <Tag
            className="radius20 m0"
            icon={<Filter style={{ marginRight: 5, marginBottom: -1 }} />}
          >
            Filters
          </Tag>
          {filterFields?.map((key, i) => {
            return (
              <Tag key={i} className="capitalize radius20 m0" color="blue">
                <div className="flexNullCenter">
                  {stringify(key)}
                  <CloseCircleSharp
                    onClick={() => {
                      clearFilter(key);
                    }}
                    className="pointer"
                    fontSize={15}
                    style={{ marginLeft: 2, marginRight: -3 }}
                  />
                </div>
              </Tag>
            );
          })}
        </div>
      </div>
      {/* SEARCH */}
      {showSearch && (
        <div>
          <Input
            prefix={<SearchOutline />}
            placeholder={SEARCH_PLACEHOLDER}
            className="font12"
          />
        </div>
      )}
    </div>
  );
};
