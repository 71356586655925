import { DatePicker } from "antd";
import React from "react";
import { DATE } from "../../../constants/constants";

export const DateRangeInput = ({
  field,
  start_field,
  end_field,
  setValue,
  ...props
}) => {
  const { RangePicker } = DatePicker;

  const onChange = (date, dateString) => {
    if (start_field && end_field) {
      setValue({
        [start_field]: dateString?.[0],
        [end_field]: dateString?.[1],
      });
      return;
    }

    const startDate = { [`${field}_after`]: dateString?.[0] };
    const endDate = { [`${field}_before`]: dateString?.[1] };
    const defaultFilterValue = { [field]: DATE };
    setValue({ ...startDate, ...endDate, ...defaultFilterValue });
  };
  return <RangePicker className="smallPicker" onChange={onChange} {...props} />;
};
