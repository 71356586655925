import {
  addAction,
  createColumn,
  currencyFormat,
  formatDateTime,
  getForm,
  getUser,
  returnObject,
} from "../../utils/helperUtils";
import {
  DATE,
  DISBURSEMENT,
  REPAYMENT,
  TRANSACTION,
  TRANSACTIONS,
} from "../../constants/constants";
import { RefLink, StatusTag } from "../../components/elements/Elements";
import { ExportExcelOption } from "../../constants/options";

export const useTransactions = ({ id, reloadData, refreshData }) => {
  const tableColumns = () => [
    ...[
      "payment_number",
      {
        id: "type",
        filter: {
          options: [
            {
              label: DISBURSEMENT,
              value: DISBURSEMENT,
            },
            {
              label: REPAYMENT,
              value: REPAYMENT,
            },
          ],
        },
      },
      ...returnObject(!id, [{ id: "ref_number", filter: {} }]),
      "amount",
      { id: "client_name", filter: {} },
      "comment",
      { id: "created_at", filter: { type: DATE } },
      "created_by",
    ].map((column) => createColumn(column)),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const { amount, created_at, created_by, type, ref_number } = item || {};
      return {
        ...item,
        ...getUser(created_by),
        ref_number: <RefLink>{ref_number}</RefLink>,
        created_at: formatDateTime(created_at),
        amount: currencyFormat(amount),
        type: <StatusTag status={type} />,
      };
    }),
  ];

  const transactionRoute = `${getForm(TRANSACTIONS)}?loan=${id}`;

  const tableActions = () => [
    addAction(TRANSACTIONS, transactionRoute),
    ExportExcelOption({ module: TRANSACTION }),
  ];

  const tableButton = {
    // to: transactionRoute,
    modal: true,
    source: TRANSACTIONS,
    state: {
      defaultValues: { loan: id },
      reloadData,
      refreshData,
    },
  };

  const tableParams = {
    disbursements: { type: DISBURSEMENT },
    repayments: { type: REPAYMENT },
  };

  return {
    tableColumns,
    tableData,
    tableActions,
    tableButton,
    tableParams,
    // tableTitle: "Transaction History",
  };
};
