import styled from "styled-components";
import { FieldWrapper, SectionTitle } from "../elements/Elements";
import { ReaderOutline } from "@raresail/react-ionicons";

export const FormSection = ({ title, children, ...rest }) => {
  return (
    <Wrapper className="whiteBg radius10">
      <SectionTitle title={title} icon={ReaderOutline} />
      {/* FIELDS */}
      <FieldWrapper {...rest}>{children}</FieldWrapper>
      {/* <FormWrapper {...rest}>{children}</FormWrapper> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (max-width: 960px) {
  }
`;
