import React, {
  cloneElement,
  createContext,
  useContext,
  useState,
} from "react";

export const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

let updateGlobalContext;

export const GlobalProvider = ({ children }) => {
  const [data, setData] = useState();
  const renderBody = (ele) => cloneElement(ele);

  updateGlobalContext = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
  };

  const contextValue = { data };

  return (
    <GlobalContext.Provider value={contextValue}>
      {renderBody(children)}
    </GlobalContext.Provider>
  );
};

export { updateGlobalContext };
