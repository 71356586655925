import React, { useEffect, useState } from "react";
import { registerHooks } from "../../hooks/handlers/useRegisterHandler";
import { ListView } from "../common/views/ListView";
import useGet from "../../hooks/common/useGet";
import { sourceApis } from "../../hooks/handlers/useApiHandler";
import { ALL, LIST_VIEW } from "../../constants/constants";
import { TableView } from "../common/tables/TableView";

export const RegisterHandler = ({
  source,
  status,
  type,
  id,
  params,
  state, // passing any custom data to register handler
  reloadData,
}) => {
  // refresh trigger
  const [refresh, setRefresh] = useState(false);
  const [current, setCurrent] = useState();

  // function
  const refreshData = () => setRefresh((prev) => !prev);

  function setPage(page) {
    setCurrent(page);
    setFilter((prev) => ({ ...prev, page }));
  }

  const registerHook = registerHooks?.[source];

  const {
    tableColumns,
    tableData,
    tableTitle,
    tableButton,
    tableActions,
    tableParams,
    listData,
    listFields,
    listTitle,
    listParams,
    listActions,
    totalRow,
    tableDescription,
    tableOptions,
  } = registerHook
    ? registerHook({ id, state, params, reloadData, refreshData })
    : {};

  function getParams(status) {
    // list params
    if (listParams && listParams[status]) return { ...listParams[status] };
    // external params
    if (params) return { ...params };
    // table params
    if (tableParams) return { ...(tableParams[status] || tableParams) };

    // default
    if (status !== ALL) return { status };
  }

  // set filters
  const [filters, setFilter] = useState(getParams(status));

  // load hooks data
  const {
    loadData: getRegisterData,
    data: registerData = [],
    loading,
  } = useGet();

  // get register data from api
  useEffect(() => {
    if (source && sourceApis[source]) {
      getRegisterData({
        id, // get list of records from with id
        api: sourceApis[source],
        params: filters,
      });
    }
  }, [filters, source, status, refresh]);

  return (
    <div className="h100" key={source}>
      {/* LISTVIEW */}
      {type === LIST_VIEW ? (
        <ListView
          loading={loading}
          title={listTitle}
          fields={listFields()}
          setFilter={setFilter}
          count={registerData?.count}
          actions={listActions}
          rows={listData(registerData?.results || [])}
          {...{ source, status, setPage, current }}
        />
      ) : (
        <TableView
          title={tableTitle || source}
          loading={loading}
          columns={tableColumns ? tableColumns() : []}
          tableButton={tableButton}
          rows={tableData ? tableData(registerData?.results || []) : []}
          actions={tableActions && tableActions({ data: registerData })}
          count={registerData?.count}
          setFilter={setFilter}
          filters={filters}
          totalRow={totalRow && totalRow({ registerData } || [])}
          {...{ setPage, current }}
          description={tableDescription}
          tableOptions={tableOptions}
        />
      )}
    </div>
  );
};
