import {
  API_APPLICATIONS,
  API_CATEGORIES,
  API_CHARGES,
  API_COMMENTS,
  API_INSTALLMENTS,
  API_LOANS,
  API_TRANSACTIONS_LOAN,
  API_ROLES,
  API_USERS,
  API_TRANSACTIONS,
  API_CHARGE_PENALTIES,
  API_AUDIT_TRAIL,
  API_TASKS,
  API_BRANCHES,
} from "../../constants/apis";

export const sourceApis = {
  users: API_USERS,
  loans: API_LOANS,
  transactions: API_TRANSACTIONS,
  loan_transactions: API_TRANSACTIONS_LOAN,
  applications: API_APPLICATIONS,
  installments: API_INSTALLMENTS,
  categories: API_CATEGORIES,
  roles: API_ROLES,
  repayments: API_INSTALLMENTS,
  comments: API_COMMENTS,
  charges: API_CHARGES,
  charge_penalties: API_CHARGE_PENALTIES,
  audit_activity: API_AUDIT_TRAIL,
  tasks: API_TASKS,
  branches: API_BRANCHES,
};

export const submitApis = {
  loans: API_LOANS,
  applications: API_APPLICATIONS,
};
