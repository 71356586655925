import { useState } from "react";

export const useFilter = (options) => {
  const { keys } = options || {};
  const [filter, setFilter] = useState(keys);

  const addFilter = (filter) => {
    setFilter((prev) => ({ ...prev, ...filter }));
  };
  return { filter, setFilter, addFilter };
};
