import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useForm from "../../hooks/common/useForm";
import useSet from "../../hooks/common/useSet";
import { RenderForm } from "../../utils/formUtils";
import { modalFormHooks } from "../../hooks/handlers/useFormHandler";
import { cleanPayload, makeSingular, stringify } from "../../utils/helperUtils";
import { Button } from "../common/GenericButton";
import { useToasts } from "../../hooks/common/useToasts";
import { ADD, EDIT, SUBMIT, UPDATE } from "../../constants/constants";

export const ModalForm = ({
  show,
  dismiss,
  button,
  state,
  source,
  id,
  title,
}) => {
  const [open, setOpen] = useState(false);
  const [mount, setMount] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (dismiss) return dismiss();
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* TRIGGER BUTTON */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          showModal();
        }}
      >
        {button}
      </div>

      <Dialog
        open={show || open}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        sx={{ zIndex: 1000 }}
        keepMounted={mount}
      >
        {/* CHILDREN */}
        <ModalFormHandler
          source={source}
          id={id}
          handleClose={handleClose}
          setMount={setMount}
          state={state}
          title={title}
        />
      </Dialog>
    </React.Fragment>
  );
};

const ModalFormHandler = ({
  source,
  id,
  handleClose,
  setMount,
  state,
  title,
}) => {
  const { defaultValues, refreshData, reloadData, submitText } = state || {};
  const { form, updateFormValues, onChange, validateForm } =
    useForm(defaultValues);
  const { uploadData, error, setError, submitting, success } = useSet();

  const formHook = modalFormHooks?.[source];
  const {
    title: modalTitle,
    fields,
    onSubmit: handleSubmit,
  } = formHook({ form, updateFormValues, source, state });

  // update form values
  useEffect(() => {
    if (defaultValues) {
      updateFormValues(defaultValues);
    }
  }, [defaultValues]);

  function onSubmit() {
    if (validateForm(fields, setError)) {
      if (handleSubmit) {
        // cleanup data
        const payload = { ...form };
        cleanPayload(payload);

        let upload_data = handleSubmit({ payload, id });

        // handle submission
        if (upload_data) {
          uploadData(upload_data);
        }
      }
    }
  }

  function onSuccess() {
    handleClose();
    if (reloadData) return reloadData();
    if (refreshData) refreshData();
    setMount(false); // reset modal
  }

  // handle notifications
  useToasts({ success, error, id, redirect: false, callback: onSuccess });

  // Persist modal
  useEffect(() => {
    setMount(true);
  }, []);

  const prefix = id ? EDIT : ADD;

  return (
    <>
      <DialogTitle>
        <p className="capitalize font14 bold mainColor">
          {title || modalTitle
            ? stringify(title || modalTitle)
            : `${prefix} ${makeSingular(stringify(source))}`}
        </p>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: 200 }}>
        <div className="flexColumn gap15">
          <RenderForm
            {...{
              fields,
              form,
              updateFormValues,
              onChange,
              error,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="font12" type="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="font12 capitalize"
          type="primary"
          loading={submitting}
          onClick={onSubmit}
        >
          {submitText ? submitText : id ? UPDATE : SUBMIT}
        </Button>
      </DialogActions>
    </>
  );
};
