import React from "react";
import { message, Upload } from "antd";
import { FieldWrapper } from "../elements/FieldWrapper";
import { ACCEPTED_FILES, MAX_FILE_SIZE } from "../../constants/constants";
import { FileTrayOutline } from "@raresail/react-ionicons";
const { Dragger } = Upload;

export const FileUpload = ({
  label,
  required,
  field,
  updateFormValues,
  value,
  error,
  maxFileSize: maxSize,
  acceptedFiles,
  ...props
}) => {
  function putFiles(fileList) {
    const files = [];
    const maxFileSize = (maxSize || MAX_FILE_SIZE) * 1024 * 1024; // Size limit

    fileList?.forEach((file) => {
      if (file.size > maxFileSize) {
        message.error(
          `File exceeds max upload size ${maxSize || MAX_FILE_SIZE}MB`
        );
        return false;
      }
      files.push(file);
    });

    return files;
  }

  function onChange(event) {
    const fileList = event?.fileList;
    if (fileList) {
      updateFormValues({ [field]: putFiles(fileList) });
    }
  }

  return (
    <div style={{ height: "auto" }}>
      <FieldWrapper {...{ label, field, required, error, ...props }}>
        <Dragger
          onChange={onChange}
          //   maxCount={1}
          beforeUpload={() => false}
          fileList={value}
          accept={acceptedFiles || ACCEPTED_FILES}
        >
          <p className="mainColor">
            <FileTrayOutline style={{ fontSize: 35 }} />
          </p>
          <p className="mainColor mb5">Click or drag file here to upload</p>
          <p className="greyColor font12">
            Max upload size is {maxSize || MAX_FILE_SIZE}MB. Allowed files{" "}
            {acceptedFiles?.toUpperCase() || "JPG, PNG, PDF"}
          </p>
        </Dragger>
      </FieldWrapper>
    </div>
  );
};
