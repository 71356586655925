import { Button as AntButton } from "antd";
import { Link } from "react-router-dom";
import { stringify } from "../../utils/helperUtils";

export const Button = ({ children, onClick, to, icon, loading, ...props }) => {
  const ButtonComonent = () => (
    <AntButton
      className="font13 capitalize"
      type="primary"
      onClick={onClick}
      style={{ boxShadow: "none" }}
      icon={icon}
      loading={loading}
      {...props}
    >
      {stringify(children)}
    </AntButton>
  );
  return (
    <>
      {to ? (
        <Link to={to}>
          <ButtonComonent />
        </Link>
      ) : (
        <ButtonComonent />
      )}
    </>
  );
};
