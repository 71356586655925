import { alpha, CircularProgress } from "@mui/material";
import React from "react";

export const Loader = ({ full }) => {
  return (
    <div
      className="flexGrow flexCenter x100 h100"
      style={{ height: full && "100vh" }}
    >
      <CircularProgress
        size={25}
        color="secondary"
        sx={{ strokeLinecap: "round" }}
      />
    </div>
  );
};

export const OverlayLoader = () => {
  return (
    <div
      className="absolute rounded flexCenter"
      style={{
        inset: 0,
        backgroundColor: alpha("#fff", 0.6),
      }}
    >
      <CircularProgress
        color="secondary"
        sx={{ strokeLinecap: "round" }}
        size={30}
      />
    </div>
  );
};
