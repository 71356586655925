import {
  ACTIVE,
  APPROVED,
  CANCELLED,
  CLOSED,
  OVERDUE,
  PENDING,
  SELECT,
  WRITTEN_OFF,
} from "../../constants/constants";
import { addSelectOption } from "../../utils/helperUtils";
import { useUsersSearch } from "../loans/useLoansForm";

export const useStaffReport = () => {
  const tiles = [
    {
      label: "outstanding_loans",
      value: 10,
    },
    {
      label: "active_loans",
      value: 10,
    },
  ];
  const report = [
    {
      col: 2,
      tiles,
    },
  ];
  return { report };
};
export const useStaffFilters = () => {
  const fields = [
    ...useUsersSearch({
      params: { is_staff: true },
      field: "created_by",
      label: "staff",
    }),
    {
      field: "status",
      label: "loan status",
      type: SELECT,
      options: [
        ...[
          OVERDUE,
          ACTIVE,
          APPROVED,
          PENDING,
          CANCELLED,
          WRITTEN_OFF,
          CLOSED,
        ].map((status) => addSelectOption(status)),
      ],
    },
  ];
  return { fields };
};
