import { Briefcase, Grid, Wallet } from "@raresail/react-ionicons";
import {
  ACTIVE,
  APPLICATIONS,
  CLIENT,
  INACTIVE,
  LOANS,
  OVERVIEW,
  STAFF,
  USERS,
} from "../../constants/constants";
import { RegisterHandler } from "../../components/handlers/RegisterHandler";
import { DetailsView } from "../../components/common/views/DetailsView";
import {
  EditButton,
  GridWrapper,
  LinkItem,
  StatTile,
  StatusTag,
} from "../../components/elements/Elements";
import {
  currencyFormat,
  editAction,
  formatDateTime,
  getForm,
} from "../../utils/helperUtils";
import { ExportExcelOption } from "../../constants/options";

export const useUsers = ({ data }) => {
  const userId = data?.id; // client id
  const isStaff = data?.is_staff; // client id

  const listData = (data) => [
    ...data.map((item) => {
      const { display_name, is_active, email } = item || {};

      const row = {
        ...item,
        title: display_name?.trim() || email,
        tags: [{ status: is_active ? ACTIVE : INACTIVE }],
      };
      return row;
    }),
  ];

  const listFields = () => [
    ...["email", "phone_number", "role_name"].map((id) => {
      return { id };
    }),
  ];

  const listParams = {
    staff: { is_staff: true },
    clients: { is_staff: false },
  };

  function viewData() {
    const {
      first_name,
      last_name,
      email,
      phone_number,
      is_active,
      is_staff,
      role_name,
      date_joined,
      last_login,
      profile,
    } = data || {};

    const { address, profession, is_employed, branch_name } = profile || {};

    return {
      user_details: {
        first_name,
        last_name,
        email: <LinkItem>{email}</LinkItem>,
        phone_number,
        date_joined: formatDateTime(date_joined),
        last_login: formatDateTime(last_login),
        role_name,
        account_type: is_staff ? STAFF : CLIENT,
        account_status: <StatusTag status={is_active ? ACTIVE : INACTIVE} />,
        branch: branch_name,
      },
      user_profile: { address, profession, is_employed, branch: branch_name },
    };
  }

  const tabs = () => [
    {
      icon: <Grid />,
      label: OVERVIEW,
      props: { fullWidth: isStaff },
      children: (
        <>
          {/* DETAILS */}
          <DetailsView
            data={viewData()?.user_details}
            title="user_details"
            rightSide={<EditButton to={getForm(USERS, userId)} />}
            // actions={[editAction(USERS, userId)]}
          />
          {!isStaff && (
            <div className="flexColumn gap15">
              {/* STATS */}
              <GridWrapper>
                {[
                  { label: "Total Balance", value: data?.outstanding_balance },
                  { label: "Total due", value: data?.overdue },
                ]?.map(({ label, value, color }) => (
                  <StatTile color={color} label={label}>
                    {currencyFormat(value)}
                  </StatTile>
                ))}
              </GridWrapper>

              {/* PROFILE */}
              <DetailsView
                data={viewData()?.user_profile}
                title="user_profile"
                actions={[editAction(USERS, userId)]}
              />
            </div>
          )}
        </>
      ),
    },
    // LOANS
    {
      icon: <Wallet />,
      label: LOANS,
      children: <RegisterHandler source={LOANS} params={{ client: userId }} />,
      props: { fullWidth: true },
    },
    // APPLICATIONS
    {
      icon: <Briefcase />,
      label: APPLICATIONS,
      children: (
        <RegisterHandler source={APPLICATIONS} params={{ client: userId }} />
      ),
      props: { fullWidth: true },
    },
  ];

  const title = data?.display_name;

  const titleFields = {
    role: data?.role_name,
    date_joined: formatDateTime(data?.date_joined),
    last_login: formatDateTime(data?.last_login),
  };

  const listActions = [ExportExcelOption({ module: "user" })];

  return {
    listData,
    listFields,
    listParams,
    tabs,
    title,
    titleFields,
    listActions,
  };
};
