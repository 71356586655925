import React from "react";
import { Layout } from "antd";
import { styled } from "styled-components";
import { LeftMenu } from "../menus/LeftMenu";
import { AppBar } from "../appbar/AppBar";

export const DashboardLayout = ({ children }) => {
  return (
    <Layout
      hasSider
      style={{
        height: "100vh",
        width: "100%",
        position: "fixed",
      }}
    >
      <MenuWrapper>
        <LeftMenu />
      </MenuWrapper>
      <div className="flex x100">
        <div className="x100 flexColumn" style={{ flexGrow: 1 }}>
          <AppBar />
          <div style={{ flexGrow: 1, height: 0, padding: "0 15px 15px 15px" }}>
            {children}
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </Layout>
  );
};

const MenuWrapper = styled.aside`
  display: contents;
  @media (max-width: 860px) {
    display: none;
  }
`;
