import { Menu } from "antd";
import { Link } from "react-router-dom";
import { stringify } from "../../utils/helperUtils";

const { SubMenu, Item } = Menu;

export function RecursiveMenu(data) {
  return data.map(
    ({ key, label, icon, to, onClick, children = [], visible = true }) => {
      if (!visible) return;
      label = stringify(label);
      if (!children?.length) {
        return (
          <Item key={key || Math.random()} icon={icon} style={{ fontSize: 13 }}>
            {to ? (
              <Link to={to} onClick={onClick} className="capitalize">
                {label}
              </Link>
            ) : onClick ? (
              <div className="capitalize" onClick={onClick}>
                {label}
              </div>
            ) : (
              <div className="capitalize">{label}</div>
            )}
            {/* onClick */}
          </Item>
        );
      }

      return (
        <SubMenu
          className="capitalize"
          style={{ paddingLeft: 0, fontSize: 13 }}
          key={key || Math.random()}
          title={label}
          icon={icon}
        >
          {RecursiveMenu(children)}
        </SubMenu>
      );
    }
  );
}
