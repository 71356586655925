import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "antd";

export const ModalDialog = ({
  button,
  title,
  children,
  loading,
  onSubmit,
  setOpen,
  open,
}) => {
  //   const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* TRIGGER BUTTON */}
      <div onClick={showModal}>{button}</div>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        sx={{ zIndex: 20 }}
      >
        <DialogTitle>
          <p className="capitalize font14 bold mainColor">{title}</p>
        </DialogTitle>
        <DialogContent dividers={true} sx={{ minHeight: 200 }}>
          {children}
        </DialogContent>
        <DialogActions>
          <Button className="font12" type="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="font12"
            type="primary"
            loading={loading}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
