import {
  CallOutline,
  MailOutline,
  PersonOutline,
} from "@raresail/react-ionicons";
import { COMPONENT, EMAIL_VERIFICATION, TEXT } from "../../constants/constants";
import { URL_LOGIN } from "../../constants/urls";
import { Link, useParams } from "react-router-dom";
import { Heading } from "../../components/pages/home/AuthPage";
import { API_CREATE_USER } from "../../constants/apis";
import { returnObject } from "../../utils/helperUtils";

export const useRegisterForm = ({ id, form }) => {
  const { source } = useParams();
  const isUpdate = id;

  const fields = [
    ...returnObject(!source && !isUpdate, [
      {
        type: COMPONENT,
        component: <Heading>Create Account</Heading>,
      },
      {
        type: COMPONENT,
        component: (
          <p className="font13">Fill in the details and get started today.</p>
        ),
      },
    ]),

    {
      label: "Email address",
      field: "email",
      type: TEXT,
      required: true,
      props: {
        prefix: <MailOutline />,
        helperText: form?.email && EMAIL_VERIFICATION && !isUpdate,
        disabled: isUpdate,
      },
    },
    {
      field: "first_name",
      type: TEXT,
      required: true,
      props: {
        prefix: <PersonOutline />,
      },
    },
    {
      field: "last_name",
      type: TEXT,
      required: true,
      props: {
        prefix: <PersonOutline />,
      },
    },
    {
      field: "phone_number",
      type: TEXT,
      required: true,
      props: {
        prefix: <CallOutline />,
      },
    },
  ];

  const suffixFields = [
    {
      type: COMPONENT,
      component: (
        <div className="flexCenter gap10 font14">
          <p className="textCenter">Already have account?</p>
          <Link to={URL_LOGIN} className="secondaryColor semiBold">
            Sign In
          </Link>
        </div>
      ),
    },
  ];

  const successMsg =
    "Account created successfully. Check your email for your login credentials";

  function onSubmit({ payload }) {
    return { api: API_CREATE_USER, params: payload };
  }

  return {
    fields,
    suffixFields,
    submitText: "Create Account",
    onSubmit,
    successMsg,
    redirect: URL_LOGIN,
  };
};
