import { addSelectOption, returnObject } from "../../utils/helperUtils";
import {
  APPLICATION,
  DATE,
  LOAN,
  MULTIPLE,
  SELECT,
  TASKS,
  TEXT,
} from "../../constants/constants";
import { useUsersSearch } from "../loans/useLoansForm";
import { API_APPLICATIONS, API_LOANS, API_TASKS } from "../../constants/apis";
import { useParams } from "react-router-dom";
import { useSearchField } from "../common/useSelectSearch";

export const useTasksForm = ({ id, form }) => {
  const { source } = useParams();
  // created from tasks register
  const isIndependent = source === TASKS;

  const fields = [
    {
      field: "title",
      type: TEXT,
      required: true,
    },
    {
      field: "description",
      props: { rows: 2 },
      type: TEXT,
    },
    {
      field: "due_date",
      type: DATE,
    },
    {
      field: "content_type",
      label: "task type",
      type: SELECT,
      options: [addSelectOption(LOAN), addSelectOption(APPLICATION)],
      required: true,
      visible: isIndependent,
    },
    // LOANS
    ...returnObject(
      form.content_type === LOAN && isIndependent,
      useSearchField({
        id: form?.object_id,
        api: API_LOANS,
        field: "object_id",
        label: "loan",
        tagOptions: { label: "ref_number", tag: "client_name" },
        visible: form.content_type === LOAN && isIndependent,
      })
    ),
    // APPLICATIONS
    ...returnObject(
      form.content_type === APPLICATION && isIndependent,
      useSearchField({
        id: form?.object_id,
        api: API_APPLICATIONS,
        field: "object_id",
        label: "application",
        tagOptions: { label: "ref_number", tag: "client" },
        visible: form.content_type === APPLICATION && isIndependent,
      })
    ),
    // USERS
    ...useUsersSearch({
      id,
      params: { is_staff: true },
      field: "assignees_ids",
      label: "assignees",
      props: {
        mode: MULTIPLE,
        helperText: "Assignees added will be notified by email.",
      },
    }),
  ];

  function onSubmit({ payload, id }) {
    return {
      id,
      api: API_TASKS,
      params: {
        ...payload,
      },
    };
  }

  return { fields, onSubmit };
};
