import { createColumn, formatDateTime, getUser } from "../../utils/helperUtils";
import { COMMENTS } from "../../constants/constants";

export const useComments = ({ state, id, refreshData }) => {
  const { type } = state || {};
  const tableColumns = () => [
    ...["comment", "created_by", "created_at"].map((column) =>
      createColumn(column)
    ),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const { created_by, created_at } = item || {};
      return {
        ...item,
        ...getUser(created_by),
        created_at: formatDateTime(created_at),
      };
    }),
  ];

  // const formRoute = `${getForm(COMMENTS)}?object_id=${id}&type=${type}`;
  // const tableActions = () => [addAction(COMMENTS, formRoute)];

  const tableParams = {
    type,
  };

  const tableButton = {
    modal: true,
    source: COMMENTS,
    state: {
      defaultValues: {
        content_type: type,
        object_id: id,
      },
      refreshData,
    },
  };

  return {
    tableColumns,
    tableData,
    tableButton,
    tableParams,
  };
};
