import { Pagination } from "antd";
import React from "react";

export const TablePagination = ({ count, setPage, current, ...props }) => {
  return (
    <div className="flexNullCenter">
      <Pagination
        total={count}
        current={current}
        size="small"
        showSizeChanger={false}
        onChange={setPage}
        {...props}
      />
    </div>
  );
};
