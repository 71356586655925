import { useApplications } from "../applications/useApplications";
import { useAuditTrail } from "../audit-trail/useAuditTrail";
import { useBranches } from "../branches/useBranches";
import { useCategories } from "../categories/useCategories";
import { useChargePenalties } from "../charges/useChargePenalties";
import { useCharges } from "../charges/useCharges";
import { useComments } from "../comments/useComments";
import { useInstallments } from "../installments/useInstallments";
import { useLoans } from "../loans/useLoans";
import { useTransactions } from "../payments/useTransactions";
import { useRoles } from "../roles/useRoles";
import { useTasks } from "../tasks/useTasks";
import { useUsers } from "../users/useUsers";

export const registerHooks = {
  loans: useLoans,
  applications: useApplications,
  users: useUsers,
  installments: useInstallments,
  transactions: useTransactions,
  loan_transactions: useTransactions,
  categories: useCategories,
  roles: useRoles,
  comments: useComments,
  charges: useCharges,
  charge_penalties: useChargePenalties,
  audit_activity: useAuditTrail,
  tasks: useTasks,
  branches: useBranches,
};
