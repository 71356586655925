import { Input } from "antd";
import { FieldWrapper } from "../../elements/FieldWrapper";
import { NumberInput } from "./NumberInput";
const { TextArea } = Input;

export const GenericInput = ({
  placeholder,
  label,
  rows,
  suffix,
  prefix,
  helperText,
  type,
  required = false,
  onChange: handleChange, // handles form input change events
  updateChanges,
  value,
  numberValue,
  name,
  error,
  ...props
}) => {
  const onChange = (event) => {
    handleChange(event);
    const { name, value } = event.target;
    const values = { [name]: value };
    if (updateChanges) updateChanges(values);
  };

  const inputOptions = {
    name: name,
    onChange,
    placeholder: placeholder || label,
    addonAfter: suffix,
    value: value,
    addonBefore: prefix,
    type: type,
    required: required,
  };

  return (
    <FieldWrapper
      field={name}
      {...{ label, helperText, error, required }}
      {...props}
    >
      {rows ? (
        <TextArea {...inputOptions} rows={rows || 2} />
      ) : type === "password" ? (
        <Input.Password {...inputOptions} {...props} />
      ) : type === "number" ? (
        <NumberInput
          addonBefore={prefix}
          min={0}
          {...{
            onChange,
            name,
            value,
            placeholder: placeholder || label,
          }}
          {...props}
          value={numberValue || value}
        />
      ) : (
        <Input {...inputOptions} {...props} />
      )}
    </FieldWrapper>
  );
};
