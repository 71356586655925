import React, { useEffect } from "react";
import useSet from "../../../hooks/common/useSet";
import useForm from "../../../hooks/common/useForm";
import { Button } from "../../common/GenericButton";
import { RenderForm } from "../../../utils/formUtils";
import { authFormHooks } from "../../../hooks/handlers/useFormHandler";
import { useNavigate } from "react-router-dom";
import { Alert, message } from "antd";

export const AuthFormHandler = ({ source }) => {
  const navigate = useNavigate();
  const { form, updateFormValues, onChange } = useForm();
  const { uploadData, submitting, error, success, data } = useSet();
  const {
    fields,
    submitText,
    suffixFields,
    onSubmit: handleSubmit,
    redirect,
    callback,
    successMsg,
    enableSubmit = true,
  } = authFormHooks[source]({ form }) || {};

  // console.log(submitting);

  const onSubmit = (e) => {
    e.preventDefault();
    if (handleSubmit) {
      const upload_data = handleSubmit({ payload: form });
      if (upload_data) uploadData(upload_data);
    }
  };

  function handleError() {
    if (typeof error === "object") {
      return "An error occured";
    }
    return error;
  }

  useEffect(() => {
    if (success) {
      let msg = "Successfully logged in";
      message.success(successMsg || msg);
      if (callback) callback(data);
      if (redirect) navigate(redirect);
    } else if (error) {
      message.error(handleError());
    }
  }, [success, error]);

  // console.log(error);

  return (
    <form onSubmit={onSubmit}>
      <div className="whiteBg radius10 flexColumn xCenter gap20">
        {error && (
          <Alert
            type="error"
            showIcon
            closable
            message={<p className="font12">{handleError()}</p>}
          />
        )}
        {/* RENDER FORM */}
        {RenderForm({ form, fields, onChange, updateFormValues, error })}
        <Button
          type="primary"
          loading={submitting}
          htmlType="submit"
          disabled={!enableSubmit}
        >
          {submitText || "Submit"}
        </Button>
        {RenderForm({ fields: suffixFields })}
      </div>
    </form>
  );
};
