import { FILE, FORMDATA, TEXT } from "../../constants/constants";
import {
  createAttachments,
  prepareAttachmentsPost,
} from "../../utils/helperUtils";
import { sourceApis } from "../handlers/useApiHandler";

export const useDocumentsForm = ({ state }) => {
  const fields = [
    {
      field: "upload_file",
      type: FILE,
      required: true,
    },
    {
      field: "caption",
      type: TEXT,
      required: true,
    },
  ];

  function onSubmit({ payload, id }) {
    const files = createAttachments(payload.upload_file, payload?.caption);
    const attachments = prepareAttachmentsPost(files, "attachments");

    const api = sourceApis[state?.source];
    if (api)
      return {
        id,
        api,
        params: { ...attachments },
        type: FORMDATA,
      };
  }
  return {
    fields,
    onSubmit,
  };
};
