import React, { useState } from "react";
import { AuditTrailView } from "../pages/audit-trail/AuditTrailView";
import { DrawerView } from "../common/views/DrawerView";
import { Tooltip } from "antd";
import { IconButton } from "@mui/material";
import {
  ChatbubbleEllipsesOutline,
  FolderOpenOutline,
} from "@raresail/react-ionicons";
import { DocumentsList } from "../documents/DocumentsList";
import { TimelineSVG } from "../../assets/svg/Svgs";
import { COMMENTS } from "../../constants/constants";
import { CommentsList } from "../comments/CommentsList";

export const WorkAreaActions = ({ source, id, data, reload }) => {
  const [extra, setExtra] = useState();

  const updateExtra = ({ name, children }) => {
    setExtra((prev) => ({ ...prev, [name]: children }));
  };

  const actions = [
    {
      title: "Files",
      icon: FolderOpenOutline,
      iconProps: { fontSize: 22 },
      children: (
        <DocumentsList
          attachments={data?.attachments}
          title="attachments"
          reload={reload}
          id={id}
        />
      ),
      props: { bodyStyle: { padding: 0 } },
    },
    {
      title: COMMENTS,
      icon: ChatbubbleEllipsesOutline,
      iconProps: { fontSize: 22 },
      children: <CommentsList updateExtra={updateExtra} />,
      // destroy: true,
    },
    {
      title: "Activity Log",
      icon: TimelineSVG,
      iconProps: { fontSize: 18 },
      children: <AuditTrailView source={source} id={id} />,
      destroy: true,
    },
  ];

  return (
    <div className="flexCenter flexColumn gap10">
      {actions.map(
        ({ title, icon: Icon, iconProps, children, destroy, props }, i) => {
          return (
            <DrawerView
              key={i}
              title={title}
              destroy={destroy}
              extra={extra}
              button={
                <Tooltip
                  title={title}
                  overlayClassName="capitalize"
                  placement="left"
                  overlayStyle={{ fontSize: 12 }}
                >
                  <IconButton>
                    <Icon {...iconProps} />
                  </IconButton>
                </Tooltip>
              }
              {...props}
            >
              {children}
            </DrawerView>
          );
        }
      )}
    </div>
  );
};
