import { Stack } from "@mui/material";
import React from "react";
import { ErrorText, FormLabel, HelperText } from "./Elements";

export const FieldWrapper = ({
  children,
  label,
  helperText,
  error,
  field,
  required,
  ...props
}) => {
  return (
    <Stack spacing={1} width="100%">
      {!props?.noLabel && (
        <FormLabel required={required} {...props}>
          {label}
        </FormLabel>
      )}
      {children}
      <HelperText text={helperText} />
      <ErrorText>{error?.[field]}</ErrorText>
    </Stack>
  );
};
