import { useEffect, useState } from "react";
import { SELECT } from "../../constants/constants";
import { addTagOption } from "../../utils/helperUtils";
import useGet from "./useGet";

export const useSelectSearch = () => {
  const [searchValue, setSearchValue] = useState();

  function onSearch(value) {
    setSearchValue(value);
  }
  return { onSearch, searchValue };
};

export function useSearchField({
  id,
  option_keys,
  params,
  field,
  props,
  label,
  tagOptions,
  api,
  visible = true,
}) {
  const { loadData, data, loading } = useGet();

  const { searchValue, onSearch } = useSelectSearch();

  useEffect(() => {
    if (!id && visible)
      loadData({
        api,
        params: { search: searchValue, ...params },
      });
  }, [searchValue, id, visible]);

  const fields = [
    {
      field,
      label,
      type: SELECT,
      required: true,
      option_keys,
      props: {
        onSearch,
        loading,
        placeholder: `search ${label || field}`,
        ...props,
      },
      options: data?.results?.map((val) => addTagOption(val, tagOptions)),
    },
  ];
  return fields;
}
