import { DocumentTextOutline } from "@raresail/react-ionicons";
import {
  addSelectOption,
  DownloadExcel,
  DownloadFile,
} from "../utils/helperUtils";
import {
  DAILY,
  EXPORT_EXCEL,
  FIXED_INTEREST,
  INTEREST_ONLY,
  MONTHLY,
  NO,
  TRANSACTION,
  YES,
} from "./constants";
import { useState } from "react";

export const payment_frequency = [
  addSelectOption(DAILY),
  addSelectOption(MONTHLY),
];

export const repayment_types = [
  addSelectOption(FIXED_INTEREST),
  addSelectOption(INTEREST_ONLY),
];

export const yesNoOptions = [
  addSelectOption(true, YES),
  addSelectOption("false", NO),
];

export const charges_types = ["charge", "penalty", "other"].map((option) =>
  addSelectOption(option)
);

export const document_fields = [
  "passport_photos",
  "national_id",
  "supporting_documents",
  "bank_statement",
  "sales_report",
  "collateral_security",
  "lc1_letter",
  "gurantor_id",
  "trading_licence",
];

export const auditActions = [
  "create",
  "update",
  TRANSACTION,
  "schedule",
  "repayment",
  "reminder",
  "comment",
];

export const ExportExcelOption = ({ module }) => {
  const [loading, setLoading] = useState(false);
  return {
    label: EXPORT_EXCEL,
    icon: DocumentTextOutline,
    onClick: () => {
      DownloadExcel({ module, setLoading });
    },
    loading,
  };
};

export const DownloadFileOption = ({ label, api, id, params }) => {
  const [loading, setLoading] = useState(false);
  return {
    label,
    icon: DocumentTextOutline,
    onClick: () => {
      DownloadFile({ api, params, id, setLoading });
    },
    loading,
  };
};
