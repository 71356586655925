import { ModalForm } from "../components/forms/ModalForm";
import {
  updateModalContext,
  useModalContext,
} from "../components/context/ModalContext";
import { cloneElement, useEffect, useState } from "react";
import { DetailsModal } from "../components/common/modals/DetailsModal";
import { GenericModal } from "../components/common/modals/GenericModal";

export const openModalForm = (options) => {
  return updateModalContext({
    type: "form",
    key: options?.source,
    ...options,
  });
};

export const openModalDetails = (options) => {
  return updateModalContext({
    type: "details",
    key: options?.source + "-" + options?.id,
    ...options,
  });
};

export const openModal = (options) => {
  return updateModalContext({ type: "modal", ...options });
};

const modalComponentHandler = {
  form: ModalForm,
  details: DetailsModal,
  modal: GenericModal,
};

export const ModalController = () => {
  const [show, setShow] = useState(false);
  const modalState = useModalContext();
  const { type } = modalState || {};
  // get modal component
  const ModalComponent = modalComponentHandler[type];

  function dismiss() {
    setShow(false);
  }

  useEffect(() => {
    setShow(true);
  }, [modalState]);

  const props = { show, dismiss, ...modalState };

  if (!modalState) return null;

  const renderBody = () => cloneElement(<ModalComponent />, props);

  return renderBody();
};
