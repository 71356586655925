import { Checkbox } from "antd";
import styled from "styled-components";
import { useEffect } from "react";
import { Loader } from "../../common/Loader";
import { API_PERMISSIONS } from "../../../constants/apis";
import useGet from "../../../hooks/common/useGet";

export const PermissionsSection = ({
  role_permissions = [],
  updateFormValues,
}) => {
  const { loadData, data: permissions, loading } = useGet();

  //  get permissions list
  useEffect(() => {
    loadData({ api: API_PERMISSIONS });
  }, []);

  const onChange = (e) => {
    const checked = e.target.checked;
    // contains codename and name
    const permission = e.target.value;

    if (checked) {
      updateFormValues({ permissions: [...role_permissions, permission] });
    } else {
      // filter out the unchecked permission
      const newSelected = role_permissions?.filter(
        (obj) => obj?.codename !== permission?.codename
      );
      updateFormValues({ permissions: newSelected });
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flexColumn gap20">
      {permissions?.map(({ app_name, permissions }, i) => (
        <div
          className="p15 rounded lightBg"
          // style={{ border: "1px solid #ddd" }}
        >
          <p className="bold capitalize mb20">
            {app_name?.replace("fs_", "").replace("app_", "")}
          </p>
          <PermissionsWrapper>
            {permissions?.map((permission, i) => (
              <Checkbox
                key={i}
                onChange={onChange}
                value={permission}
                defaultChecked={role_permissions?.find(
                  (obj) => obj.codename === permission?.codename
                )}
                className="capitalize font13 flexNullCenter"
              >
                {permission?.name}
              </Checkbox>
            ))}
          </PermissionsWrapper>
        </div>
      ))}
    </div>
  );
};

const PermissionsWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;
