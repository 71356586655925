import React from "react";
import styled from "styled-components";
import {
  addAction,
  getRegisterTitle,
  getWorkArea,
  stringify,
} from "../../../utils/helperUtils";
import { Input } from "antd";
import { Search } from "@raresail/react-ionicons";
import { TablePagination } from "../tables/TablePagination";
import { EmptyView, RenderTags, SectionTitle } from "../../elements/Elements";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../Loader";
import { NO_TITLE, SEARCH_PLACEHOLDER } from "../../../constants/constants";

export const ListView = ({
  title,
  fields,
  rows,
  count,
  setFilter,
  status,
  source,
  loading,
  setPage,
  current,
  actions = [],
}) => {
  const { id } = useParams();

  function updateSearchFilter(search) {
    setFilter((prev) => ({ ...prev, search }));
  }

  function onChange(event) {
    const value = event.target.value;
    updateSearchFilter(value);
  }

  function getPath(rowId) {
    if (id !== rowId) {
      return `${getWorkArea(source, status)}/${rowId}/overview`;
    }
  }

  return (
    <div className="whiteBg rounded h100 flexColumn">
      <HeaderWrapper>
        {/* TITLE */}
        <SectionTitle
          title={title || getRegisterTitle(source, status)}
          actions={[addAction(source), ...actions]}
        />

        {/* SEARCH */}
        <div className="p10">
          <Input
            placeholder={SEARCH_PLACEHOLDER}
            className="lightBg rounded"
            onChange={onChange}
            allowClear
            prefix={<Search color="#aaa" />}
          />
        </div>
      </HeaderWrapper>

      {loading ? (
        <Loader />
      ) : count > 0 ? (
        <>
          {/* ROWS */}
          <RowSection
            className="flexGrow scrollContainer"
            style={{ height: 0 }}
          >
            {rows?.map((row, i) => {
              const { id: rowId, title, tags } = row || {};

              return (
                <Link to={getPath(String(rowId))} key={i}>
                  <RowWrapper
                    className={`p15 flexColumn gap5 animate pointer ${
                      id === String(rowId) && "lightBg list-item-selected"
                    }`}
                  >
                    {/* TITLE */}
                    <div className="flexNullCenter flexSpaceCenter gap10">
                      <div className="bold mainColor ellipsis capitalize">
                        {title || NO_TITLE}
                      </div>
                      {/* STATUS TAGS */}
                      <RenderTags tags={tags} />
                    </div>
                    {/* FIELDS */}
                    <div
                      className="flexWrap"
                      style={{ columnGap: 10, rowGap: 2 }}
                    >
                      {fields?.map((field, i) => {
                        const { id } = field || {};
                        return (
                          <FieldsWrapper className="font11 greyColor" key={i}>
                            <div className="flexNullCenter gap5">
                              <p className="capitalize semiBold">
                                {stringify(id)}:
                              </p>
                              <div>{row?.[id]}</div>
                            </div>
                          </FieldsWrapper>
                        );
                      })}
                    </div>
                  </RowWrapper>
                </Link>
              );
            })}
          </RowSection>

          {/* FOOTER */}
          <FooterWrapper>
            <TablePagination
              count={count}
              setPage={setPage}
              current={current}
            />
          </FooterWrapper>
        </>
      ) : (
        <EmptyView />
      )}
    </div>
  );
};

const RowWrapper = styled.div`
  border-bottom: 1px solid #eee;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const RowSection = styled.div``;

const FieldsWrapper = styled.div`
  line-height: 1.5;
`;

const FooterWrapper = styled.div`
  border-top: 1px solid #eee;
  padding: 10px;
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid #eee;
`;
