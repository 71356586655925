import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { RecursiveMenu } from "./RecursiveMenu";
import {
  BarChartOutline,
  BriefcaseOutline,
  CalendarOutline,
  CardOutline,
  CloudUploadSharp,
  DocumentText,
  GridOutline,
  HomeOutline,
  List,
  PeopleOutline,
  PersonOutline,
  ServerOutline,
  SettingsOutline,
  WalletOutline,
} from "@raresail/react-ionicons";
import {
  ACCEPTED,
  ACCOUNT,
  ACTIVE,
  ALL,
  APPLICATIONS,
  APPROVED,
  APP_NAME,
  AUDIT_ACTIVITY,
  CANCELLED,
  CAN_ADMIN,
  CAN_PUBLIC,
  CAN_VIEW_APPLICATION,
  CAN_VIEW_LOAN,
  CAN_VIEW_TRANSACTION,
  CAN_VIEW_USER,
  CLIENT,
  CLOSED,
  COMPLETED,
  DISBURSEMENTS,
  DUE_TODAY,
  IMPORT,
  INSTALLMENTS,
  LOANS,
  NEW_USER,
  OVERDUE,
  OVERVIEW,
  PENDING,
  REJECTED,
  REPAYMENTS,
  REPORTS,
  SETTINGS,
  STAFF,
  TASKS,
  TRANSACTIONS,
  USERS,
  WRITTEN_OFF,
} from "../../constants/constants";
import { LogoIcon, NoteSVG, TimelineSVG } from "../../assets/svg/Svgs";
import styled from "styled-components";
import {
  addForm,
  addRegister,
  addWorkArea,
  getForm,
  getRegister,
  stringify,
} from "../../utils/helperUtils";
import { IsPublicUser, usePermissions } from "../../utils/permissionUtils";
import { useAuth } from "../context/AuthContext";
import { useGlobalContext } from "../context/GlobalContext";
import { settingsOptions } from "../../constants/settings";

export const LeftMenu = ({ collapsible = true }) => {
  const { profile } = useAuth();
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const { getPermissions } = usePermissions();
  const { data } = useGlobalContext();
  const { settings } = data || {};

  const isPublicUser = IsPublicUser();

  const clientOptions = [
    {
      label: "Home",
      to: "/",
      icon: <HomeOutline />,
      key: "1",
    },
    // LOAN
    {
      label: LOANS,
      icon: <WalletOutline />,

      children: [
        addForm(APPLICATIONS, "Apply new loan"),
        addRegister(LOANS, null, "My Loans"),
      ],
    },
    // APPLICATION
    {
      label: APPLICATIONS,
      icon: <BriefcaseOutline />,
      children: [
        addForm(APPLICATIONS, "Apply new loan"),
        addRegister(APPLICATIONS, null, `My ${APPLICATIONS}`),
      ],
    },
    // ACCOUNT
    {
      label: `My ${ACCOUNT}`,
      icon: <PersonOutline />,
      to: ACCOUNT,
    },
  ];

  const menuItems = [
    {
      label: "Dashboard",
      to: "/",
      icon: <GridOutline />,
      key: "1",
    },
    // APPLICATIONS
    {
      label: APPLICATIONS,
      icon: <BriefcaseOutline />,
      visible: getPermissions([CAN_ADMIN, CAN_VIEW_APPLICATION]),
      children: [
        addForm(APPLICATIONS),
        ...[ALL, PENDING, ACCEPTED, REJECTED, CANCELLED].map((status) =>
          addWorkArea(APPLICATIONS, status)
        ),
      ],
    },
    // LOANS
    {
      label: LOANS,
      icon: <WalletOutline />,
      visible: getPermissions([CAN_ADMIN, CAN_VIEW_LOAN]),
      children: [
        addForm(LOANS),
        ...[ALL, ACTIVE, PENDING, APPROVED].map((status) =>
          addWorkArea(LOANS, status)
        ),
        {
          label: "More",
          icon: <List />,
          children: [
            ...[DUE_TODAY, OVERDUE, WRITTEN_OFF, CANCELLED, CLOSED].map(
              (status) => addWorkArea(LOANS, status)
            ),
          ],
        },
      ],
    },
    // TRANSACTIONS
    {
      label: TRANSACTIONS,
      to: "/",
      icon: <CardOutline />,
      visible: getPermissions([CAN_ADMIN, CAN_VIEW_TRANSACTION]),
      children: [
        addForm(TRANSACTIONS),
        // addRegister(TRANSACTIONS, null, "All Transactions"),
        ...[ALL, DISBURSEMENTS, REPAYMENTS].map((status) =>
          addRegister(TRANSACTIONS, status)
        ),
      ],
    },
    // INSTALLMENTS
    {
      label: INSTALLMENTS,
      to: "/",
      icon: <CalendarOutline />,
      visible: getPermissions([CAN_ADMIN]),
      children: [
        // addForm(INSTALLMENTS),
        {
          label: "Upcoming",
          to: `${getRegister(INSTALLMENTS)}?period=today`,
          icon: <DocumentText />,
        },
        addRegister(INSTALLMENTS, null, `All ${REPAYMENTS}`),
      ],
    }, // REPORTS
    {
      label: REPORTS,
      icon: <BarChartOutline />,
      visible: getPermissions([CAN_ADMIN]),
      children: [
        {
          label: OVERVIEW,
          to: REPORTS,
          icon: <DocumentText />,
        },
        {
          label: `${STAFF} Report`,
          to: `${REPORTS}/staff`,
          icon: <DocumentText />,
        },
      ],
    },

    // TASKS
    {
      label: TASKS,
      icon: <NoteSVG />,
      visible: !getPermissions([CAN_PUBLIC]),
      children: [
        ...[PENDING, COMPLETED, CLOSED, ALL].map((status) =>
          addRegister(TASKS, status)
        ),
      ],
    },

    // USERS
    {
      label: USERS,
      icon: <PeopleOutline />,
      visible: getPermissions([CAN_ADMIN, CAN_VIEW_USER]),
      children: [
        addForm(USERS, stringify(NEW_USER)),
        addWorkArea(USERS, `${CLIENT}s`),
        addWorkArea(USERS, STAFF),
      ],
    },

    // DATA IMPORTER
    {
      label: "Data Control",
      icon: <ServerOutline />,
      visible: getPermissions([CAN_ADMIN]),
      children: [
        {
          label: "Import Data",
          to: getForm(IMPORT),
          icon: <CloudUploadSharp />,
        },
      ],
    },

    // AUDIT
    {
      label: "Audit Activity",
      to: getRegister(AUDIT_ACTIVITY),
      icon: <TimelineSVG />,
      visible: getPermissions([CAN_ADMIN]),
    },

    // SETTINGS
    {
      label: "Settings",
      to: SETTINGS,
      icon: <SettingsOutline />,
      visible: getPermissions([CAN_ADMIN]),
    },
  ];

  const items = isPublicUser
    ? clientOptions
    : settings
    ? settingsOptions(getPermissions)
    : menuItems;

  return (
    <Sider
      theme="light"
      collapsible={collapsible}
      collapsed={collapsed}
      collapsedWidth={60}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="flexColumn h100">
        <IconWrapper className="flexNullCenter lightBg radius10 p10 animate">
          <img
            src={LogoIcon}
            alt="logo"
            className="animate"
            style={{
              width: 25,
              height: 25,
              margin: collapsed ? "-2px" : "0 10px 0 0",
            }}
          />
          <div>
            {!collapsed && (
              <div>
                {/* ROLE */}
                <p className="bold mainColor">{profile?.role_name}</p>
                <p className="font10" style={{ marginTop: 3 }}>
                  {APP_NAME}
                </p>
              </div>
            )}
          </div>
        </IconWrapper>
        <MenuWrapper className="scroll hideScroll">
          <Menu
            theme="light"
            defaultSelectedKeys={["1"]}
            mode="inline"
            key={settings ? "settings" : "menu"}
          >
            {RecursiveMenu(items)}
          </Menu>
        </MenuWrapper>
      </div>
    </Sider>
  );
};

const MenuWrapper = styled.div``;
const IconWrapper = styled.div`
  margin: 10px;
  font-size: 13px;
  height: 45px;
`;
