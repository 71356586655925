import {
  createColumn,
  editAction,
  formatDate,
  getForm,
} from "../../utils/helperUtils";
import { ROLES } from "../../constants/constants";
import { ActionsPopup } from "../../components/elements/Elements";

export const useRoles = () => {
  const tableColumns = () => [
    ...["name", "created_at", "actions"].map((column) => createColumn(column)),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const { name, created_at, id } = item;
      return {
        name,
        created_at: formatDate(created_at),
        actions: <ActionsPopup actions={[editAction(ROLES, id)]} />,
      };
    }),
  ];

  const tableButton = {
    to: `${getForm(ROLES)}`,
  };

  return { tableColumns, tableData, tableButton };
};
