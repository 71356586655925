import styled from "styled-components";
import { stringify } from "../../../utils/helperUtils";
import { EmptyField, SectionTitle } from "../../elements/Elements";
import { CheckmarkCircleSharp, RemoveCircle } from "@raresail/react-ionicons";
import { NO, YES } from "../../../constants/constants";

export const DetailsView = ({ data, title, actions, rightSide }) => {
  function getValue(value) {
    if (value === true) {
      return (
        <div className="flexNullCenter gap5">
          <CheckmarkCircleSharp fontSize={14} color="green" />
          {YES}
        </div>
      );
    } else if (value === false) {
      return (
        <div className="flexNullCenter gap5">
          <RemoveCircle fontSize={14} color="grey" />
          {NO}
        </div>
      );
    }
    return value;
  }
  return (
    <div className="rounded whiteBg">
      <SectionTitle title={title} actions={actions}>
        {rightSide}
      </SectionTitle>
      <TableWrapper className="animate p10">
        <table className="font12 x100 animate">
          {Object?.keys(data || {})?.map((key, i) => (
            <tr key={i} className="animate">
              <td className="capitalize semiBold">{stringify(key)}</td>
              <td className="capitalize">
                {getValue(data[key]) || <EmptyField />}
              </td>
            </tr>
          ))}
        </table>
      </TableWrapper>
    </div>
  );
};

const TableWrapper = styled.div`
  table {
    border-collapse: collapse;
  }

  td {
    padding: 7px;
    border-right: 1px solid rgba(0, 0, 0, 0.03);
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }
`;
