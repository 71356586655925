import {
  AccessibilityOutline,
  ArrowBack,
  FileTrayFullOutline,
  GitBranchOutline,
  PersonOutline,
  RocketOutline,
} from "@raresail/react-ionicons";
import {
  ACCOUNT,
  AUTOMATIONS,
  BRANCHES,
  CAN_ADMIN,
  CATEGORIES,
  CHARGES,
  ROLES,
  SETTINGS,
} from "./constants";
import { CoinsSVG } from "../assets/svg/Svgs";
import { addForm, addRegister, getRegister } from "../utils/helperUtils";
import { updateGlobalContext } from "../components/context/GlobalContext";
import { usePermissions } from "../utils/permissionUtils";

export const settingsOptions = (getPermissions) => {
  return [
    {
      label: "Back Home",
      icon: <ArrowBack />,
      to: "/",
      onClick: () => {
        updateGlobalContext({ settings: false });
      },
    },
    {
      label: ACCOUNT,
      icon: <PersonOutline />,
      to: SETTINGS,
      key: "1",
    },
    // ROLES
    {
      label: ROLES,
      icon: <AccessibilityOutline />,
      children: [addForm(ROLES), addRegister(ROLES)],
    },
    // LOAN CATEGORIES
    {
      label: CATEGORIES,
      icon: <FileTrayFullOutline />,
      children: [
        addForm(CATEGORIES),
        addRegister(CATEGORIES, null, `Loan ${CATEGORIES}`),
      ],
    },
    // Configurables
    {
      label: CHARGES,
      icon: <CoinsSVG />,
      children: [addForm(CHARGES), addRegister(CHARGES)],
    },
    // BRANCHES
    {
      label: BRANCHES,
      icon: <GitBranchOutline />,
      to: getRegister(BRANCHES),
      visible: getPermissions([CAN_ADMIN]),
    },
    // Automations
    {
      label: AUTOMATIONS,
      icon: <RocketOutline />,
      to: AUTOMATIONS,
    },
  ];
};
