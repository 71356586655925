import {
  APPLICATION_TERMS,
  CHECK_BOX,
  COLUMN,
  COMPONENT,
  FILE,
  FIXED_INTEREST,
  FORMDATA,
  MONTHLY,
  TEXT,
} from "../../constants/constants";
import { API_APPLICATIONS, API_USERS } from "../../constants/apis";
import {
  createAttachments,
  prepareAttachmentsPost,
  returnObject,
  unsetKeys,
} from "../../utils/helperUtils";
import { useLoanFields } from "../loans/useLoansForm";
import { IsPublicUser } from "../../utils/permissionUtils";
import { useAuth } from "../../components/context/AuthContext";
import { useEffect } from "react";
import { useSearchField } from "../common/useSelectSearch";

export const useApplicationsForm = ({ id, form, updateFormValues }) => {
  const isUpdate = id;
  const isPublicUser = IsPublicUser();
  const { fields: loanFields } = useLoanFields({ form });

  const { profile } = useAuth();
  const {
    first_name,
    last_name,
    email,
    phone_number,
    profile: userProfile,
  } = profile || {};
  const { address, branch_name } = userProfile || {};

  useEffect(() => {
    if (isPublicUser) {
      updateFormValues({
        first_name,
        last_name,
        phone_number,
        email,
        address,
        branch: branch_name,
      });
    }
  }, [profile, isPublicUser]);

  // const { loadData: getClient, data: client } = useGet();
  // const { user, profile } = useAuth();
  // const user_id = user?.id;
  // const is_staff = profile?.is_staff;

  // useEffect(() => {
  //   if (!isUpdate) getClient({ api: API_USERS, id: user_id });
  // }, [isUpdate]);

  // set client id
  // useEffect(() => updateFormValues({ client: user_id }), []);

  const client = true;

  const peronalFields = [
    ...returnObject(
      !isPublicUser,
      useSearchField({
        id: id || isPublicUser,
        api: API_USERS,
        field: "client",
        option_keys: [
          "email",
          "first_name",
          "last_name",
          "phone_number",
          "address",
          "branch",
        ],
        tagOptions: { label: "display_name", tag: "email" },
        params: { is_staff: false },
      })
    ),
    {
      label: "Email Address",
      field: "email",
      type: TEXT,
      required: true,
      value: form?.email,
      props: { disabled: client },
    },
    {
      field: "first_name",
      type: TEXT,
      required: true,
      value: form?.first_name,
      props: { disabled: client },
    },
    {
      field: "last_name",
      type: TEXT,
      required: true,
      value: form?.last_name,
      props: { disabled: client },
    },
    {
      field: "phone_number",
      type: TEXT,
      required: true,
      value: form?.phone_number,
      // props: { disabled: client },
    },
    {
      label: "Residential Address",
      field: "address",
      type: TEXT,
      required: true,
      // value: form?.profile?.address,
      props: {
        placeholder: "Where do you stay?",
      },
    },
    {
      field: "branch",
      type: TEXT,
      required: true,
      props: { disabled: true },
    },
  ];

  const categoryDocumentFields =
    form?.document_fields?.map(
      ({ name, is_required, caption, description }) => {
        return {
          type: FILE,
          label: name,
          field: caption,
          required: is_required,
          props: { helperText: description },
        };
      }
    ) || [];

  const uploadFields = [
    ...categoryDocumentFields,
    // {
    //   label: "national ID",
    //   field: "national_id",
    //   type: FILE,
    //   required: true,
    // },
    // {
    //   field: "bank_statement",
    //   type: FILE,
    //   required: true,
    // },
    // {
    //   label: "LC 1 Letter",
    //   field: "lc_letter",
    //   type: FILE,
    //   required: true,
    // },
    {
      field: "supporting_documents",
      type: FILE,
      props: { helperText: "Any other required supporting documents" },
    },
  ];

  const conditionsFields = [
    {
      type: COMPONENT,
      component: (
        <p style={{ lineHeight: 1.5 }} className="font13 greyColor">
          {APPLICATION_TERMS}
        </p>
      ),
    },
    {
      label: "I agree to these Terms & Conditions",
      field: "agreed",
      type: CHECK_BOX,
      required: true,
    },
  ];

  const steps = [
    {
      title: "Personal Information",
      fields: peronalFields,
    },
    {
      title: "Loan Details",
      fields: loanFields,
    },
    {
      title: "Upload Documents",
      fields: uploadFields,
      // style: { gridTemplateColumns: "1fr 1fr 1fr 1fr" },
    },
    {
      title: "Submit",
      fields: conditionsFields,
      layout: COLUMN,
    },
  ];

  // FOR UPDATE FIELDS
  if (isUpdate) {
    steps.splice(0, 1);
    steps.splice(1, 1);
  }

  const enableSubmit = form?.agreed;

  const defaultValues = {
    payment_frequency: MONTHLY,
    repayment_type: FIXED_INTEREST,
  };

  function onSubmit({ payload, id }) {
    // UPDATE
    if (id) {
      const {
        amount,
        category,
        interest_rate,
        loan_term,
        payment_frequency,
        repayment_type,
      } = payload;

      const params = {
        amount,
        category,
        interest_rate,
        loan_term,
        payment_frequency,
        repayment_type,
      };
      return { id, api: API_APPLICATIONS, params };
    }

    // document fields
    const attachmentKeys = form?.document_fields?.map(({ caption }) => caption);

    const files = [
      // create attachments from document fields keys
      ...attachmentKeys.reduce(
        (acc, key) => [...acc, ...createAttachments(payload[key], key)],
        []
      ),
      // ...createAttachments(lc_letter, "lc_letter"),
    ];

    const attachments = prepareAttachmentsPost(files, "attachments");

    let params = {
      ...unsetKeys(payload, [...attachmentKeys, "document_fields"]),
      ...attachments,
    };

    return { id, api: API_APPLICATIONS, params, type: FORMDATA };
  }

  return { steps, enableSubmit, defaultValues, loanFields, onSubmit };
};
