import {
  Calendar,
  Card,
  CloseCircleOutline,
  Grid,
  PersonAddOutline,
} from "@raresail/react-ionicons";
import { DetailsView } from "../../components/common/views/DetailsView";
import { RegisterHandler } from "../../components/handlers/RegisterHandler";
import {
  ACTIVE,
  APPROVE,
  APPROVED,
  CAN_ADMIN,
  CAN_CHANGE_LOAN,
  CAN_CHANGE_LOAN_STATUS,
  CANCEL,
  CANCELLED,
  CHARGE_PENALTIES,
  CHARGES,
  CLOSED,
  DISBURSE,
  DUE_TODAY,
  INSTALLMENTS,
  LOAN,
  LOAN_TRANSACTIONS,
  LOANS,
  MONTHLY,
  OVERDUE,
  OVERVIEW,
  PENDING,
  SCHEDULE,
  TASKS,
  TRANSACTIONS,
  WRITE_OFF,
  WRITTEN_OFF,
} from "../../constants/constants";
import {
  createColumn,
  currencyFormat,
  formatDate,
  formatDateTime,
  getForm,
  getUser,
  interestFormat,
  returnObject,
  stringify,
} from "../../utils/helperUtils";
import {
  EditButton,
  GridWrapper,
  LinkItem,
  ProgressTile,
  RefLink,
  RenderTags,
  StatTile,
} from "../../components/elements/Elements";
import { Tag } from "antd";
import { CoinsSVG, NoteFilledSVG } from "../../assets/svg/Svgs";
import { DownloadFileOption, ExportExcelOption } from "../../constants/options";
import { IsPublicUser, usePermissions } from "../../utils/permissionUtils";
import { openModalForm } from "../../utils/modalUtils";
import { useState } from "react";
import { API_LOAN_STATEMENT } from "../../constants/apis";

export const useLoans = ({ data, reload: reloadData }) => {
  const { getPermissions } = usePermissions();
  const [refreshTasks, setRefreshTasks] = useState(false);

  const tableColumns = () => [
    ...[
      "ref_number",
      "amount",
      "balance",
      "category",
      "next_payment",
      "created_by",
      "created_at",
      "status",
    ].map((column) => createColumn(column)),
  ];

  const tableData = (data) => [
    ...data.map((item) => {
      const {
        ref_number,
        created_at,
        status,
        amount,
        category_name,
        outstanding_balance,
        next_payment_date,
        is_overdue,
        is_due_today,
      } = item || {};

      const row = {
        ...item,
        ref_number: <RefLink>{ref_number}</RefLink>,
        category: category_name,
        status: (
          <RenderTags
            tags={[
              {
                status,
              },
              {
                status: OVERDUE,
                visible: is_overdue,
              },
              {
                status: DUE_TODAY,
                visible: is_due_today,
              },
            ]}
          />
        ),
        amount: currencyFormat(amount),
        balance: currencyFormat(outstanding_balance),
        created_at: formatDate(created_at),
        next_payment: next_payment_date,
      };
      return row;
    }),
  ];

  const listData = (data) => [
    ...data.map((item) => {
      const {
        ref_number,
        created_at,
        category_name,
        client_name,
        amount,
        is_overdue,
        is_due_today,
        status,
      } = item || {};

      const row = {
        ...item,
        title: ref_number,
        category: category_name,
        client: client_name,
        amount: currencyFormat(amount),
        created_at: formatDateTime(created_at),
        tags: [
          {
            status,
          },
          {
            status: OVERDUE,
            visible: is_overdue,
          },
          {
            status: DUE_TODAY,
            visible: is_due_today,
          },
        ],
      };

      return row;
    }),
  ];

  const listFields = () => [
    ...["amount", "category", "client", "created_at"].map((id) => {
      return { id };
    }),
  ];

  // VIEW DATA
  function viewData({ data }) {
    const {
      amount,
      interest_rate,
      category_details,
      loan_term,
      payment_frequency,
      client_details,
      repayment_type,
      ref_number,
      created_by,
      outstanding_balance,
      amount_paid,
      payment_amount,
      interest_amount,
      charges,
      status,
      application_number,
      overdue,
    } = data || {};

    const { email, display_name, phone_number, profile } = client_details || {};

    return {
      // LOAN
      loan_details: {
        ref_number,
        category: category_details?.name,
        principal: currencyFormat(amount),
        interest_rate: interestFormat(interest_rate),
        // interest_amount
        interest_payable: currencyFormat(interest_amount),
        // charges
        charges: currencyFormat(charges),

        ...returnObject(payment_frequency === MONTHLY, {
          loan_term: `${loan_term} Months`,
        }),

        payment_frequency,
        repayment_type: stringify(repayment_type),

        // PAYMENT AMOUNT
        payment_amount: (
          <p className="bold">{currencyFormat(payment_amount)}</p>
        ),
        ...returnObject([ACTIVE, CLOSED].includes(status), {
          // AMOUNT PAID
          amount_paid: (
            <Tag color="green" className="semiBold">
              {currencyFormat(amount_paid)}
            </Tag>
          ),
        }),

        ...returnObject([ACTIVE].includes(status), {
          // OUTSTANDING BALANCE
          outstanding_balance: (
            <Tag color="red" className="semiBold">
              {currencyFormat(outstanding_balance)}
            </Tag>
          ),
          // OVERDUE_AMOUNT
          overdue_amount: currencyFormat(overdue),
        }),

        ...[
          "created_at",
          "approved_date",
          "disbursement_date",
          "end_date",
        ].reduce((acc, key) => {
          return { ...acc, [key]: formatDateTime(data?.[key]) };
        }, {}),
        ...getUser(created_by),
      },
      // CLIENT
      client_details: {
        email: <LinkItem>{email}</LinkItem>,
        client_name: display_name,
        phone_number,
        ...profile,
      },
      // APPLICATION
      application_details: {
        application_number: <RefLink>{application_number}</RefLink>,
      },
    };
  }

  const loanId = data?.id;

  // LOAN DETAILS ACTIONS
  // const loanDetailsActions = [editAction(LOANS, loanId)];

  // TABS FOR VIEW
  const tabs = ({ data }) => [
    // OVERVIEW
    {
      icon: <Grid />,
      label: OVERVIEW,
      children: (
        <>
          <DetailsView
            data={viewData({ data })?.loan_details}
            // actions={loanDetailsActions}
            title="loan_details"
            rightSide={
              getPermissions([CAN_CHANGE_LOAN, CAN_ADMIN]) && (
                <EditButton to={getForm(LOANS, loanId)} />
              )
            }
          />
          <div className="flexColumn gap15">
            {/* PROGRESS */}
            {[ACTIVE, CANCELLED, CLOSED].includes(data?.status) && (
              <ProgressTile data={data} />
            )}
            {/* STATS */}
            {data?.status === ACTIVE && (
              <GridWrapper>
                {[
                  { label: "Balance", value: data?.outstanding_balance },
                  { label: "Total Repayment", value: data?.payment_amount },
                  { label: "Paid", value: data?.amount_paid, color: "" },
                  { label: "Amount due", value: data?.overdue, color: "red" },
                ]?.map(({ label, value, color }) => (
                  <StatTile color={color} label={label}>
                    {currencyFormat(value)}
                  </StatTile>
                ))}
              </GridWrapper>
            )}
            {/* CLIENT */}
            <DetailsView
              data={viewData({ data })?.client_details}
              title="client_details"
            />
            {/* APPLICATION */}
            {data?.application && (
              <DetailsView
                data={viewData({ data })?.application_details}
                title="application_details"
              />
            )}
          </div>
        </>
      ),
    },
    // SCHEDULE
    {
      icon: <Calendar />,
      label: SCHEDULE,
      children: (
        <RegisterHandler
          source={INSTALLMENTS}
          params={{ loan: loanId }}
          state={{ loan_status: data?.status, data }}
          reloadData={reloadData}
        />
      ),
      props: { fullWidth: true },
      // visible: data?.status !== PENDING,
    },
    // TRANSACTIONS
    {
      icon: <Card />,
      label: TRANSACTIONS,
      children: (
        <RegisterHandler
          source={LOAN_TRANSACTIONS}
          id={loanId}
          reloadData={reloadData}
        />
      ),
      props: { fullWidth: true },
      visible: ![PENDING, APPROVED].includes(data?.status),
    },
    // CHARGES
    {
      icon: <CoinsSVG />,
      label: CHARGES,
      children: (
        <RegisterHandler
          source={CHARGE_PENALTIES}
          params={{ loan: loanId }}
          reloadData={reloadData}
        />
      ),
      props: { fullWidth: true },
      visible: data?.status !== PENDING,
    },
    // TASKS
    {
      icon: <NoteFilledSVG />,
      label: TASKS,
      children: (
        <RegisterHandler
          key={refreshTasks}
          source={TASKS}
          params={{ object_id: loanId, type: LOAN }}
        />
      ),
      props: { fullWidth: true },
      visible: !IsPublicUser(),
    },
    // COMMENTS
    // {
    //   icon: <ChatbubbleEllipses />,
    //   label: COMMENTS,
    //   children: (
    //     <RegisterHandler source={COMMENTS} id={loanId} state={{ type: LOAN }} />
    //   ),
    //   props: { fullWidth: true },
    // },

    // DOCUMENTS
    // {
    //   icon: <Receipt />,
    //   label: DOCUMENTS,
    //   children: (
    //     <>
    //       <DocumentsList
    //         attachments={data?.attachments}
    //         title="Loan attachments"
    //       />
    //     </>
    //   ),
    // },
  ];

  // VIEW TITLE FIELDS
  const titleFields = {
    created: formatDateTime(data?.created_at),
    updated: formatDateTime(data?.updated_at),
    category: data?.category_details?.name,
  };

  // TITLE BUTTONS
  const titleButtons = () => {
    if (!getPermissions([CAN_CHANGE_LOAN_STATUS, CAN_ADMIN])) return;
    const status = data?.status;

    function getButtons() {
      let buttons;
      const cancelBtn = { label: CANCEL, value: CANCELLED };
      const approveBtn = { label: APPROVE, value: APPROVED };
      switch (status) {
        case PENDING:
          buttons = [cancelBtn, approveBtn];
          break;
        case APPROVED:
          buttons = [cancelBtn, { label: DISBURSE, icon: Card, value: ACTIVE }];
          break;
        case ACTIVE:
          buttons = [cancelBtn];
          break;
        case CANCELLED:
          buttons = [approveBtn];
          break;
        default:
          break;
      }
      return buttons;
    }

    return getButtons();
  };

  // ASSIGN OPTIONS
  const createTask = () =>
    openModalForm({
      source: TASKS,
      state: {
        defaultValues: {
          object_id: loanId,
          content_type: LOAN,
        },
        refreshData: () => setRefreshTasks(!refreshTasks),
      },
    });

  const titleActions = [
    {
      label: "Assign",
      icon: PersonAddOutline,
      onClick: createTask,
    },
    ...returnObject(data?.status === ACTIVE, [
      {
        label: WRITE_OFF,
        icon: CloseCircleOutline,
        onClick: () =>
          openModalForm({
            id: loanId,
            source: LOANS,
            title: `${WRITE_OFF} ${LOAN}`,
            state: {
              defaultValues: { status: WRITTEN_OFF },
              reloadData,
              submitText: WRITE_OFF,
            },
          }),
      },
    ]),
    ...returnObject(![PENDING].includes(data?.status), [
      DownloadFileOption({
        label: "Loan Statement",
        api: API_LOAN_STATEMENT,
        id: loanId,
      }),
    ]),
  ];

  const tags = [
    {
      status: OVERDUE,
      visible: data?.is_overdue,
    },
    {
      status: DUE_TODAY,
      visible: data?.is_due_today,
    },
  ];

  const listParams = {
    due_today: { is_due_today: true },
  };

  const listActions = [ExportExcelOption({ module: LOAN })];

  return {
    tableData,
    tableColumns,
    listData,
    listFields,
    tabs,
    titleFields,
    titleButtons,
    titleActions,
    tags,
    listParams,
    listActions,
  };
};
