import { GenericForm } from "../components/forms/GenericForm";
import { RegisterView } from "../components/common/views/RegisterView";
import { TestArea } from "../components/common/TestArea";
import { WorkAreaLayout } from "../components/layouts/WorkAreaLayout";
import { AutomationsView } from "../components/pages/automations/AutomationsView";
import { Dashboard } from "../components/pages/dashboard/Dashboard";
import { AuthPage } from "../components/pages/home/AuthPage";
import { ReportsView } from "../components/pages/reports/ReportsView";
import { SettingsView } from "../components/pages/settings/SettingsView";
import { TasksView } from "../components/pages/tasks/TasksView";
import { ACCOUNT, AUTOMATIONS, REPORTS, SETTINGS, TASKS } from "./constants";
import { URL_LOGIN, URL_REGISTER } from "./urls";
import { AccountView } from "../components/pages/account/AccountView";
import { StaffReport } from "../components/pages/reports/StaffReport";

// external use
export const guestRoutes = [
  {
    path: URL_LOGIN,
    component: <AuthPage />,
  },
  {
    path: URL_REGISTER,
    component: <AuthPage />,
  },
  {
    path: "/reset-password/:uid/:token",
    component: <AuthPage />,
  },
  {
    path: "/forgot-password",
    component: <AuthPage />,
  },
];

export const standardRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "test",
    component: <TestArea />,
  },
  // FORMS
  {
    path: "/:source/create",
    component: <GenericForm />,
  },
  {
    path: "/:source/update/:id",
    component: <GenericForm />,
  },
  // REGISTERS
  {
    path: "register/:source",
    component: <RegisterView />,
  },
  {
    path: "register/:source/:status",
    component: <RegisterView />,
  },
  // WORK AREAS
  {
    path: "workarea/:source",
    component: <WorkAreaLayout />,
  },
  {
    path: "workarea/:source/:status",
    component: <WorkAreaLayout />,
  },
  {
    path: "workarea/:source/:status/:id",
    component: <WorkAreaLayout />,
  },
  {
    path: "workarea/:source/:status/:id/:name",
    component: <WorkAreaLayout />,
  },
  // REPORTS
  {
    path: REPORTS,
    component: <ReportsView />,
  },
  {
    path: `${REPORTS}/staff`,
    component: <StaffReport />,
  },
  // TASKS
  {
    path: TASKS,
    component: <TasksView />,
  },
  // AUTOMATIONS
  {
    path: AUTOMATIONS,
    component: <AutomationsView />,
  },
  // SETTINGS
  {
    path: SETTINGS,
    component: <SettingsView />,
  },
  // ACCOUNT
  {
    path: ACCOUNT,
    component: <AccountView />,
  },
  // SCHEDULE
  // {
  //   path: "schedule/:id",
  //   component: <ScheduleForm />,
  // },
  // DATA CONTROL
  // {
  //   path: "import-data",
  //   component: <ImportView />,
  // },
];
