import React from "react";
import { useParams } from "react-router-dom";
import { FormHandler } from "../handlers/FormHandler";

export const GenericForm = () => {
  const { source, id } = useParams();
  return (
    <>
      <FormHandler key={source} source={source} id={id} />
    </>
  );
};
