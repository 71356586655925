import {
  createColumn,
  currencyFormat,
  formatDate,
  getForm,
} from "../../utils/helperUtils";
import { ACTIVE, CATEGORIES, EDIT, INACTIVE } from "../../constants/constants";
import { AnchorLink, StatusTag } from "../../components/elements/Elements";

export const useCategories = () => {
  const tableColumns = () => [
    ...[
      "name",
      "description",
      "interest_rate",
      "max_amount",
      "created_at",
      "is_active",
      "actions",
    ].map((column) => createColumn(column)),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const { created_at, id, max_amount, is_active } = item || {};
      return {
        ...item,
        created_at: formatDate(created_at),
        max_amount: currencyFormat(max_amount),
        is_active: <StatusTag status={is_active ? ACTIVE : INACTIVE} />,
        actions: (
          <>
            <AnchorLink to={getForm(CATEGORIES, id)}>{EDIT}</AnchorLink>
          </>
        ),
      };
    }),
  ];

  const tableButton = {
    to: `${getForm(CATEGORIES)}`,
    // label: CATEGORIES,
  };

  return { tableColumns, tableData, tableButton };
};
