import {
  ArrowUndo,
  Bulb,
  CalendarOutline,
  CardOutline,
  DiamondOutline,
  Enter,
  Flag,
  HandRight,
  HeartDislikeOutline,
  Layers,
  Pencil,
  People,
  Person,
  PersonCircle,
  Receipt,
  ThumbsUp,
  Timer,
  WalletOutline,
} from "@raresail/react-ionicons";
import { API_REPORT_SUMMARY } from "../../constants/apis";
import { useEffect, useState } from "react";
import useGet from "../common/useGet";
import {
  APPROVED,
  CLOSED,
  OVERDUE,
  PENDING,
  REJECTED,
  WRITTEN_OFF,
} from "../../constants/constants";
import { primaryColor } from "../../constants/theme";
import { alpha } from "@mui/material";

export const useReports = () => {
  const { loadData, data, loading } = useGet();
  const [filter, setFilter] = useState();

  useEffect(() => {
    loadData({ api: API_REPORT_SUMMARY, params: filter });
  }, [filter]);

  const {
    applications,
    loans,
    overdue,
    transactions,
    users,
    transactions_by_month,
  } = data || {};
  const {
    active,
    disbursed,
    approved,
    cancelled,
    closed,
    pending,
    total,
    written_off,
    overdue: overdue_loans,
  } = loans?.counts || {};
  const { total_outstanding, total_overdue } = overdue || {};
  const {
    accepted,
    rejected,
    pending: pending_app,
    total: total_app,
  } = applications?.counts || {};
  const { total_requested, total_approved } = applications?.amounts || {};

  const { clients, staff, total: total_users } = users || {};
  const { disbursements, repayments, total_disbursed, total_repaid } =
    transactions || {};

  const applicationReport = {
    grids: [
      {
        col: 4,
        tiles: [
          {
            label: "pending_applications",
            value: pending_app,
            icon: Pencil,
            desc: true,
          },
          {
            label: "accepted_applications",
            value: accepted,
            icon: ThumbsUp,
            desc: true,
          },
          {
            label: "rejected_applications",
            value: rejected,
            icon: HandRight,
            desc: true,
          },
          {
            label: "total_applications",
            value: total_app,
            desc: "Total submitted applications",
          },
        ],
      },
      {
        col: 2,
        tiles: [
          {
            label: "total_requested_amount",
            value: total_requested,
            currency: true,
            desc: "Total amount requested in applications",
          },
          {
            label: "total_approved_amount",
            value: total_approved,
            currency: true,
            desc: "Total amount approved in applications",
          },
          // {
          //   label: "expected_interest",
          //   value: 0,
          //   icon: Bulb,
          //   currency: true,
          //   desc: "Expected total amount of interest",
          // },
        ],
      },
    ],
  };

  const loanReport = {
    grids: [
      {
        col: 4,
        tiles: [
          {
            label: "active_loans",
            value: active,
            icon: Bulb,
            desc: "Number of ongoing loans",
          },
          {
            label: "disbursed_loans",
            value: disbursed,
            icon: Receipt,
            desc: "Number of loans disbursed",
          },
          {
            label: "approved_loans",
            value: approved,
            icon: Flag,
            desc: true,
          },
          {
            label: "pending_loans",
            value: pending,
            icon: Pencil,
            desc: true,
          },
          {
            label: "rejected_loans",
            value: cancelled,
            icon: HeartDislikeOutline,
            desc: true,
          },
          {
            label: "overdue_loans",
            value: overdue_loans,
            icon: Timer,
            desc: true,
          },
          {
            label: "closed_loans",
            value: closed,
            icon: Enter,
            desc: true,
          },
          {
            label: "total_loans",
            value: total,
            icon: Layers,
            desc: "Total registered loans",
          },
        ],
      },

      {
        col: 2,
        tiles: [
          {
            label: "total_overdue_amount",
            value: total_overdue,
            icon: Timer,
            currency: true,
            desc: "Total loan overdue amount",
          },
          {
            label: "total_outstanding_amount",
            value: total_outstanding,
            icon: ArrowUndo,
            currency: true,
            desc: "Total outstanding balance amount",
          },
        ],
      },
    ],
  };

  const transactionsReport = {
    grids: [
      {
        col: 4,
        tiles: [
          {
            label: "disbursements",
            value: disbursements,
            icon: WalletOutline,
            desc: "Total number of disbursements",
          },
          {
            label: "repayments",
            value: repayments,
            icon: DiamondOutline,
            desc: "Total number of repayments",
          },
          {
            label: "total_disbursed",
            value: total_disbursed,
            icon: CalendarOutline,
            currency: true,
            desc: "Total amount of disbursed",
          },
          {
            label: "total_repaid",
            value: total_repaid,
            icon: CardOutline,
            desc: "Total amount repaid back",
            currency: true,
          },
        ],
      },
    ],
  };
  const usersReport = {
    grids: [
      {
        col: 3,
        tiles: [
          {
            label: "clients",
            value: clients,
            icon: PersonCircle,
            desc: "Total registered clients",
          },
          {
            label: "staff",
            value: staff,
            icon: Person,
            desc: "Total registered staff",
          },
          {
            label: "total_users",
            value: total_users,
            icon: People,
            desc: "Total system users",
          },
        ],
      },
    ],
  };

  // CATEGORIES
  const loanCategories = loans?.categories?.map(({ name, total }) => {
    return { value: total, label: name };
  });

  // TRANSACTIONS BY MONTH
  const repaymentsByMonth = transactions_by_month?.map(
    ({ month, total_repaid }) => {
      return { value: total_repaid, label: month };
    }
  );
  const disbursementsByMonth = transactions_by_month?.map(
    ({ month, total_disbursed }) => {
      return { value: total_disbursed, label: month };
    }
  );

  const reports = {
    applications: applicationReport,
    loans: {
      reports: loanReport,
      charts: {
        categories: loanCategories,
        performance: {
          data: [
            {
              label: PENDING,
              value: pending,
            },
            {
              label: APPROVED,
              value: approved,
            },
            {
              label: "Disbursed",
              value: disbursed,
            },
            {
              label: OVERDUE,
              value: overdue_loans,
            },
            {
              label: REJECTED,
              value: cancelled,
            },
            {
              label: CLOSED,
              value: closed,
            },
            {
              label: WRITTEN_OFF,
              value: written_off,
            },
          ],
          legend: "Total count",
          yLabel: "Number of loans",
        },
      },
    },
    transactions: {
      reports: transactionsReport,
      charts: {
        disbursements_by_month: {
          data: disbursementsByMonth,
          legend: "Total disbursements",
          yLabel: "Amount in UGX",
        },
        repayments_by_month: {
          data: repaymentsByMonth,
          legend: "Total repayments",
          yLabel: "Amount in UGX",
          colors: [alpha(primaryColor, 0.7), primaryColor],
        },
      },
    },
    users: usersReport,
  };

  return { reports, filter, setFilter, loading };
};
