import { Switch } from "antd";
import React from "react";

export const GenericSwitch = ({
  field,
  label,
  updateFormValues,
  value,
  ...props
}) => {
  const onChange = (checked) => {
    updateFormValues({ [field]: checked });
  };
  return (
    <div className="flexNullCenter gap10">
      <div>
        <Switch checked={value} onChange={onChange} {...props} />
      </div>
      <p className="capitalize font13 mainColor">{label}</p>
    </div>
  );
};
