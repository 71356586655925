import { API_INSTALLMENTS } from "../../constants/apis";
import { DATE, TEXT, UGX } from "../../constants/constants";
import { FieldProps } from "../../utils/formUtils";

export const useRepaymentForm = () => {
  // const loan = searchParams.get("loan");

  const fields = [
    {
      field: "due_date",
      type: DATE,
      required: true,
    },
    {
      field: "principal",
      type: TEXT,
      props: FieldProps.number,
    },
    {
      field: "interest",
      type: TEXT,
      props: { ...FieldProps.number, prefix: UGX },
    },
  ];

  const sections = [
    {
      title: "Repayment Details",
      fields,
    },
  ];

  function onSubmit({ payload, id }) {
    let params = { ...payload };

    if (id) {
      const { principal, due_date, fees, interest } = payload;
      params = {
        principal,
        due_date,
        fees,
        interest,
      };
    }

    return { id, api: API_INSTALLMENTS, params };
  }

  return { sections, onSubmit, fields };
};
