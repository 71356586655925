import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { RegisterHandler } from "../../handlers/RegisterHandler";
import { TABLE_VIEW } from "../../../constants/constants";

export const RegisterView = () => {
  const { source, status } = useParams();
  const search = useLocation().search;
  return (
    <>
      <RegisterHandler
        key={status + source + search}
        source={source}
        status={status}
        type={TABLE_VIEW}
      />
    </>
  );
};
