import React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@raresail/react-ionicons";
import { ViewHandler } from "../../handlers/ViewHandler";
import { makeSingular } from "../../../utils/helperUtils";

export const DetailsModal = ({ show, dismiss, source, id, title, state }) => {
  const handleClose = () => {
    if (dismiss) return dismiss();
  };

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        sx={{ zIndex: 1000 }}
      >
        {/* CHILDREN */}
        <div className="flexSpaceCenter">
          <DialogTitle>
            <p className="capitalize font14 bold mainColor">
              {title || `${makeSingular(source)} Details`}
            </p>
          </DialogTitle>
          <IconButton sx={{ mr: 1 }} onClick={handleClose}>
            <Close fontSize={20} />
          </IconButton>
        </div>
        <DialogContent dividers={true} sx={{ minHeight: 200, p: 1 }}>
          <ViewHandler {...{ source, id, state: { ...state, handleClose } }} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
