import { BrowserRouter } from "react-router-dom";
import { MainContainer } from "./components/layouts/MainContainer";
import { ThemeProvider } from "@mui/material";
import { ConfigProvider } from "antd";
import { antdTheme, themeMui } from "./constants/theme";
import { GlobalProvider } from "./components/context/GlobalContext";
import { AuthProvider } from "./components/context/AuthContext";
import { ModalController } from "./utils/modalUtils";
import { ModalProvider } from "./components/context/ModalContext";

function App() {
  return (
    <>
      <ThemeProvider theme={themeMui}>
        <ConfigProvider theme={antdTheme}>
          <GlobalProvider>
            <BrowserRouter>
              <AuthProvider>
                <ModalProvider>
                  <>
                    <MainContainer />
                    <ModalController />
                  </>
                </ModalProvider>
              </AuthProvider>
            </BrowserRouter>
          </GlobalProvider>
        </ConfigProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
