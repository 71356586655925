import { useState } from "react";
import useAxios from "./useAxios";

// type UploadDataParams = {
//   api: string,
//   params?: any,
//   id?: number,
//   type?: "application/json" | "multipart/form-data",
//   method?: string,
// };

const useSet = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState();
  const { set } = useAxios();

  const uploadData = ({ api, params, method, type, id, onSuccess }) => {
    // const { api, params, method, type, id } = options;
    const extras = {};

    // If id is passed get single instance, other fetch list
    setError(null);
    setSuccess(false);
    setSubmitting(true);
    set({
      api: id ? `${api}${id}/` : api,
      loader: setSubmitting,
      errorHandler: setError,
      successHandler: (res) => {
        setData(res);
        setSuccess(true);
        onSuccess && onSuccess(res);
      },
      methodName: method || (id ? "PATCH" : "POST"),
      params: { ...params, ...extras },
      type,
    });
  };

  const updateErrors = (err) => {
    setError(err ? { ...error, ...err } : null);
  };

  return {
    submitting,
    error,
    data,
    success,
    uploadData,
    updateErrors,
    setError,
    setSuccess,
  };
};

export default useSet;
