import React, { cloneElement, useState } from "react";
import { Drawer } from "antd";
import { capitalizeText } from "../../../utils/helperUtils";
export const DrawerView = ({
  title,
  button,
  children,
  destroy = false,
  extra,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [footer, setFooter] = useState();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div onClick={showDrawer}>{button}</div>

      <Drawer
        footer={footer}
        title={capitalizeText(title)}
        onClose={onClose}
        open={open}
        extra={extra?.[title]}
        styles={{ body: props?.bodyStyle }}
        destroyOnClose={destroy}
      >
        {children && cloneElement(children, { setFooter })}
      </Drawer>
    </>
  );
};
