import { useEffect } from "react";
import { API_CHARGE_PENALTIES, API_CHARGES } from "../../constants/apis";
import { SELECT, TEXT, UGX } from "../../constants/constants";
import { FieldProps } from "../../utils/formUtils";
import useGet from "../common/useGet";
import { charges_types } from "../../constants/options";
import { addTagOption, currencyFormat } from "../../utils/helperUtils";
import { Tag } from "antd";

export const useChargePenaltiesForm = () => {
  const { loadData, data, loading } = useGet();

  useEffect(() => {
    loadData({ api: API_CHARGES });
  }, []);

  const fields = [
    {
      field: "charge",
      type: SELECT,
      required: true,
      options: data?.results?.map((charge) =>
        addTagOption(charge, {
          tag: currencyFormat(charge?.amount),
          exact: true,
        })
      ),
      props: { loading },
      option_keys: ["amount", "type"],
    },
    {
      field: "type",
      type: SELECT,
      required: true,
      options: charges_types,
      props: FieldProps.disabled,
    },
    {
      field: "amount",
      type: TEXT,
      required: true,
      props: { ...FieldProps.number, ...FieldProps.disabled, prefix: UGX },
    },
    {
      field: "comment",
      type: TEXT,
      required: true,
      props: { rows: 2 },
    },
  ];

  const sections = [
    {
      title: "Charge Details",
      fields,
    },
  ];
  function onSubmit({ payload, id }) {
    return {
      id,
      api: API_CHARGE_PENALTIES,
      params: { ...payload },
    };
  }
  return { sections, fields, onSubmit };
};
