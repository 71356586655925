import { useEffect } from "react";
import { API_TRANSACTIONS } from "../../constants/apis";
import {
  COMPONENT,
  DISBURSEMENT,
  REPAYMENT,
  SELECT,
  TEXT,
} from "../../constants/constants";
import { FieldProps } from "../../utils/formUtils";
import { addSelectOption, returnObject } from "../../utils/helperUtils";
import { useLoanSearch } from "../loans/useLoansForm";
import { InformationCircleSharp } from "@raresail/react-ionicons";
import { useLocation } from "react-router-dom";

export const useTransactionsForm = ({ updateFormValues, form }) => {
  // id means form is belongs to a loan
  const location = useLocation();
  const isWorkArea = location.pathname.includes("workarea");

  // const loan = searchParams?.get("loan");
  // const type = searchParams?.get("type");
  // const amount = searchParams?.get("amount");

  const loan = form?.loan;
  const type = form?.type;
  const amount = form?.amount;

  useEffect(() => {
    if (type) {
      updateFormValues({ type, amount });
    }
  }, [type]);

  const fields = [
    ...returnObject(!loan || !isWorkArea, useLoanSearch({ id: loan })),
    {
      field: "type",
      label: "Transaction type",
      type: SELECT,
      options: [addSelectOption(REPAYMENT), addSelectOption(DISBURSEMENT)],
      required: true,
    },
    {
      field: "amount",
      type: TEXT,
      props: FieldProps.number,
      required: true,
    },
    {
      field: "comment",
      props: { rows: 2 },
      type: TEXT,
    },
    {
      type: COMPONENT,
      component: (
        <div className="flexNullCenter gap10 greyColor">
          <InformationCircleSharp />
          <p className="font12">
            Transactions made will first clear any missed, overdue or partially
            paid installments.
          </p>
        </div>
      ),
    },
  ];

  const sections = [
    {
      title: "Payment Details",
      fields,
    },
  ];

  function onSubmit({ payload }) {
    return { api: API_TRANSACTIONS, params: payload };
  }

  return { sections, fields, onSubmit };
};
