import React, { createContext, useState, useContext } from "react";

// Create a Context
const ModalContext = createContext();

// This will hold our update function
let updateModalContext;

// Context Provider
export const ModalProvider = ({ children }) => {
  const [state, setState] = useState(null);

  // Update the state in context
  updateModalContext = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <ModalContext.Provider value={state}>{children}</ModalContext.Provider>
  );
};

// Custom function to get context value
export const useModalContext = () => useContext(ModalContext);

// Export the updater function
export { updateModalContext };
