import mLogo from "./logo/logo.svg";
import favicon from "./logo/favicon.svg";
import { ReactComponent as mTimeline } from "./icons/timeline.svg";
import { ReactComponent as mCoins } from "./icons/coins.svg";
import { ReactComponent as note } from "./icons/note.svg";
import { ReactComponent as note_filled } from "./icons/note_filled.svg";

// LOGO
export const LogoSVG = mLogo;
export const LogoHorizontalSVG = mLogo;
export const LogoIcon = favicon;

// OTHERS
export const TimelineSVG = mTimeline;
export const CoinsSVG = mCoins;
export const NoteSVG = note;
export const NoteFilledSVG = note_filled;
