import { DatePicker } from "antd";
import React from "react";
import { FieldWrapper } from "../../elements/FieldWrapper";
import dayjs from "dayjs";

export const DateInput = ({
  updateFormValues,
  updateChanges,
  label,
  helperText,
  error,
  field,
  required,
  info,
  value,
  ...props
}) => {
  const onChange = (date, dateString) => {
    const values = { [field]: dateString };
    updateFormValues(values);
    // changes
    if (updateChanges) updateChanges(values);
  };

  return (
    <div>
      <FieldWrapper
        {...{ label, helperText, error, field, required, info }}
        {...props}
      >
        <DatePicker onChange={onChange} value={value && dayjs(value)} />
      </FieldWrapper>
    </div>
  );
};
