import {
  createColumn,
  currencyFormat,
  filterOption,
  formatDate,
  formatDateTime,
  getForm,
  getUser,
  numberFormat,
  returnObject,
} from "../../utils/helperUtils";
import {
  ActionsPopup,
  BalanceField,
  RefLink,
  StatusTag,
} from "../../components/elements/Elements";
import {
  CalendarOutline,
  CloseCircleOutline,
  CreateOutline,
} from "@raresail/react-ionicons";
import {
  ACTIVE,
  APPROVED,
  CANCELLED,
  CLOSED,
  DATE,
  DUE_TODAY,
  EDIT,
  INSTALLMENT,
  INSTALLMENTS,
  MISSED,
  NOT_PAID,
  OVERDUE,
  PAID,
  PARTIALY_PAID,
  PENDING,
  REPAYMENT,
  REPAYMENTS,
  TRANSACTIONS,
  WRITE_OFF,
} from "../../constants/constants";
import { Button } from "antd";
import { ExportExcelOption } from "../../constants/options";
import { useLocation } from "react-router-dom";
import {
  openModal,
  openModalDetails,
  openModalForm,
} from "../../utils/modalUtils";
import { ScheduleForm } from "../../components/pages/schedule/ScheduleForm";

export const useInstallments = ({ params, reloadData, refreshData, state }) => {
  const { loan_status, data: loanData } = state || {};
  const loanId = params?.loan;

  const { search } = useLocation();

  const tableColumns = () => [
    ...[
      { id: "due_date", filter: { type: DATE } },
      ...returnObject(!loanId, [
        "ref_number",
        {
          id: "loan_status",
          filter: {
            options: [ACTIVE, APPROVED, CANCELLED, CLOSED].map((val) =>
              filterOption(val)
            ),
          },
        },
      ]),
      "principal",
      "interest",
      { id: "total_amount", title: "total" },
      ...returnObject(loan_status !== PENDING, [
        { id: "paid_amount", title: "paid" },
        { id: "balance" },
        { id: "payment_date", title: "Paid date" },
        {
          id: "status",
          filter: {
            options: [
              PAID,
              NOT_PAID,
              PARTIALY_PAID,
              DUE_TODAY,
              OVERDUE,
              MISSED,
            ].map((val) => filterOption(val)),
          },
        },
      ]),
      "actions",
    ].map((column) => createColumn(column)),
  ];
  const tableData = (data) => [
    ...data.map((item) => {
      const {
        due_date,
        status,
        payment_date,
        balance,
        id,
        loan,
        loan_status,
        ref_number,
      } = item || {};

      const isPaid = status === PAID;

      return {
        ...item,
        due_date: formatDate(due_date),
        ref_number: <RefLink>{ref_number}</RefLink>,
        loan_status: <StatusTag status={loan_status} />,
        payment_date: formatDate(payment_date),
        status: <StatusTag status={status} />,

        ...["principal", "total_amount", "interest", "paid_amount"].reduce(
          (acc, key) => {
            return { ...acc, [key]: numberFormat(item[key]) };
          },
          {}
        ),

        balance: <BalanceField isPaid={isPaid} balance={balance} />,

        actions: (
          <div className="flexNullCenter gap5">
            {!isPaid && loan_status !== PENDING && (
              <Button
                size="small"
                className="font12"
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  openModalForm({
                    id,
                    source: TRANSACTIONS,
                    state: {
                      defaultValues: { loan, amount: balance, type: REPAYMENT },
                      reloadData,
                      refreshData,
                      submitText: "Add Transaction",
                    },
                  });
                }}
                style={{ boxShadow: "none" }}
              >
                Pay
              </Button>
            )}
            <ActionsPopup
              actions={[
                {
                  label: EDIT,
                  to: getForm(REPAYMENTS, id),
                  icon: CreateOutline,
                  visible: !isPaid,
                },
                {
                  label: WRITE_OFF,
                  to: getForm(REPAYMENTS, id),
                  icon: CloseCircleOutline,
                  visible: !isPaid,
                },
                // createAction("Delete", null, TrashOutline),
              ]}
            />
          </div>
        ),

        onClick: () => {
          openModalDetails({
            source: INSTALLMENTS,
            id: item?.id,
            title: "Repayment Details",
          });
        },
      };
    }),
  ];

  const tableActions = ({ data }) => {
    const hasSchedule = data?.results?.length > 0;
    return [
      {
        label: "New Schedule",
        // to: `/schedule/${loanId}`,
        onClick: () =>
          openModal({
            body: <ScheduleForm loanId={loanId} reloadData={reloadData} />,
            title: "Create Loan Schedule",
          }),
        icon: CalendarOutline,
        visible: loanId != null && !hasSchedule,
      },
      {
        label: "Reschedule",
        // to: `/schedule/${loanId}`,
        onClick: () =>
          openModal({
            body: (
              <ScheduleForm
                loanId={loanId}
                reloadData={reloadData}
                isReschedule
              />
            ),
            title: "Reschedule Loan",
          }),
        icon: CalendarOutline,
        visible: loanId != null && hasSchedule,
      },
      // EXPORT TO EXCEL
      ExportExcelOption({ module: INSTALLMENT }),
      // addAction(REPAYMENTS, `${getForm(REPAYMENTS)}?loan=${loanId}`),
      // createAction("Refresh", refreshData, Refresh),
    ];
  };

  const tableButton = {
    modal: true,
    source: REPAYMENTS,
    state: {
      defaultValues: { loan: loanId },
      reloadData,
    },
    visible: loanId != null,
  };

  const totalRow = () => {
    if (!loanId) return;
    const {
      outstanding_balance,
      amount_paid,
      payment_amount,
      interest_amount,
      charges,
    } = loanData || {};

    const payable_principal = payment_amount - interest_amount - charges; // TODO: create this field on the backend

    const totalFields = {
      due_date: "Total",
      principal: numberFormat(payable_principal),
      interest: numberFormat(interest_amount),
      total_amount: numberFormat(payment_amount),
      paid_amount: numberFormat(amount_paid),
      balance: (
        <BalanceField
          balance={outstanding_balance}
          isPaid={outstanding_balance <= 0}
        />
      ),
    };
    return totalFields;
  };

  const tableParams = {
    period: search?.split("=")[1],
  };

  const tableOptions = {
    filterBar: {
      visible: !loanId,
      selectProps: {
        defaultValue: tableParams?.period || "all",
        field: "period",
        options: [
          { label: "All", value: "all" },
          { label: "Today", value: "today" },
          { label: "Tomorrow", value: "tomorrow" },
          { label: "This Week", value: "current_week" },
          { label: "This Month", value: "current_month" },
        ],
      },
    },
  };

  const detailsData = ({ data }) => {
    const {
      created_at,
      ref_number,
      updated_at,
      due_date,
      created_by,
      updated_by,
      status,
      payment_date,
      loan_status,
    } = data || {};
    return {
      ref_number: <RefLink>{ref_number}</RefLink>,
      loan_status: <StatusTag status={loan_status} />,
      due_date: formatDate(due_date),
      ...[
        "principal",
        "interest",
        "total_amount",
        "paid_amount",
        "balance",
      ].reduce((acc, key) => {
        return { ...acc, [key]: currencyFormat(data?.[key]) };
      }, {}),
      paid_date: formatDateTime(payment_date),
      status: <StatusTag status={status} />,
      ...getUser(created_by, "created_by"),
      ...getUser(updated_by, "updated_by"),
      created_at: formatDateTime(created_at),
      updated_at: formatDateTime(updated_at),
    };
  };

  return {
    tableColumns,
    tableData,
    tableTitle: "Repayments schedule",
    tableActions,
    tableButton,
    tableParams,
    totalRow,
    tableOptions,
    detailsData,
  };
};
