export const useLocalStorage = () => {
  function setData(key, data) {
    if (data) localStorage.setItem(key, JSON.stringify(data));
  }

  function getData(key) {
    const data = localStorage.getItem(key) || "";

    if (data !== "undefined" && data !== null && data !== "") {
      return JSON.parse(data);
    }
  }

  return { getData, setData };
};
