import React, { useEffect, useState } from "react";
import {
  moduleHandler,
  useAuditTrail,
} from "../../../hooks/audit-trail/useAuditTrail";
import useGet from "../../../hooks/common/useGet";
import { TimelineView } from "../../common/views/TimelineView";
import { API_AUDIT_TRAIL } from "../../../constants/apis";
import { Loader } from "../../common/Loader";
import { EmptyView } from "../../elements/Elements";
import { TablePagination } from "../../common/tables/TablePagination";

export const AuditTrailView = ({ source, id, setFooter }) => {
  const { listData } = useAuditTrail();
  const { data, loadData, loading } = useGet();
  const module = moduleHandler[source];

  const [page, setPage] = useState();

  useEffect(() => {
    loadData({
      api: API_AUDIT_TRAIL,
      params: {
        module,
        id,
        page,
      },
    });
  }, [source, id, page]);

  useEffect(() => {
    if (data?.count > 0 && setFooter) {
      setFooter(
        <TablePagination count={data?.count} setPage={setPage} current={page} />
      );
    }
  }, [data]);

  if (loading) return <Loader />;

  if (data?.results?.length === 0) return <EmptyView />;

  return (
    <div className="h100 flexColumn">
      <TimelineView data={listData(data?.results || [])} />
    </div>
  );
};
