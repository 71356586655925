export const APP_NAME = "Credtics";
export const APP_COMPANY_CODE = "CR";
export const APP_TAGLINE = (
  <div>
    Data-Driven <b>Credit Management</b> Solutions
  </div>
);
export const APP_CONTACT = "+(256) 757 712 667 | +(256) 771 669 216";
export const APP_EMAIL = "Email: info@credtics.com";
export const APP_ADDRESS = "Ntinda, Kampala (Uganda)";

export const CONST_CREDENTIALS_KEY = "DGD^#&#^#*#&#*(^GDGE#^*&*%%%";

// FORM FIELD TYPES
export const TEXT = "text";
export const SELECT = "select";
export const DATE = "date";
export const CHECK_BOX = "check_box";
export const SWITCH = "switch";
export const COMPONENT = "component";
export const FILE = "file";

// CONSTANTS
export const APPLICATIONS = "applications";
export const LOANS = "loans";
// export const PAYMENTS = "payments";
export const REPAYMENTS = "repayments";
export const DISBURSEMENTS = "disbursements";
export const COMMENTS = "comments";
export const CHARGES = "charges";
export const CHARGE_PENALTIES = "charge_penalties";
export const CATEGORIES = "categories";
export const TRANSACTIONS = "transactions";
export const LOAN_TRANSACTIONS = "loan_transactions";
export const INSTALLMENTS = "installments";
export const USERS = "users";
export const PROFILE = "profile";
export const NEW_USER = "new_user";
export const ROLES = "roles";
export const FORMDATA = "formdata";
export const SCHEDULE = "schedule";
export const DOCUMENTS = "documents";
export const AUDIT_ACTIVITY = "audit_activity";
export const REPORTS = "reports";
export const IMPORT = "import";
export const EXPORT = "export";
export const EXPORT_EXCEL = "Export Excel";
export const TASKS = "tasks";
export const MULTIPLE = "multiple";
export const UPDATE = "update";
export const SUBMIT = "submit";
export const AUTOMATIONS = "automations";
export const BRANCHES = "branches";
export const SETTINGS = "settings";
export const ACCOUNT = "account";
// SINGULARS
export const LOAN = "loan";
export const APPLICATION = "application";
export const INSTALLMENT = "installment";
export const TRANSACTION = "transaction";

// MISC
export const NOT_AVAILABLE = "not available";
export const NO_TITLE = "no title";
export const CREATED_AT = "created_at";
export const UPDATED_AT = "updated_at";
export const N_A = "N/A";
export const ADD = "add";
export const EDIT = "edit";
export const WRITE_OFF = "Write-Off";
export const UGX = "UGX";
export const OVERVIEW = "overview";
export const STAFF = "staff";
export const CLIENT = "client";
export const LIST_SEARCH = "?list";

// LABELS
export const EMAIL_VERIFICATION =
  "Verification email will be sent to this email address.";
export const APPLICATION_TERMS =
  "I hereby declare that all information provided in this application is true, complete, and accurate to the best of my knowledge and belief. I understand that any misrepresentation or omission of facts in this application may result in the rejection of my application.";
export const ERROR_MESSAGE = "An error occurred!";

// PLACEHOLDERS
export const COMMENTS_PLACEHOLDER = "Add your comment here...";
export const SEARCH_PLACEHOLDER = "Search...";

// PAYMENT FREQUENCY
export const DAILY = "daily";
export const MONTHLY = "monthly";

// REPAYMENT TYPES
export const FIXED_INTEREST = "fixed_interest";
export const INTEREST_ONLY = "interest_only";

// LAYOUTS
export const COLUMN = "column";

// STATUS
export const PENDING = "pending";
export const APPROVED = "approved";
export const ACCEPTED = "accepted";
export const REJECTED = "rejected";
export const CLOSED = "closed";
export const CANCELLED = "cancelled";
export const ALL = "all";
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const OVERDUE = "overdue";
export const DUE_TODAY = "due_today";
export const PAID = "paid";
export const NOT_PAID = "not_paid";
export const PARTIALY_PAID = "partially_paid";
export const MISSED = "missed";
export const WRITTEN_OFF = "written_off";
export const COMPLETED = "completed";
export const IN_PROGRESS = "in_progress";

// DATE FORMATS
export const DATE_FORMAT = "DD MMM YYYY";
export const DATE_TIME_FORMAT = "DD MMM YYYY, HH:mm";

// LIST TYPES
export const TABLE_VIEW = "table";
export const LIST_VIEW = "list";

// ACTIONS
export const ACCEPT = "accept";
export const APPROVE = "approve";
export const CANCEL = "cancel";
export const REJECT = "reject";
export const DISBURSE = "disburse";
export const YES = "yes";
export const NO = "no";

// UPLOAD_FORMATS
export const ACCEPTED_FILES = ".png,.jpeg,.jpg,.doc,.docx,.pdf";
export const ACCEPTED_DATA_IMPORT_FILES = ".xlsx";
export const MAX_FILE_SIZE = 3;

// TRANSACTION TYPES
export const DISBURSEMENT = "disbursement";
export const REPAYMENT = "repayment";

// PERMISSIONS
export const CAN_ADMIN = "can_admin";
export const CAN_VIEW_TRANSACTION = "view_transaction";
export const CAN_PUBLIC = "can_public";
export const CAN_VIEW_LOAN = "view_loan";
export const CAN_VIEW_APPLICATION = "view_application";
export const CAN_CHANGE_LOAN_STATUS = "change_loan_status";
export const CAN_VIEW_USER = "view_customuser";
export const CAN_CHANGE_LOAN = "change_loan";
export const CAN_CHANGE_APPLICATION = "change_application";
export const CAN_CHANGE_APPLICATION_STATUS = "change_application_status";

// ROLES
export const PUBLIC_USER = "public user";
