import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useGet from "../../hooks/common/useGet";
import { sourceApis } from "../../hooks/handlers/useApiHandler";
import { Button, Tabs, Tag } from "antd";
import {
  getStatusColor,
  RenderTitleButtons,
  stringify,
} from "../../utils/helperUtils";
import { NO_TITLE } from "../../constants/constants";
import { ChevronDown, ShieldHalf } from "@raresail/react-ionicons";
import { registerHooks } from "../../hooks/handlers/useRegisterHandler";
import {
  ActionsPopup,
  EmptyView,
  GridWrapper,
  RenderTags,
} from "../elements/Elements";
import { Loader, OverlayLoader } from "../common/Loader";
import { WorkAreaActions } from "../layouts/WorkAreaActions";
import { IsPublicUser } from "../../utils/permissionUtils";

export const WorkAreaHandler = ({ source, id, name }) => {
  const { loadData, data, loading } = useGet();
  const { ref_number, status } = data || {};
  const hooks = registerHooks[source];
  const navigate = useNavigate();

  // fetch the instance data
  function getData() {
    if (id && source && sourceApis[source]) {
      loadData({ id, api: sourceApis[source], replace: false });
    }
  }

  // hook destructure
  const { tabs, titleFields, titleActions, titleButtons, title, tags } = hooks({
    data: data || {},
    reload: getData,
  });

  useEffect(() => {
    getData();
  }, [id]);

  // if (loading) return <Loader />;

  if (!data) {
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <EmptyView iconSize={35}>
            <p>Data will be displayed here</p>
            <p className="mainColor">Learn More</p>
          </EmptyView>
        )}
      </>
    );
  }

  // tab changes
  const onChange = (key) => {
    const path = window.location.pathname;
    const search = window.location.search;
    navigate(path?.replace(name, key) + search);
  };

  return (
    <div className="flex gap15 x100 relative" key={loading}>
      <div className="radius10 flexColumn flexGrow">
        {/* TITLE */}
        <TitleWrapper className="whiteBg p15 flexSpaceCenter">
          <div className="flexColumn gap10">
            <div className="flexNullCenter gap10">
              <div className="bold mainColor font18 capitalize">
                {title || ref_number || NO_TITLE}
              </div>

              {status ? (
                <Tag
                  className="capitalize flexNullCenter gap5 m0"
                  color={getStatusColor(status)}
                  icon={<ShieldHalf />}
                >
                  {stringify(status)}
                </Tag>
              ) : (
                <ShieldHalf />
              )}

              <RenderTags tags={tags} />
            </div>
            {/* TITLE FIELDS */}
            {titleFields && (
              <div className="flexWrap gap10 capitalize">
                {Object.keys(titleFields).map((key) => (
                  <div className="font11 greyColor">
                    <b>{stringify(key)}:</b> <span>{titleFields[key]}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* ACTIONS */}
          <div className="flexNullCenter gap5 flexWrap flexNullEnd">
            {/* BUTTONS */}
            {titleButtons &&
              RenderTitleButtons({
                buttons: titleButtons(),
                source,
                id,
                state: data,
                reload: getData,
              })}
            {/* POPUP */}
            {titleActions?.length > 0 && (
              <ActionsPopup actions={titleActions}>
                <Button
                  className="font12"
                  iconPosition="end"
                  style={{ padding: 10 }}
                  icon={<ChevronDown />}
                >
                  Actions
                </Button>
              </ActionsPopup>
            )}
          </div>
        </TitleWrapper>

        {/* BODY */}
        <div className="h100 flexColumn x100">
          <div className="flex flexGrow" style={{ height: 0 }}>
            <Tabs
              className="scroll flexGrow hideScroll"
              style={{ width: 0 }}
              // defaultActiveKey={name}
              activeKey={name}
              onChange={onChange}
              items={
                tabs && [
                  ...tabs({ data })?.map((tab, key) => {
                    const { children, props, label, visible = true } = tab;
                    if (visible)
                      return {
                        key: label?.toLowerCase() || key,
                        ...tab,
                        children: (
                          <GridWrapper
                            style={{ alignItems: "start", gap: 15 }}
                            {...props}
                          >
                            {children}
                          </GridWrapper>
                        ),
                      };
                    return null;
                  }),
                ]
              }
              tabBarExtraContent={{ left: <div style={{ padding: 7 }} /> }}
              size="small"
              tabBarStyle={{
                backgroundColor: "white",
                borderRadius: "0 0 7px 7px",
              }}
            />
          </div>
        </div>
      </div>
      {!IsPublicUser() && (
        <RightAside className="whiteBg rounded">
          <WorkAreaActions
            data={data}
            source={source}
            id={id}
            reload={getData}
          />
        </RightAside>
      )}
      {loading && <OverlayLoader />}
    </div>
  );
};

const TitleWrapper = styled.div`
  border-bottom: 1px solid #eee;
  border-radius: 7px 7px 0 0;
`;

/* <Button
onClick={() => {
  window.history.replaceState(
    { data: "Hello" },
    "New Page Title",
    `/view/${id}`
  );
}}
>
Change Route
</Button> */

const RightAside = styled.aside`
  width: 50px;
  padding: 10px;
  @media (max-width: 960px) {
    display: none;
  }
`;
