import React, { useEffect } from "react";
import { GridWrapper } from "../../elements/Elements";
import { SCHEDULE } from "../../../constants/constants";
import { Button } from "antd";
import { TableView } from "../../common/tables/TableView";
import { useSchedule } from "../../../hooks/schedule/useSchedule";
import useGet from "../../../hooks/common/useGet";
import { API_SCHEDULE } from "../../../constants/apis";
import { useParams } from "react-router-dom";
import { RenderForm } from "../../../utils/formUtils";
import useForm from "../../../hooks/common/useForm";
import { useScheduleOptions } from "../../../hooks/schedule/useScheduleOptions";
import useSet from "../../../hooks/common/useSet";
import { useToasts } from "../../../hooks/common/useToasts";
import { ConfirmDialog } from "../../common/modals/ConfirmDialog";

export const ScheduleForm = ({
  loanId,
  reloadData,
  dismiss,
  isReschedule = false,
}) => {
  const { id } = useParams();
  const { loadData, data, loading } = useGet();
  const { uploadData, success, error, submitting } = useSet();
  const { tableColumns, tableData, totalRow } = useSchedule();
  const {
    form,
    onChange,
    updateFormValues,
    changes,
    updateChanges,
    setChanges,
  } = useForm();
  const { fields } = useScheduleOptions({ form, isReschedule });
  const api = API_SCHEDULE;

  function getPreview() {
    loadData({
      id: id || loanId,
      api,
      params: { ...form, is_rescheduled: isReschedule },
      onSuccess: () => {
        setChanges(false);
      },
    });
  }

  // get schedule
  useEffect(getPreview, []);

  // notifications
  useToasts({ success, error, redirect: false });

  // update filters
  useEffect(() => {
    if (data) {
      const {
        payment_frequency,
        loan_term,
        repayment_type,
        start_date,
        principal,
        interest_rate,
      } = data;

      const options = {
        payment_frequency,
        loan_term,
        repayment_type,
        start_date,
        principal,
        interest_rate,
      };
      updateFormValues(options);
    }
  }, [data]);

  function onSubmit() {
    const installments = data?.installments;
    if (installments) {
      uploadData({
        api,
        params: { installments, is_rescheduled: isReschedule },
        onSuccess: () => {
          dismiss();
          reloadData();
        },
      });
    }
  }

  return (
    <div className="flexColumn gap15 h100">
      <GridWrapper grid="1fr 2fr" className="flexGrow h100 p15">
        {/* OPTIONS */}
        <div className="flexColumn gap15">
          <div className="rounded whiteBg">
            {/* FORM */}
            <GridWrapper className="" fullWidth>
              {RenderForm({
                form,
                fields,
                onChange,
                updateFormValues,
                updateChanges,
              })}
            </GridWrapper>
          </div>
          <div className="flex gap15">
            {/* PREVIEW */}
            <Button onClick={getPreview}>Apply Filter</Button>
            {/* SUBMIT */}
            <ConfirmDialog title="Create New Schedule" onConfirm={onSubmit}>
              <Button
                type="primary"
                loading={submitting}
                disabled={loading || changes}
              >
                {isReschedule ? "Reschedule" : "Create Schedule"}
              </Button>
            </ConfirmDialog>
          </div>
        </div>
        {/* TABLE */}
        <div className="h100 scroll">
          <TableView
            title={`${SCHEDULE} Preview`}
            columns={tableColumns()}
            rows={tableData(data?.installments || [])}
            loading={loading}
            totalRow={totalRow({ data } || [])}
            count={data?.installments?.length}
            showPagination={false}
          />
        </div>
      </GridWrapper>
    </div>
  );
};
