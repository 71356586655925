import axios from "axios";
import { BASE_API_URL } from "../../constants/config";
import { useAuth } from "../../components/context/AuthContext";
import { FORMDATA } from "../../constants/constants";
import { message } from "antd";

function useAxios() {
  const { authToken, logout } = useAuth();

  const get = async (options) => {
    const {
      api,
      loader,
      successHandler,
      isExternal,
      errorHandler,
      params,
      type,
      token: customToken,
    } = options;

    const token = customToken || authToken;

    return await axios({
      method: "get",
      headers: {
        "Content-Type": type || "application/json",
        ...(!isExternal && token ? { Authorization: `Bearer ${token}` } : {}),
      },
      url: isExternal ? api : `${BASE_API_URL}${api}`,
      params,
    })
      .then((resp) => {
        const { data } = resp;

        if (successHandler) successHandler(data);
        if (loader) loader(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) return logout();

        const errorMessage =
          err?.response?.data?.detail ||
          err?.message ||
          "An unknown eror occured";
        // show errors
        message.error(errorMessage);

        if (errorHandler) errorHandler(errorMessage);
        if (loader) loader(false);
      });
  };

  const set = async (options) => {
    const {
      api,
      loader,
      successHandler,
      errorHandler,
      params,
      methodName,
      type,
    } = options;

    return await axios({
      headers: {
        "Content-Type":
          (type === FORMDATA && "multipart/form-data") || "application/json",
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      },
      method: methodName,
      url: `${BASE_API_URL}${api}`,
      data: params,
    })
      .then((resp) => {
        const { data } = resp;
        if (successHandler) successHandler(data);
        if (loader) loader(false);
      })
      .catch((resp) => {
        let message = "An unknown error occured";

        if (resp?.response?.status === 401) {
          message = resp?.response?.data?.detail || "Unauthorized Access";
          logout();
        } else if (resp?.response?.status === 500) {
          message = "Internal server error occured";
        } else if (resp?.response?.status === 402) {
          message = "Server timeout error occured";
        } else {
          message = resp?.response?.data || "An unknown error occured";
        }

        if (errorHandler) errorHandler(message);
        if (loader) loader(false);
      });
  };

  return { get, set };
}

export default useAxios;
