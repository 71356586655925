import { Popover, Progress, Tag, Tooltip } from "antd";
import {
  AlertCircleSharp,
  CreateOutline,
  EllipsisVertical,
  FileTrayOutline,
  InformationCircle,
  InformationCircleOutline,
  OpenOutline,
} from "@raresail/react-ionicons";
import styled from "styled-components";
import {
  ALL,
  APP_NAME,
  APPLICATIONS,
  COLUMN,
  EDIT,
  LOANS,
  N_A,
  OVERVIEW,
} from "../../constants/constants";
import {
  currencyFormat,
  formatDate,
  getStatusColor,
  getWorkArea,
  numberFormat,
  stopPropagation,
  stringify,
} from "../../utils/helperUtils";
import { primaryColor } from "../../constants/theme";
import { Link } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { Button } from "../common/GenericButton";

export const ErrorText = ({ children }) => {
  if (children) return <p className="font12 redColor">{children}</p>;
};

export const HelperText = ({ text }) => {
  if (text) return <p className="font11 greyColor thin">{text}</p>;
};

export const FormLabel = ({ children, required = false, info, ...props }) => {
  const { optional } = props || {};
  return (
    <p className="font13 mainColor flexNullCenter gap5 capitalize">
      {children} {required && <span className="redColor">*</span>}
      {info && (
        <Tooltip title={info}>
          <InformationCircleOutline
            className="mainColor"
            style={{ fontSize: 17 }}
          />
        </Tooltip>
      )}
      {optional && <span className="greyColor thin font12">(optional)</span>}
    </p>
  );
};

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.layout === COLUMN ? "1fr" : "repeat(2, 1fr)"};

  background-color: white;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

export const FieldWrapper = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: ${(props) =>
    props.layout === COLUMN ? "1fr" : "repeat(2, 1fr)"};
  gap: 20px;

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }

  & > *:only-child {
    grid-column: span 2;
  }

  & > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
    @media (max-width: 760px) {
      grid-column: 1;
    }
  }
`;

export const EmptyView = ({ children, iconSize }) => (
  <div className="flexCenter flexColumn gap5 flexGrow font12 greyColor x100 h100 whiteBg rounded p20">
    <FileTrayOutline style={{ fontSize: iconSize || 25 }} />
    <div className="flexColumn gap5 textCenter">
      {children || "Nothing to show"}
    </div>
  </div>
);

export const EmptyField = () => <i style={{ color: "#aaa" }}>{N_A}</i>;

export const StatusTag = ({ status, label, color }) => (
  <Tag
    className="capitalize"
    color={color || getStatusColor(status)}
    style={{ margin: 0 }}
  >
    {stringify(label || status)}
  </Tag>
);

export const GridWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: ${(props) =>
    props?.grid ? props?.grid : props?.fullWidth ? "1fr" : "repeat(2, 1fr)"};

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const ActionsMenu = (actions) => {
  return (
    <>
      {actions?.map(
        ({ label, to, onClick, icon: Icon, loading, visible = true }, i) =>
          visible && (
            <ItemWrapper
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                if (onClick) onClick();
              }}
              className="capitalize"
              style={
                loading
                  ? { pointerEvents: "none", color: "gray" }
                  : { color: "black" }
              }
            >
              <Link to={to}>
                {loading && (
                  <CircularProgress
                    size={15}
                    sx={{
                      strokeLinecap: "round",
                    }}
                    color="secondary"
                  />
                )}
                {Icon && <Icon fontSize="small" style={{ fontSize: 16 }} />}
                {label}
              </Link>
            </ItemWrapper>
          )
      )}
    </>
  );
};

export const ActionsPopup = ({ actions, children, ...props }) => {
  return (
    <Popover
      content={actions && ActionsMenu(actions)}
      trigger="click"
      overlayStyle={{ minWidth: 150 }}
      overlayInnerStyle={{ padding: 5 }}
      placement="bottomRight"
      {...props}
    >
      {children ? (
        children
      ) : (
        <IconButton size="small" onClick={stopPropagation}>
          <EllipsisVertical />
        </IconButton>
      )}
    </Popover>
  );
};

export const LinkItem = ({ children, to }) => {
  return (
    <Link
      to={to}
      style={{ textTransform: "none" }}
      className="flexNullCenter gap5"
      onClick={stopPropagation}
    >
      {children} <OpenOutline fontSize={15} />
    </Link>
  );
};

export const RefLink = ({ children: ref_number }) => {
  const id = ref_number?.split("/").pop();
  function getSource() {
    if (ref_number?.includes("LOA")) {
      return LOANS;
    }
    return APPLICATIONS;
  }
  return (
    <LinkItem to={`${getWorkArea(getSource(), ALL)}/${id}/${OVERVIEW}?list`}>
      {ref_number}
    </LinkItem>
  );
};

export const ItemWrapper = styled.div`
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 5px;
  transition: 0.3s ease;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: inherit;
  }

  &:hover {
    a {
      color: ${primaryColor};
    }
    background-color: #f2f2f2;
  }
`;

export const SectionTitle = ({
  actions,
  children,
  title,
  leftSide,
  icon: Icon,
  description,
}) => {
  return (
    <>
      {title && (
        <SectionTitleWrapper
          className="borderBottom flexSpaceCenter"
          description={description}
        >
          <div className="flexNullCenter gap10">
            {Icon && <Icon className="mainColor" />}

            <div>
              <p className="mainColor bold capitalize">{stringify(title)}</p>
              {description && (
                <p className="greyColor font11" style={{ marginTop: 5 }}>
                  {description}
                </p>
              )}
            </div>
            {leftSide}
          </div>
          {/* RIGHT SIDE */}
          <div className="flexNullCenter gap10">
            {children}
            {actions && <ActionsPopup actions={actions} />}
          </div>
        </SectionTitleWrapper>
      )}
    </>
  );
};

const SectionTitleWrapper = styled.div`
  padding: ${(props) => (props.description ? "10px 15px" : "0 15px")};
  min-height: 40px;
`;

export const RepaymentInfo = () => (
  <div>
    <b>Fixed monthly</b> - constant principal and interest rate.
    <br />
    <b>Interest-only</b> - interest is paid for initial installments and the
    principal in final payment.
  </div>
);

export const StatTile = ({ label, children, color }) => (
  <div className="radius10 whiteBg x100" style={{ padding: "15px 20px" }}>
    <div className="bold mainColor" style={{ color, fontSize: 16 }}>
      {children}
    </div>
    <div className="flexNullCenter gap5">
      <div className="capitalize font12">{label}</div>
      <InformationCircle className="greyColor" />
    </div>
  </div>
);

export const BalanceField = ({ balance, isPaid }) => {
  return (
    <div className="textNoWrap flexNullCenter gap5">
      {!isPaid && <AlertCircleSharp fontSize={14} className="redColor" />}
      {numberFormat(balance)}
    </div>
  );
};

export const VerticalDivider = styled.div`
  min-height: 30px;
  border-right: 1px solid #ddd;
`;

export const AnchorLink = ({ children, to }) => (
  <Link to={to} className="capitalize flexNullCenter gap5">
    <CreateOutline fontSize={15} />
    {children}
  </Link>
);

export const EditButton = ({ to }) => (
  <Button
    className="font12 capitalize"
    size="small"
    to={to}
    icon={<CreateOutline fontSize={14} />}
  >
    {EDIT}
  </Button>
);

export const ProgressTile = ({ data }) => {
  const { category_details, progress, next_payment } = data || {};
  return (
    <div
      className="radius10 p20 whiteColor gap20 flexNullCenter"
      style={{ background: "#060135" }}
    >
      <Progress
        type="circle"
        size={"small"}
        status="active"
        percent={progress}
        strokeColor="#009fff"
        trailColor="#ffffff40"
      />
      <div className="flexGrow">
        <p className="bold font14">{category_details?.name}</p>
        <p className="font12 white70">Completion at {progress || 0}%</p>
      </div>

      <div>
        <p className="font12 white70">Amount</p>
        <b className="font13">{currencyFormat(next_payment?.balance)}</b>
      </div>
      <div
        style={{
          height: 40,
          borderRight: "1px solid #7a7a7a",
        }}
      />
      <div>
        <p className="font12 white70">Next Payment</p>
        <p className="font13">{formatDate(next_payment?.due_date)}</p>
      </div>
    </div>
  );
};

export const RenderTags = ({ tags }) => {
  return (
    <span className="gap5 flex">
      {tags?.length > 0 &&
        tags?.map(
          ({ label, status, color, visible = true }) =>
            visible && <StatusTag label={label} status={status} color={color} />
        )}
    </span>
  );
};

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      style={{ padding: 16, borderTop: "1px solid #e9e9e9" }}
      className="textCenter font11"
    >
      {`© ${currentYear} Copyright ${APP_NAME}. All Rights Reserved.`}
    </div>
  );
};
