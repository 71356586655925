import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { guestRoutes, standardRoutes } from "../../constants/routes";
import { Loader } from "../common/Loader";
import { DashboardLayout } from "./DashboardLayout";
import { URL_DEFAULT } from "../../constants/urls";
import { useAuth } from "../context/AuthContext";

export const MainContainer = () => {
  const { loggedIn } = useAuth();
  // const loggedIn = null;

  // not logged in
  if (loggedIn === false) {
    return (
      <>
        <Routes>
          {guestRoutes.map(({ path, component }, i) => (
            <Route path={path} element={component} key={i} exact={true} />
          ))}
          <Route path="*" element={<Navigate replace to={URL_DEFAULT} />} />
        </Routes>
      </>
    );
    // logged in
  } else if (loggedIn === true) {
    return (
      <>
        <DashboardLayout>
          <Routes>
            {standardRoutes.map(({ path, component }, i) => (
              <Route path={path} element={component} key={i} exact={true} />
            ))}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </DashboardLayout>
      </>
    );
  } else {
    // data loading
    if (loggedIn === null) return <Loader full />;
  }
};
