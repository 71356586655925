import { API_COMMENTS } from "../../constants/apis";
import { COMMENTS_PLACEHOLDER, TEXT } from "../../constants/constants";

export const useCommentsForm = () => {
  // const object_id = searchParams?.get("object_id");
  // const content_type = searchParams?.get("type");

  const fields = [
    {
      field: "comment",
      props: { rows: 5, placeholder: COMMENTS_PLACEHOLDER },
      type: TEXT,
      required: true,
    },
  ];

  const sections = [
    {
      fields,
    },
  ];

  function onSubmit({ payload }) {
    return {
      api: API_COMMENTS,
      params: payload,
    };
  }

  return { sections, fields, onSubmit };
};
