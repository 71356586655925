import { ActionsPopup } from "../../components/elements/Elements";
import { BRANCHES } from "../../constants/constants";
import {
  addColumns,
  editModalAction,
  formatDateTime,
  getUser,
} from "../../utils/helperUtils";

export const useBranches = ({ refreshData }) => {
  const tableColumns = () =>
    addColumns(["name", "branch_code", "created_by", "created_at", "actions"]);

  const tableData = (data) => [
    ...data.map((item) => {
      const { created_by, created_at } = item || {};
      return {
        ...item,
        ...getUser(created_by),
        created_at: formatDateTime(created_at),
        actions: (
          <ActionsPopup
            actions={[editModalAction({ item, source: BRANCHES, refreshData })]}
          />
        ),
      };
    }),
  ];

  const tableButton = {
    modal: true,
    source: BRANCHES,
    state: {
      refreshData,
    },
  };

  return { tableColumns, tableData, tableButton };
};
