import React from "react";
import { RegisterHandler } from "../../handlers/RegisterHandler";
import { LOANS, STAFF } from "../../../constants/constants";
import { Collapse } from "antd";
import { FilterHandler } from "../../handlers/FilterHandler";
import { useFilter } from "../../../hooks/common/useFilter";

export const StaffReport = () => {
  const { addFilter, filter } = useFilter();
  // console.log(filter);

  // const { report } = useStaffReport();
  const items = [
    {
      key: "1",
      label: "Data Filters",
      children: <FilterHandler source={STAFF} addFilter={addFilter} />,
    },
  ];
  return (
    <div className="flexColumn gap15 h100 scroll">
      <div className="p15 whiteBg rounded">
        <h4>Staff Performance Report</h4>
      </div>
      <Collapse items={items} defaultActiveKey={["1"]} size="small" />
      {/* <ReportStatSection title={"Report"} data={report} /> */}
      <RegisterHandler
        source={LOANS}
        params={filter}
        key={JSON.stringify(filter)}
      />
    </div>
  );
};
