import React, { useEffect } from "react";
import { updateGlobalContext } from "../../context/GlobalContext";
import { AccountView } from "../account/AccountView";

export const SettingsView = () => {
  useEffect(() => {
    updateGlobalContext({ settings: true });
  }, []);

  return (
    <div className="flexColumn gap15">
      <h4 className="whiteBg rounded p15">My Settings</h4>
      <AccountView />
    </div>
  );
};
