import { LockClosedOutline, MailOutline } from "@raresail/react-ionicons";
import { COMPONENT, TEXT } from "../../constants/constants";
import { Heading } from "../../components/pages/home/AuthPage";
import { Link } from "react-router-dom";
import { URL_FORGOT_PASSWORD, URL_REGISTER } from "../../constants/urls";
import { useAuth } from "../../components/context/AuthContext";
import { API_TOKEN } from "../../constants/apis";

export const useLoginForm = () => {
  const { login } = useAuth();
  const fields = [
    {
      type: COMPONENT,
      component: <Heading>Welcome Back!</Heading>,
    },
    {
      type: COMPONENT,
      component: (
        <p className="font13">Please enter your information to sign in.</p>
      ),
    },
    {
      label: "Email Address",
      field: "email",
      type: TEXT,
      required: true,
      props: {
        prefix: <MailOutline />,
      },
    },
    {
      label: "Password",
      type: TEXT,
      required: true,
      props: {
        info: "Please enter your password",
        prefix: <LockClosedOutline />,
        type: "password",
      },
    },
    {
      type: COMPONENT,
      component: (
        <Link to={URL_FORGOT_PASSWORD}>
          <p className="secondaryColor font13 textCenter">Forgot Password ?</p>
        </Link>
      ),
    },
  ];

  const suffixFields = [
    {
      type: COMPONENT,
      component: (
        <div className="flexCenter gap10 font14">
          <p className="textCenter">Don't have an account?</p>
          <Link to={URL_REGISTER} className="secondaryColor semiBold">
            {"Sign Up"}
          </Link>
        </div>
      ),
    },
  ];

  function callback(data) {
    login(data);
  }

  function onSubmit({ payload }) {
    return { api: API_TOKEN, params: payload };
  }

  return { fields, submitText: "Sign In", suffixFields, callback, onSubmit };
};
