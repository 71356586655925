import React from "react";
import { useAuth } from "../../context/AuthContext";
import { FormHandler } from "../../handlers/FormHandler";
import { ACCOUNT, SETTINGS, USERS } from "../../../constants/constants";

export const AccountView = () => {
  const { user } = useAuth();

  return (
    <div className="h100">
      <FormHandler
        key={USERS}
        source={USERS}
        id={user?.id}
        title={`${ACCOUNT} ${SETTINGS}`}
      />
    </div>
  );
};
