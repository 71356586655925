import React from "react";
import {
  addColumns,
  filterOption,
  formatDateTime,
  getModuleRef,
  numberFormat,
  stringify,
} from "../../utils/helperUtils";
import { auditActions } from "../../constants/options";
import {
  APPLICATION,
  DATE,
  INSTALLMENT,
  LOAN,
  TRANSACTION,
  USERS,
} from "../../constants/constants";
import { RefLink } from "../../components/elements/Elements";

export const useAuditTrail = () => {
  const tableColumns = () =>
    addColumns([
      {
        id: "module",
        filter: {
          options: [filterOption(LOAN), filterOption(APPLICATION)],
        },
      },
      { id: "ref_number", filter: true },
      {
        id: "action",
        filter: {
          options: auditActions.map((val) => filterOption(val)),
        },
      },
      { id: "changes", filter: true },
      { id: "actor_name", filter: true },
      { id: "created_at", filter: { type: DATE } },
    ]);

  const tableData = (data) => [
    ...data.map((item) => {
      const { created_at, model_name, object_id, actor_name, action, changes } =
        item || {};
      return {
        ...item,
        module: <div className="capitalize">{model_name}</div>,
        changes: <ChangesView changes={changes} />,
        action: <div className="capitalize">{action}</div>,
        actor_name: actor_name || "core",
        ref_number: <RefLink>{getModuleRef(object_id, model_name)}</RefLink>,
        created_at: formatDateTime(created_at),
      };
    }),
  ];

  const listData = (data = []) => [
    ...data?.map((item) => {
      const { action, created_at, model_name, changes, actor_name } =
        item || {};

      const row = {
        children: (
          <div>
            <div className="flex gap20 font12 x100 mb10">
              <div className="flexGrow">
                {/* LABELS */}
                <span className="capitalize mainColor semiBold">
                  {action}
                </span>{" "}
                <span>on {model_name}</span>:
              </div>
              {/* LOG INFO */}
              <div className="font10 textRight">
                <div className="mainColor">{actor_name || "core"}</div>
                <div className="greyColor">{formatDateTime(created_at)}</div>
              </div>
            </div>
            {/* CHANGES */}
            <ChangesView changes={changes} />
          </div>
        ),
        color: "grey",
      };
      return row;
    }),
  ];
  return {
    listData,
    tableColumns,
    tableData,
    tableDescription:
      "Monitor any changes made on the system, schema and content with audit logs.",
  };
};

const ChangesView = ({ changes = [] }) => {
  function getValue(value) {
    if (typeof value === "string") {
      return stringify(value);
    } else if (typeof value === "number") {
      return numberFormat(value);
    } else if (typeof value === "boolean") {
      return value === true ? "True" : "False";
    }
    return value;
  }
  function getChanges(key) {
    const change = changes?.[key];

    if (typeof change !== "object") return getValue(change);
    return (
      <span>
        <span className="greyColor">{getValue(change?.old)}</span> to{" "}
        <span className="mainColor">{getValue(change?.new)}</span>
      </span>
    );
  }
  return (
    <>
      {Object.keys(changes).length > 0 && (
        <div className="x100 lightBgTrans p10 rounded flexColumn gap5">
          {Object.keys(changes)?.map((key, i) => {
            if (changes[key])
              return (
                <div
                  className="font10"
                  style={
                    i !== 0
                      ? { borderTop: "1px solid #ddd", paddingTop: 5 }
                      : {}
                  }
                >
                  <span>
                    <span className="semiBold capitalize">
                      {stringify(key)}
                    </span>
                    : {getChanges(key)}
                  </span>
                </div>
              );
            return null;
          })}
        </div>
      )}
    </>
  );
};

export const moduleHandler = {
  loans: LOAN,
  applications: APPLICATION,
  installments: INSTALLMENT,
  transactions: TRANSACTION,
  users: USERS,
};
