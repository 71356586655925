import { useEffect } from "react";
import {
  APPLICATION,
  COLUMN,
  LOAN,
  MONTHLY,
  SELECT,
  TEXT,
} from "../../constants/constants";
import { FieldProps } from "../../utils/formUtils";
import useGet from "../common/useGet";
import {
  API_APPLICATIONS,
  API_CATEGORIES,
  API_LOANS,
  API_USERS,
} from "../../constants/apis";
import { useSearchField, useSelectSearch } from "../common/useSelectSearch";
import {
  addTagOption,
  currencyFormat,
  getInterestAmount,
} from "../../utils/helperUtils";
import { payment_frequency, repayment_types } from "../../constants/options";
import { RepaymentInfo } from "../../components/elements/Elements";

export const useLoansForm = ({ id, form }) => {
  const { fields: loanFields } = useLoanFields({ form });
  const isUpdate = id;

  const clientFields = [
    ...useUsersSearch({ id }),

    ...useSearchField({
      api: API_APPLICATIONS,
      field: APPLICATION,
      tagOptions: { label: "ref_number", tag: "client" },
    }),
  ];

  const sections = [
    {
      title: "Client Details",
      fields: clientFields,
      layout: COLUMN,
      visible: !isUpdate,
    },
    {
      title: "Loan Details",
      fields: loanFields,
    },
  ];

  return { sections, onSubmit };
};

function onSubmit({ payload, id }) {
  let params = payload;
  if (id) {
    const {
      amount,
      repayment_type,
      payment_frequency,
      loan_term,
      interest_rate,
      category,
    } = payload;

    params = {
      amount,
      repayment_type,
      payment_frequency,
      loan_term,
      interest_rate,
      category,
    };
  }
  return { id, api: API_LOANS, params };
}

// LOAN FIELDS
export const useLoanFields = ({ form }) => {
  const { loadData: getCategories, data: categories, loading } = useGet();
  useEffect(
    () => getCategories({ api: API_CATEGORIES, params: { is_active: true } }),
    []
  );

  const fields = [
    {
      label: "Loan Category",
      field: "category",
      type: SELECT,
      required: true,
      props: { loading },
      options: categories?.results.map((category) =>
        addTagOption(category, { tag: "interest_rate", suffix: "%" })
      ),

      option_keys: ["interest_rate", "max_amount", "document_fields"],
    },
    {
      field: "interest_rate",
      type: TEXT,
      required: true,
      props: { ...FieldProps.number, disabled: true, prefix: "%" },
    },
    {
      field: "payment_frequency",
      type: SELECT,
      required: true,
      options: payment_frequency,
    },
    {
      field: "repayment_type",
      type: SELECT,
      required: true,
      options: repayment_types,
      props: {
        info: RepaymentInfo,
      },
    },
    {
      label: "Loan Amount",
      field: "amount",
      type: TEXT,
      required: true,
      props: {
        helperText: `Amount of money you want to borrow. Max Amount: ${currencyFormat(
          form?.max_amount
        )}`,
        // max: form?.max_amount,
        ...FieldProps.number,
      },
    },
    {
      field: "loan_term",
      type: TEXT,
      props: {
        ...FieldProps.number,
        helperText: "Number of months you want the loan to run.",
      },
      required: true,
      visible: form?.payment_frequency === MONTHLY,
    },
    {
      label: "Amount To be repaid",
      field: "payment_amount",
      value:
        getInterestAmount(form?.interest_rate, form?.amount, form?.loan_term) +
        form?.amount,
      type: TEXT,
      required: true,
      props: {
        helperText: "This money is inclusive of the interest",
        disabled: true,
        ...FieldProps.number,
      },
    },
    {
      label: "Total Interest Paid",
      field: "total_interest_paid",
      value: getInterestAmount(
        form?.interest_rate,
        form?.amount,
        form?.loan_term
      ),
      type: TEXT,
      props: {
        disabled: true,
        ...FieldProps.number,
      },
    },
  ];
  return { fields };
};

export function useLoanSearch({ id, field = LOAN, label = LOAN }) {
  const { loadData, data, loading } = useGet();

  const { searchValue: valueLoans, onSearch: searchLoans } = useSelectSearch();

  useEffect(() => {
    if (!id)
      loadData({
        api: API_LOANS,
        params: { search: valueLoans },
      });
  }, [valueLoans, id]);

  const fields = [
    {
      field,
      label,
      type: SELECT,
      required: true,
      props: {
        onSearch: searchLoans,
        loading,
      },
      options: data?.results?.map((loan) =>
        addTagOption(loan, { label: "ref_number", tag: "client_name" })
      ),
    },
  ];
  return fields;
}

export function useUsersSearch({
  id,
  option_keys,
  params,
  field,
  props,
  label,
}) {
  const { loadData, data, loading } = useGet();

  const { searchValue, onSearch } = useSelectSearch();

  useEffect(() => {
    if (!id)
      loadData({
        api: API_USERS,
        params: { search: searchValue, is_staff: false, ...params },
      });
  }, [searchValue, id]);

  const fields = [
    {
      field: field || "client",
      label,
      type: SELECT,
      required: true,
      option_keys,
      props: {
        onSearch,
        loading,
        ...props,
      },
      options: data?.results?.map((client) =>
        addTagOption(client, { label: "display_name", tag: "email" })
      ),
    },
  ];
  return fields;
}
