import React, { useEffect, useState } from "react";
import { RenderForm } from "../../../utils/formUtils";
import useForm from "../../../hooks/common/useForm";
import { IconButton } from "@mui/material";
import { AddCircle, TrashOutline } from "@raresail/react-ionicons";
import { Button } from "antd";

const TableRow = ({ fields, index, data, error, deleteRow, handleChange }) => {
  const { form, onChange, updateFormValues } = useForm(data);

  useEffect(() => {
    handleChange(index, form);
  }, [form]);

  return (
    <div className="flexNullCenter gap20">
      {/* INDEX */}
      {index + 1}
      {/* FORM */}
      {RenderForm({
        form: data,
        fields,
        onChange,
        updateFormValues,
        error,
      })}
      {/* ACTIONS */}
      <div className="flex">
        <IconButton onClick={deleteRow} color="error" size="small">
          <TrashOutline />
        </IconButton>
      </div>
    </div>
  );
};

export const ExpandableTable = ({
  data,
  formHook,
  updateFormValues,
  field,
}) => {
  const { fields, formatData } = formHook ? formHook() : {};
  const [rows, setRows] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setRows(data);
    }
  }, [data]);

  const updateGlobal = (data) => updateFormValues({ [field]: data });

  function addRow() {
    setRows((prev) => [...(prev?.length > 0 ? prev : []), {}]);
  }

  function handleChange(index, data) {
    const newRows = rows;
    const formattedData = formatData ? formatData(data) : data;
    newRows[index] = formattedData;
    setRows(newRows);
    updateGlobal(newRows);
  }

  function deleteRow(index) {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
    updateGlobal(newRows);
  }

  return (
    <div className="flexColumn gap20">
      {rows?.map((row, i) => (
        <TableRow
          fields={fields}
          data={row}
          key={i}
          index={i}
          deleteRow={() => deleteRow(i)}
          handleChange={handleChange}
        />
      ))}
      <div>
        <Button
          className="font13"
          onClick={addRow}
          type="primary"
          icon={<AddCircle fontSize={17} />}
        >
          Add Field
        </Button>
      </div>
    </div>
  );
};
