import React from "react";
import styled from "styled-components";
import { RegisterHandler } from "../handlers/RegisterHandler";
import { useLocation, useParams } from "react-router-dom";
import { WorkAreaHandler } from "../handlers/WorkAreaHandler";
import { LIST_SEARCH, LIST_VIEW } from "../../constants/constants";

export const WorkAreaLayout = () => {
  const { source, status, id, name } = useParams();
  const { search } = useLocation();

  return <ContentView key={source} {...{ source, status, id, name, search }} />;
};

const ContentView = ({ source, status, id, name, search }) => {
  const hideList = search === LIST_SEARCH;

  return (
    <>
      <div className="h100 flex gap15">
        <GridWrapper className="h100 x100" search={hideList}>
          {/* LISTVIEW */}
          {!hideList && (
            <RegisterHandler
              key={status}
              source={source}
              status={status}
              type={LIST_VIEW}
            />
          )}
          {/* DETAILS VIEW */}
          <WorkAreaHandler
            key={`${source}/${status}`}
            source={source}
            id={id}
            name={name}
          />
        </GridWrapper>
      </div>
    </>
  );
};

const GridWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: ${(props) =>
    props?.search ? "1fr" : "minmax(250px, 1fr) 3fr"};

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
