import React from "react";
import { Button } from "../common/GenericButton";
import styled from "styled-components";
import { RenderForm } from "../../utils/formUtils";
import useForm from "../../hooks/common/useForm";
import { filterFormHooks } from "../../hooks/handlers/useFormHandler";

export const FilterHandler = ({ source, addFilter }) => {
  const { form, updateFormValues } = useForm();
  const hook = filterFormHooks[source];
  const { fields } = hook ? hook({ form }) : {};

  const handleSubmit = () => {
    addFilter(form);
  };

  return (
    <div className="flexColumn gap15">
      <GridWrapper>
        {RenderForm({
          form,
          fields,
          updateFormValues,
        })}
      </GridWrapper>
      <div>
        <Button onClick={handleSubmit}>Apply Filter</Button>
      </div>
    </div>
  );
};

export const GridWrapper = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;
