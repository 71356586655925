import {
  createColumn,
  currencyFormat,
  formatDate,
} from "../../utils/helperUtils";

export const useSchedule = () => {
  const tableColumns = () => [
    ...["due_date", "principal", "interest", "total"].map((column) =>
      createColumn(column)
    ),
  ];

  const tableData = (data) => {
    const rows = [
      ...data.map((item) => {
        const { due_date } = item || {};

        return {
          ...["principal", "interest", "total"].reduce((acc, key) => {
            return { ...acc, [key]: currencyFormat(item[key]) };
          }, {}),
          due_date: formatDate(due_date),
        };
      }),
    ];

    return rows;
  };

  const totalRow = ({ data }) => {
    const totalFields = { index: "#", due_date: "Total" };
    for (const key of ["principal", "interest", "total"]) {
      totalFields[key] = currencyFormat(data?.[key]);
    }
    return totalFields;
  };

  return { tableColumns, tableData, totalRow };
};
