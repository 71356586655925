import { Button } from "antd";
import React from "react";
import styled from "styled-components";

export const TestArea = () => {
  function handleParentClick() {
    console.log("handleParentClick");
  }

  function handleChildClick(e, data) {
    e.stopPropagation();

    console.log("handleChildClick", data);
  }

  const data = [
    {
      column1: "nig",
      column2: "pop",
    },
  ];

  return (
    <>
      <div>
        {/* <MyTable /> */}
        <div className="rounded whiteBg p20" onClick={handleParentClick}>
          <Button type="primary" onClick={(e) => handleChildClick(e, data)}>
            Click me
          </Button>
        </div>
      </div>
    </>
  );
};

function MyTable({
  data = [
    {
      column1: "nig",
      column2: "pop",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
    {
      column1: "column1",
      column2: "column2",
    },
  ],
}) {
  return (
    <TableContainer className="h100">
      <Table>
        <TableHeader>
          <tr>
            <th>Column 1</th>
            <th>Column 2</th>
          </tr>
        </TableHeader>
        <TableBody>
          {data?.map((row) => (
            <tr key={row.id}>
              <td>{row.column1}</td>
              <td>{row.column2}</td>
            </tr>
          ))}
        </TableBody>
        <TableFooter>
          <tr>
            <td colSpan={3}>Footer</td>
          </tr>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;   
  height: 300px; 
  overflow: scroll;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 20px;
    border: 1px solid #ccc;
  }
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;

  th {
    text-align: left;
    padding: 20px;
    border: 1px solid #ccc;
  }
`;

const TableBody = styled.tbody`
  height: 100%;
  overflow-y: scroll;
`;

const TableFooter = styled.tfoot`
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 1;
`;
