import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart, axisClasses } from "@mui/x-charts";
import { SectionTitle } from "../elements/Elements";
import styled from "styled-components";
import { primaryColor, secondaryColor } from "../../constants/theme";
import {
  capitalizeText,
  scaleFormatter,
  stringify,
} from "../../utils/helperUtils";

export const BarChartView = ({ options }) => {
  const { yLabel, data = [], legend, colors } = options || {};
  const dataSet = [
    {
      data: data?.filter((val) => val.value > 0)?.map((val) => val?.value),
      label: legend,
      color: colors?.[0] || secondaryColor,
    },
  ];

  const newLabels = data
    ?.filter((val) => val.value > 0)
    ?.map((val) => capitalizeText(stringify(val?.label)));

  return (
    <BarChart
      width={500}
      height={300}
      borderRadius={5}
      series={dataSet}
      grid={{ horizontal: true }}
      margin={{ left: 60 }}
      xAxis={[
        {
          data: newLabels,
          scaleType: "band",
          colorMap: {
            type: "ordinal",
            values: newLabels,
            colors: colors || ["orange", secondaryColor],
          },
        },
      ]}
      yAxis={[
        {
          label: yLabel,
          labelStyle: { fontSize: 11 },
          valueFormatter: scaleFormatter,
        },
      ]}
      slotProps={{ legend: { labelStyle: { fontSize: 13 } } }}
      sx={{
        [`.${axisClasses.left} .${axisClasses.label}`]: {
          transform: "translate(-20px, 0)",
        },
      }}
    />
  );
};

export const PieChartView = ({ data = [] }) => {
  return (
    <PieChart
      series={[
        {
          data,
          // [
          //   { id: 0, value: 10, label: "series A" },
          //   { id: 1, value: 15, label: "series B" },
          //   { id: 2, value: 20, label: "series C" },
          // ],
          cornerRadius: 5,
          paddingAngle: 2,
          innerRadius: 20,
          cx: 135,
          highlightScope: { faded: "global", highlighted: "item" },
        },
      ]}
      slotProps={{ legend: { labelStyle: { fontSize: 13 } } }}
      width={400}
      height={200}
    />
  );
};

export const ChartSection = ({ title, children }) => (
  <div className="whiteBg rounded flexColumn">
    <SectionTitle title={title} />
    <ChartWrapper className="flexCenter h100">{children}</ChartWrapper>
  </div>
);

const ChartWrapper = styled.div`
  align-self: center;
  @media (max-width: 960px) {
    width: 350px;
    height: 250px;
  }
`;
