import { PermissionsSection } from "../../components/pages/permissions/PermissionsSection";
import { API_ROLES } from "../../constants/apis";
import { COLUMN, TEXT } from "../../constants/constants";

export const useRolesForm = ({ form, updateFormValues }) => {
  const fields = [
    {
      field: "name",
      label: "Role Name",
      type: TEXT,
      required: true,
    },
  ];

  const sections = [
    {
      title: "Role Details",
      fields,
    },
    {
      title: "Role Permissions",
      component: (
        <PermissionsSection
          role_permissions={form?.permissions}
          updateFormValues={updateFormValues}
        />
      ),
      layout: COLUMN,
    },
  ];
  return { sections, onSubmit };
};

function onSubmit({ payload, id }) {
  // construct permission_ids
  const permission_ids = payload.permissions.map((permission) => permission.id);
  return { id, api: API_ROLES, params: { name: payload.name, permission_ids } };
}
