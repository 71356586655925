import { MailOutline } from "@raresail/react-ionicons";
import React from "react";
import { APP_NAME, COMPONENT, TEXT } from "../../constants/constants";
import { Heading } from "../../components/pages/home/AuthPage";
import { Link } from "react-router-dom";
import { URL_LOGIN } from "../../constants/urls";
import { API_PASSWORD_RESET } from "../../constants/apis";

export const useForgotPasswordForm = () => {
  const fields = [
    {
      type: COMPONENT,
      component: <Heading>Forgot Password</Heading>,
    },
    {
      type: COMPONENT,
      component: (
        <p className="font13">
          Enter the email address associated with your <b>{APP_NAME}</b> account
          and we'll send you a link to reset your password.
        </p>
      ),
    },
    {
      label: "Email Address",
      field: "email",
      type: TEXT,
      required: true,
      props: {
        prefix: <MailOutline />,
      },
    },
  ];

  const suffixFields = [
    {
      type: COMPONENT,
      component: (
        <div className="flexCenter">
          <Link to={URL_LOGIN} className="secondaryColor font13">
            Back to Login
          </Link>
        </div>
      ),
    },
  ];

  function onSubmit({ payload }) {
    return { api: API_PASSWORD_RESET, params: payload };
  }

  return {
    fields,
    submitText: "Continue",
    suffixFields,
    redirect: URL_LOGIN,
    successMsg: "Password reset email has been sent.",
    onSubmit,
  };
};
