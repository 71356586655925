import { DATE, MONTHLY, SELECT, TEXT } from "../../constants/constants";
import { payment_frequency, repayment_types } from "../../constants/options";
import { RepaymentInfo } from "../../components/elements/Elements";
import { FieldProps } from "../../utils/formUtils";

export const useScheduleOptions = ({ form, isReschedule }) => {
  const fields = [
    {
      label: "first payment date",
      field: "start_date",
      type: DATE,
      required: true,
    },
    {
      field: "payment_frequency",
      type: SELECT,
      required: true,
      options: payment_frequency,
    },
    {
      field: "repayment_type",
      type: SELECT,
      required: true,
      options: repayment_types,
      props: {
        info: RepaymentInfo,
      },
    },
    {
      field: "loan_term",
      type: TEXT,
      required: true,
      props: FieldProps.number,
      visible: form?.payment_frequency === MONTHLY,
    },
    {
      field: "interest_rate",
      type: TEXT,
      required: true,
      props: { prefix: "%", ...FieldProps.number, disabled: !isReschedule },
    },
    {
      field: "principal",
      type: TEXT,
      required: true,
      props: { ...FieldProps.number, disabled: !isReschedule },
    },
  ];
  return { fields };
};

// payment_frequency
// loan_term
// reypayment_type
