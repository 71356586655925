import React, { useEffect } from "react";
import useForm from "../../hooks/common/useForm";
import { Button } from "antd";
import {
  createForm,
  getValidators,
  renderStepper,
} from "../../utils/formUtils";
import useSet from "../../hooks/common/useSet";
import { formHooks } from "../../hooks/handlers/useFormHandler";
import { useStepper } from "../common/GenericStepper";
import { useToasts } from "../../hooks/common/useToasts";
import { sourceApis } from "../../hooks/handlers/useApiHandler";
import useGet from "../../hooks/common/useGet";
import { Loader } from "../common/Loader";
import { cleanPayload } from "../../utils/helperUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@raresail/react-ionicons";

export const FormHandler = ({ source, id, title }) => {
  const navigate = useNavigate();
  const { form, updateFormValues, onChange, validateForm } = useForm();
  // get and set
  const { uploadData, error, setError, submitting, success } = useSet();
  const { loadData, data, loading } = useGet();
  // state management
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  // form hook destructure
  const formHook = formHooks?.[source];
  const {
    sections,
    steps,
    enableSubmit,
    defaultValues,
    onSubmit: handleSubmit,
    params,
    submitText,
  } = formHook({ id, form, updateFormValues, searchParams });
  // stepper config
  const { current, next, prev } = useStepper();

  function submitForm(form) {
    if (handleSubmit) {
      // cleanup data
      const payload = { ...form };
      cleanPayload(payload);

      let upload_data = handleSubmit({ payload, id });

      // handle submission
      if (upload_data) return uploadData(upload_data);
    }
  }

  // handle notifications
  useToasts({ success, error, id });

  // get record data from api
  useEffect(() => {
    if (id) {
      if (source && sourceApis[source]) {
        loadData({ id, api: sourceApis[source], params });
      }
    }
  }, [id]);

  // update form data
  useEffect(() => {
    if (data) {
      updateFormValues(data);
    }
  }, [data]);

  function onSubmit(e) {
    e.preventDefault();
    // form validation
    if (validateForm(getValidators(sections), setError)) submitForm(form);
  }

  function onSubmitStepper(e) {
    e.preventDefault();
    // stepper form validation
    if (validateForm([...steps[current]?.fields], setError)) {
      if (current === steps.length - 1) {
        submitForm(form);
      } else {
        next();
      }
    }
  }

  // set defaults
  useEffect(() => {
    if (defaultValues) {
      updateFormValues(defaultValues);
    }
  }, []);

  if (loading) return <Loader />;

  function goBack() {
    navigate(-1);
  }

  return (
    <form onSubmit={steps ? onSubmitStepper : onSubmit} className="h100">
      <div className="flexColumn gap15 h100">
        <div className="flexNullCenter rounded gap10 whiteBg ">
          <IconButton size="small" sx={{ ml: 1 }} onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <p
            className="capitalize bold mainColor"
            style={{ padding: "12px 0" }}
          >
            {title || `${source} Form`}
          </p>
        </div>

        {/* FORM FIELDS */}
        {sections && (
          <>
            <div className="flexColumn gap15 scroll radius10">
              {createForm({
                sections,
                form,
                updateFormValues,
                onChange,
                error,
              })}
            </div>
            {/* SUBMIT */}
            <div>
              <Button
                type="primary"
                className="font13"
                loading={submitting}
                htmlType="submit"
              >
                {submitText || (id ? "Update" : "Submit")}
              </Button>
            </div>
          </>
        )}

        {/* STEPPER */}
        {steps &&
          renderStepper({
            steps,
            form,
            updateFormValues,
            onChange,
            error,
            current,
            prev,
            next,
            enableSubmit,
            submitting,
          })}
      </div>
    </form>
  );
};
