import React from "react";
import styled from "styled-components";

import { LogoIcon, LogoSVG } from "../../../assets/svg/Svgs";
import {
  CallOutline,
  LocationOutline,
  MailOutline,
} from "@raresail/react-ionicons";
import { AuthFormHandler } from "./AuthFormHandler";
import {
  APP_ADDRESS,
  APP_CONTACT,
  APP_EMAIL,
  APP_TAGLINE,
} from "../../../constants/constants";
import { Footer } from "../../elements/Elements";

const contactLinks = [
  { label: APP_CONTACT, icon: CallOutline },
  { label: APP_EMAIL, icon: MailOutline },
  { label: APP_ADDRESS, icon: LocationOutline },
];

const LinkItem = ({ data: { label, icon: Icon } }) => {
  return (
    <div className="flexNullCenter gap15">
      <Icon style={{ fontSize: 20 }} className="secondaryColor" />
      <p className="font13 mainColor">{label}</p>
    </div>
  );
};

export const AuthPage = () => {
  const source = window.location.pathname.split("/")[1];

  return (
    <div className="vh100 p20">
      <GridWrapper className="h100">
        {/* LEFT */}
        <LeftWrapper className="whiteBg radius10 flexColumn xCenter gap20">
          <img src={LogoIcon} width={50} height={50} />
          <AuthFormHandler key={source} source={source} />
        </LeftWrapper>
        {/* RIGHT */}
        <RightWrapper className="flexColumn gap20">
          <div className="flexCenter flexColumn gap20 textCenter flexGrow">
            <LogoImg src={LogoSVG} />

            <p className="font14">{APP_TAGLINE}</p>

            {/* CONTACTS */}
            <div className="flexColumn gap10" style={{ marginTop: 50 }}>
              {contactLinks.map((data, i) => (
                <LinkItem data={data} key={i} />
              ))}
            </div>
          </div>
          {/* FOOTER */}
          <Footer />
        </RightWrapper>
      </GridWrapper>
    </div>
  );
};

export const Heading = ({ children }) => (
  <h2 className="mainColor">{children}</h2>
);

export const LeftWrapper = styled.div`
  padding: 0 100px;
  @media (max-width: 960px) {
    min-height: calc(100vh - 40px);
    padding: 30px;
  }
`;

const RightWrapper = styled.div`
  @media (max-width: 960px) {
    padding: 60px 20px;
  }
`;

const LogoImg = styled.img`
  width: 40%;
  @media (max-width: 960px) {
    width: 90%;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1.5fr 2fr;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
