import React from "react";
import styled from "styled-components";
import {
  currencyFormat,
  numberFormat,
  stringify,
} from "../../../utils/helperUtils";
import { BriefcaseOutline, ChevronDown } from "@raresail/react-ionicons";
import { alpha } from "@mui/material";
import { primaryColor, secondaryColor } from "../../../constants/theme";
import { useReports } from "../../../hooks/reports/useReports";
import { DateRangeInput } from "../../common/inputs/DateRangeInput";
import { BarChartView, ChartSection, PieChartView } from "../../charts/Charts";
import { OverlayLoader } from "../../common/Loader";
import { Select } from "antd";

const Tile = ({
  data: { label, value, color, icon: Icon = BriefcaseOutline, currency, desc },
  index,
}) => {
  const getColor = () => (index & 1 ? secondaryColor : primaryColor);
  label = stringify(label);

  function getDesc() {
    if (typeof desc === "boolean") {
      return `Total ${label}`;
    }
    return desc;
  }

  return (
    <TileWrapper
      className="radius10 whiteBg p15 flexNullCenter gap15 animate pointer"
      color={color || getColor()}
    >
      <IconWrapper
        className="flexCenter radius10 animate"
        color={color || getColor()}
        id="icon"
      >
        <Icon />
      </IconWrapper>
      <div className="flexColumn gap5">
        <div className="bold font18 mainColor mb5" id="value">
          {currency ? currencyFormat(value) : numberFormat(value)}
        </div>
        <div className="flexNullCenter gap5">
          <div className="capitalize font13">{label}</div>
          <ChevronDown className="greyColor" id="desc" />
        </div>
        <div className="font10 greyColor" id="desc">
          {getDesc()}
        </div>
      </div>
    </TileWrapper>
  );
};

export const ReportsView = () => {
  const { reports, setFilter, loading } = useReports();

  return (
    <div className="flexColumn gap15 h100 relative">
      {/* OVERLAY LOADER */}
      {loading && <OverlayLoader />}
      {/* HEADER */}
      <ReportsHeader setFilter={setFilter} />

      {/* BODY */}
      <div className="flexColumn gap15 scroll h100">
        {/* APPLICATIONS */}
        <ReportStatSection
          title="Applications Overview"
          data={reports.applications.grids}
        />
        {/* LOANS */}
        <LoanReportView loans={reports?.loans} />
        {/* TRANSACTIONS */}
        <ReportStatSection
          title="Transactions Overview"
          data={reports.transactions.reports.grids}
        />
        <TransactionChartView transactions={reports?.transactions} />
        {/* USERS */}
        <ReportStatSection title="Users Overview" data={reports.users.grids} />
      </div>
    </div>
  );
};

export const ReportsHeader = ({ title, setFilter }) => {
  return (
    <div className="whiteBg p10 rounded flexSpaceCenter">
      <h4 style={{ marginLeft: 5, fontSize: 16 }}>{title || "Reports"}</h4>
      <div className="flexNullCenter gap10">
        <Select
          placeholder="Filter"
          rootClassName="smallSelect"
          style={{ minWidth: 100 }}
          allowClear
          options={["Today", "Yesterday", "Custom"].map((item) => ({
            label: item,
            value: item,
          }))}
          // onChange={(value) => setFilter({ period: value })}
        />
        <DateRangeInput
          style={{ width: 220 }}
          setValue={setFilter}
          start_field={"start_date"}
          end_field={"end_date"}
        />
      </div>
    </div>
  );
};

export const LoanReportView = ({ loans }) => {
  return (
    <div className="flexColumn gap10">
      <ReportStatSection
        title="Loans Porfolio Report"
        data={loans?.reports.grids}
      />
      <Grid col={2}>
        <ChartSection title="Loans by status">
          <BarChartView options={loans?.charts?.performance} />
        </ChartSection>
        <ChartSection title="Loans by category">
          <PieChartView data={loans?.charts?.categories} />
        </ChartSection>
      </Grid>
    </div>
  );
};

export const TransactionChartView = ({ transactions }) => {
  return (
    <Grid col={2}>
      <ChartSection title="Repayments by month">
        <BarChartView options={transactions?.charts?.repayments_by_month} />
      </ChartSection>
      <ChartSection title="Disbursements by month">
        <BarChartView options={transactions?.charts?.disbursements_by_month} />
      </ChartSection>
    </Grid>
  );
};

export const ReportStatSection = ({ title, data }) => {
  return (
    <GridSection>
      <h4>{title}</h4>
      {data?.map(({ tiles, col }) => (
        <Grid col={col}>
          {tiles.map((tile, i) => (
            <Tile data={tile} index={i} />
          ))}
        </Grid>
      ))}
    </GridSection>
  );
};

const IconWrapper = styled.div`
  background-color: ${(props) => props?.color && alpha(props.color, 0.1)};
  color: ${(props) => props?.color};
  padding: 10px;
  height: 60px;
  width: 60px;
  font-size: 30px;
`;
const TileWrapper = styled.div`
  &:hover {
    scale: 0.98;
    background-color: ${(props) => props?.color};
    color: white;
    #icon {
      background-color: ${alpha("#fff", 0.2)};
      color: white;
      border: 1px solid ${alpha("#fff", 0.1)};
    }
    #value {
      color: white;
    }
    #desc {
      color: #ffffffb0;
    }
  }
`;
const GridSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h4 {
    margin-bottom: 5px;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: ${(props) => `repeat(${props.col}, 1fr)`};

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;
