import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE } from "../../constants/constants";

function getMessage({ id }) {
  if (id) {
    return "Updated Successfully!";
  }
  return "Created Successfully!";
}

export const useToasts = ({ success, error, id, redirect, callback }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      message.success(getMessage({ id }));

      if (callback) callback();

      if (redirect === false) return;

      // handle redirect
      if (redirect) {
        navigate(redirect);
        // } else if ((redirect = false)) {
      } else {
        navigate(-1);
      }
    }
    if (error) {
      const errorMessage = error?.message || error?.detail || ERROR_MESSAGE;
      message.error(errorMessage);
    }
  }, [success, error]);

  return;
};
