import { Popconfirm } from "antd";
import React from "react";

export const ConfirmDialog = ({
  children,
  onConfirm,
  onCancel,
  title,
  description,
}) => {
  return (
    <Popconfirm
      title={title}
      description={description || "Are you sure to proceed this task?"}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText="Yes"
      cancelText="No"
    >
      {children}
    </Popconfirm>
  );
};
