import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useGet from "../../hooks/common/useGet";
import { API_COMMENTS } from "../../constants/apis";
import { Loader } from "../common/Loader";
import { formatDateTime, getUser, makeSingular } from "../../utils/helperUtils";
import { openModalForm } from "../../utils/modalUtils";
import { COMMENTS } from "../../constants/constants";
import { Button } from "../common/GenericButton";
import { Add } from "@raresail/react-ionicons";
import { EmptyView } from "../elements/Elements";

export const CommentsList = ({ updateExtra }) => {
  const { source, id } = useParams();
  const { loadData, loading, data } = useGet();
  const type = makeSingular(source);

  const refreshData = () =>
    loadData({ id, api: API_COMMENTS, params: { type } });

  useEffect(refreshData, []);
  useEffect(
    () =>
      updateExtra({
        name: COMMENTS,
        children: (
          <div>
            <Button
              icon={<Add />}
              className="font12"
              onClick={openModal}
              size="small"
            >
              New
            </Button>
          </div>
        ),
      }),
    []
  );

  const openModal = () => {
    openModalForm({
      id,
      source: COMMENTS,
      state: {
        defaultValues: {
          content_type: type,
          object_id: id,
        },
        refreshData,
      },
    });
  };

  const comments = data?.results;

  if (loading) return <Loader />;

  return (
    <>
      {comments?.length > 0 ? (
        <div className="flexColumn gap15">
          {comments?.map((item, i) => {
            return <CommentItem key={i} data={item} />;
          })}
        </div>
      ) : (
        <EmptyView />
      )}
    </>
  );
};

const CommentItem = ({ data }) => {
  const { comment, created_by, created_at } = data || {};
  return (
    <div className="lightBg p10 rounded gap10 flexColumn">
      <div className="font12">{comment}</div>
      <div className="flexSpaceCenter">
        <div className="font11 mainColor mb5">
          {getUser(created_by).created_by}
        </div>
        <div className="font10 greyColor">{formatDateTime(created_at)}</div>
      </div>
    </div>
  );
};
